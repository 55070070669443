
import { createSlice } from '@reduxjs/toolkit'
import http from 'axios'

let tokenInterceptor

const initialState = {
	user: null,
}

const counterSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		login: (state, { payload }) => {
			const token = payload
			localStorage.setItem('token', token)
			tokenInterceptor = http.interceptors.request.use((config) => {
				config.headers.Authorization = `Bearer ${token}`
				return config
			})
		},
		setUser: (state, { payload }) => {
			state.user = payload
		},
		logout: (state, { payload }) => {
			localStorage.removeItem('token')
			state.user = null
			http.interceptors.request.eject(tokenInterceptor)
		},
	},
})

export const { login, logout, setUser } = counterSlice.actions
export default counterSlice.reducer
