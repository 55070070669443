import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const transaction_types = await http.get(`${window.__endpoint}/cha/list_lines/list?cols=list_value_id,name&list_id=94`)
		const transaction_monetary_types = await http.get(`${window.__endpoint}/cha/list_lines/list?cols=list_value_id,name&list_id=168`)
		const stock_monetary_types = await http.get(`${window.__endpoint}/cha/list_lines/list?cols=list_value_id,name&list_id=169`)

		setSelectOptions({
			transaction_types: transaction_types.data,
			transaction_monetary_types: transaction_monetary_types.data,
			stock_monetary_types: stock_monetary_types.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Codice */}
					<Col all={4}>
						<FormGroup
							name="code"
							label="Codice"
							validationState={validator.validationState.code}
						>
							<InputText
								value={editItem.code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Nome */}
					<Col all={8}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tipologia di movimentazione magazzino iniziale */}
					<Col all={6}>
						<FormGroup
							name="source_transaction_type_id"
							label="Tipologia di movimentazione magazzino iniziale"
						>
							<Select
								value={editItem.source_transaction_type_id || ''}
								onChange={onChange}
								options={selectOptions.transaction_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Tipologia di movimentazione magazzino finale */}
					<Col all={6}>
						<FormGroup
							name="target_transaction_type_id"
							label="Tipologia di movimentazione magazzino finale"
						>
							<Select
								value={editItem.target_transaction_type_id || ''}
								onChange={onChange}
								options={selectOptions.transaction_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tipologia di valore monetario magazzino iniziale */}
					<Col all={6}>
						<FormGroup
							name="source_transaction_monetary_type_id"
							label="Tipologia di valore monetario magazzino iniziale"
						>
							<Select
								value={editItem.source_transaction_monetary_type_id || ''}
								onChange={onChange}
								options={selectOptions.transaction_monetary_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Tipologia di valore monetario magazzino finale */}
					<Col all={6}>
						<FormGroup
							name="target_transaction_monetary_type_id"
							label="Tipologia di valore monetario magazzino finale"
						>
							<Select
								value={editItem.target_transaction_monetary_type_id || ''}
								onChange={onChange}
								options={selectOptions.transaction_monetary_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tipologia di variazione del valore monetario magazzino iniziale */}
					<Col all={6}>
						<FormGroup
							name="source_stock_monetary_type_id"
							label="Tipologia di variazione del valore monetario magazzino iniziale"
						>
							<Select
								value={editItem.source_stock_monetary_type_id || ''}
								onChange={onChange}
								options={selectOptions.stock_monetary_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Tipologia di variazione del valore monetario magazzino finale */}
					<Col all={6}>
						<FormGroup
							name="target_stock_monetary_type_id"
							label="Tipologia di variazione del valore monetario magazzino finale"
						>
							<Select
								value={editItem.target_stock_monetary_type_id || ''}
								onChange={onChange}
								options={selectOptions.stock_monetary_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
