import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, HTMLEditor, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	{/*  name, description, intro_text, active_flag, billed_amount, monthly_amount, user_flag */}
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome servizio"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputText
							type="text"
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="intro_text"
						label="Testo introduttivo"
						validationState={validator.validationState.intro_text}
					>
						<HTMLEditor
							value={editItem.intro_text}
							onChange={onChange}
							modules={{
								toolbar: [
									[{ 'header': [1, 5, false] }],
									['bold', 'italic', 'underline','strike', 'blockquote'],
									[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
									[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
									['link', 'image'],
									['clean']
								]
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="help_text"
						label="Testo guida per l'utente in fase di utilizzo"
						validationState={validator.validationState.help_text}
					>
						<HTMLEditor
							value={editItem.help_text}
							onChange={onChange}
							modules={{
								toolbar: [
									[{ 'header': [1, 5, false] }],
									['bold', 'italic', 'underline','strike', 'blockquote'],
									[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
									[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
									['link', 'image'],
									['clean']
								]
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="active_flag"
						label="Attivo"
						validationState={validator.validationState.active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="user_flag"
						label="Visibile e attivabile dall'utente"
						validationState={validator.validationState.user_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<p>Inserire il costo per chiamata se il servizio é fatturabile ad ogni chiamata oppure inserire il costo mensile se il servizio é usufruibile tramite un canone/credito mensile</p>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="billed_amount"
						label="Costo per chiamata"
						validationState={validator.validationState.billed_amount}
					>
						<InputText
							type="number"
							value={editItem.billed_amount || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="monthly_amount"
						label="Costo mensile"
						validationState={validator.validationState.monthly_amount}
					>
						<InputText
							type="number"
							value={editItem.monthly_amount || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
