import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import labels from 'core/labels'

function Edit({ editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const roles_types = await http.get(`${window.__endpoint}/cha/roles/list?cols=role_id,name&order=name`)

		setSelectOptions({
			roles_types: roles_types.data,
		})
	}

	const validationFunction = (data) =>{
		const ok = !(data.role_id && data.user_level) && (data.role_id || data.user_level)
		return ok ? '' : {
			user_level: labels.one_compiled_field_required,
			role_id: labels.one_compiled_field_required,
		}
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={[]}
		validationFunction={validationFunction}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Referment types */}
					<Col all={6}>
						<FormGroup
							name="role_id"
							label="Ruolo"
							validationState={validator.validationState.role_id}
						>
							<Select
								disabled={editItem.user_level && editItem.user_level.toString().length > 0}
								value={editItem.role_id || ''}
								onChange={onChange}
								options={selectOptions.roles_types || []}
								optionValue={(o) => o.role_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Livello */}
					<Col all={6}>
						<FormGroup
							name="user_level"
							label="Livello"
							validationState={validator.validationState.user_level}
						>
							<InputText
								disabled={editItem.role_id > 0}
								type="number"
								value={editItem.user_level || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
