import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as ShopsEdit } from '../../Components/Shops'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/environment/ecom/shops/load?shop_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		const data = {
			...editItem,
			shipping_provinces: Array.isArray(editItem.shipping_provinces) ? editItem.shipping_provinces.join(',') : editItem.shipping_provinces,
		}
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/environment/ecom/shops/create`, data)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/environment/ecom/shops/update`, data)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea negozio' : `Modifica ${editItem && editItem.shop_name != '' ? editItem.shop_name : 'negozio'}`}`}>
			<ShopsEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

export default PageEdit
