import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox, InputTextarea } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								row={6}
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome */}
					<Col all={3}>
						<FormGroup
							name="price_show_alt_flag"
							label="Mostra testo alternativo anzichè il prezzo"
							validationState={validator.validationState.price_show_alt_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.price_show_alt_flag == 1}
							/>
						</FormGroup>
					</Col>
					{editItem.price_show_alt_flag == 1 ?
						<>
							<Col all={9}>
								<FormGroup
									name="price_alt_text"
									label="Testo alternativo al prezzo"
									validationState={validator.validationState.price_alt_text}
								>
									<InputText
										value={editItem.price_alt_text || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</>
						:
						<>
							<Col all={9}>
								<FormGroup
									name="price_per_month"
									label="Prezzo / mese"
									validationState={validator.validationState.price_per_month}
								>
									<InputText
										type="number"
										value={editItem.price_per_month || ''}
										onChange={onChange}
									/>
								</FormGroup>
							</Col>
						</>
					}
				</Row>
				<Row>
					<Col all={3}>
						<FormGroup
							name="active_flag"
							label="Attivo"
							validationState={validator.validationState.active_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.active_flag == 1}
							/>
						</FormGroup>
					</Col>
					<Col all={3}>
						<FormGroup
							name="highlight_flag"
							label="Metti in risalto"
							validationState={validator.validationState.highlight_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.highlight_flag == 1}
							/>
						</FormGroup>
					</Col>
					<Col all={3}>
						<FormGroup
							name="contact_flag"
							label="Richiedi info"
							validationState={validator.validationState.contact_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.contact_flag == 1}
							/>
						</FormGroup>
					</Col>
					<Col all={3}>
						<FormGroup
							name="hide_flag"
							label="Nascondi dai piani visibili all'utente"
							validationState={validator.validationState.hide_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.hide_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
