import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { Card } from 'components/Ui'
import { SearchContext } from 'core/contexts/SearchContext'
import { List as LtList } from '../../Components/ListLt'

function PageList() {
	const { contextSearch } = useContext(SearchContext)
	const [items, setItems] = React.useState([])
	const query = useQuery()
	const list_line_id = query.get('list_line_id')
	const isNew = (list_line_id == undefined)
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/list_lines_lt/list?list_line_id=${list_line_id}`, {
			params: filters,
		})
		setItems(list.data)
	}

	/*
	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/cha/lists/delete?list_id=${id}`)
		handleList(filtersValues)
	}
	*/

	function useQuery() {
		const { search } = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search])
	}

	return <Card title="Traduzioni">
		<LtList
			list_line_id={list_line_id}
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			//onDelete={handleDelete}
		/>
	</Card>
}

export default PageList
