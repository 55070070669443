import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, InputTextarea } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['payment_method']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Metodo di pagamento */}
					<Col all={6}>
						<FormGroup
							name="payment_method"
							label="Metodo di pagamento"
							validationState={validator.validationState.payment_method}
						>
							<InputText
								value={editItem.payment_method || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={6}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputText
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Impostazioni */}
					<Col all={12}>
						<FormGroup
							name="settings"
							label="Impostazioni"
							validationState={validator.validationState.settings}
						>
							<InputTextarea
								rows={4}
								value={editItem.settings || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Altre impostazioni */}
					<Col all={12}>
						<FormGroup
							name="secret_settings"
							label="Altre impostazioni"
							validationState={validator.validationState.secret_settings}
						>
							<InputTextarea
								rows={4}
								value={editItem.secret_settings || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
