import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as CategoryEdit } from '../../Components/Categories'
import { List as ListParameters } from '../../../../../cha/modules/Parameters/Components/Parameters'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [parameters, setParameters] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		refer_type_id: 667, // RMS categories
		refer_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListParameters(defaultFiltersValues)
		}
	}, [id])

	//* Gestione della categoria
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const report = await http.get(`${window.__endpoint}/rms/categories/load?category_id=${id}`)
			setEditItem(report.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/rms/categories/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/rms/categories/update`, {
				...editItem,
				category_id: id
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//Gestione dei parametri
	const handleListParameters = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/parameters/list`, {
			params: {
				refer_type_id: 667, // rms categories
				refer_id: id,
			}
		})
		setParameters(list.data)
	}

	const handleDeleteParameters = async(id) => {
		await http.delete(`${window.__endpoint}/cha/parameters/delete?parameter_id=${id}`)
		handleListParameters(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea categoria ' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'categoria'}`}`}>
			<CategoryEdit
				category_id={id}
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Modifica Parametri">
				<ListParameters
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={parameters}
					onList={handleListParameters}
					onDelete={handleDeleteParameters}
					refer_id={id}
					refer_type_id={667}
				/>
			</Card>
		</React.Fragment>}
	</>
}

export default PageEdit
