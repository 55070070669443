import React from 'react'

const allowedValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

function Col({ children, all, sm, md, lg, xl, className, ...props }) {
	let style = ''
	if (allowedValue.includes(all)) {
		style = `col-${all}`
	} else {
		style = 'col'
	}
	if (allowedValue.includes(sm)) {
		style = `${style} sm:col-${sm}`
	}
	if (allowedValue.includes(md)) {
		style = `${style} md:col-${md}`
	}
	if (allowedValue.includes(lg)) {
		style = `${style} lg:col-${lg}`
	}
	if (allowedValue.includes(xl)) {
		style = `${style} xl:col-${xl}`
	}
	if (className) {
		style = `${style} ${className}`
	}
	return <div className={style} {...props}>
		{ children }
	</div>
}

export default Col
