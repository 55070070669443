import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditTranslations } from '../../Components/Translations'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()
	let refer_type_id = query.get('refer_type_id')
	let lang_id = query.get('lang_id')
	let refer_id = query.get('refer_id')

	const isNew = (refer_type_id == undefined || lang_id == undefined || refer_id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
		if (isNew){
			setEditItem({ ...editItem, refer_type_id: refer_type_id })
		}
	}, [refer_id, lang_id, refer_type_id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/cha/referement_type_texts_translations/load`,{
				params: {
					lang_id: lang_id,
					refer_id: refer_id,
					refer_type_id: refer_type_id
				}
			})
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const lang_id = await http.post(`${window.__endpoint}/cha/referement_type_texts_translations/create`, {
				...editItem,
				refer_id: refer_id,
				refer_type_id: refer_type_id
			})
			navigate(`/cha/referements-translations/translations/edit?refer_type_id=${refer_type_id}&refer_id=${refer_id}&lang_id=${lang_id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/referement_type_texts_translations/update`, {
				...editItem,
				refer_id: refer_id,
				refer_type_id: refer_type_id,
				language_id: lang_id
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea traduzione' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'traduzione'}`}`}>
			<EditTranslations
				isNew={isNew}
				refer_type_id={refer_type_id}
				refer_id={refer_id}
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

function useQuery() {
	const { search } = useLocation()

	return useMemo(() => new URLSearchParams(search), [search])
}

export default PageEdit
