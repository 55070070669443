import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditInterfaces } from '../../Components/Interfaces'
import { List as ListRulesFields } from '../../Components/RulesFields'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [rules, setRules] = useState({})

	useEffect(() => {
		handleLoad()
		handleListRules()
		if (!isNew) {
			handleListRules()
		}
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/int/interfaces/load?interface_id=${id}`)
			setEditItem(service.data)
		}
	}
	const handleListRules = async() => {

		const rules = await http.get(`${window.__endpoint}/int/interfaces/rules_fields/list?interface_id=${id}`)
		setRules(rules.data)

	}
	const handleDeleteRules = async(id) => {
		await http.delete(`${window.__endpoint}/int/interfaces/rules_fields/delete?interface_field_id=${id}`)
		handleListRules()
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/int/interfaces/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/int/interfaces/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea interfaccia' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'interfaccia'}`}`}>
			<EditInterfaces
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{ !isNew && <> <br />
			<Card title={'Elenco regole di interfaccia'}>
				<ListRulesFields
					items={rules}
					onList={handleListRules}
					onDelete={handleDeleteRules}
				/>
			</Card>

		</>

		}
	</>
}

export default PageEdit
