import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'subscription_code']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Tipo dell'abbonamento */}
					<Col all={6}>
						<FormGroup
							name="name"
							label="Tipo dell'abbonamento"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Codice dell'abbonamento */}
					<Col all={6}>
						<FormGroup
							name="subscription_code"
							label="Codice dell'abbonamento"
							validationState={validator.validationState.subscription_code}
						>
							<InputText
								value={editItem.subscription_code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
