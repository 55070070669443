import React from 'react'
import { Button } from 'primereact/button'
import { Select } from 'components/Ui'

const ListToolbarSelector = ({
	selectOptions,
	onChange,
	onSubmit,
	...props
}) => {
	const [editItem, setEditItem] = React.useState({})

	return <div className="flex flex-column md:flex-row gap-3" style={{ marginBottom: 10 }}>
		{selectOptions && selectOptions.map((selectOption, i) => {

			const handleValueChange = (value) => {
				onChange({
					name: selectOption.name,
					value: value
				})

				setEditItem({
					...editItem,
					[selectOption.name]: value
				})
			}

			return <div className="p-inputgroup flex-1" key={i}>
				<Select
					showClear
					filter
					value={Number(editItem[selectOption.name]) || ''}
					onChange={(e) => handleValueChange(e.value)}
					options={selectOption.options || []}
					optionValue={(o) => Number(o[selectOption.name])}
					optionLabel={(o) => o.name}
					all={{
						label: '- Home page -'
					}}
				/>
			</div>
		})}
		<Button
			label="Aggiungi"
			icon='pi pi-plus'
			onClick={onSubmit}
		/>
	</div>
}

export default ListToolbarSelector
