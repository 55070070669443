import React from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['code', 'text']}
	>
		{(validator) => {

			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Codice */}
					<Col all={12}>
						<FormGroup
							name="code"
							label="Codice"
							validationState={validator.validationState.code}
						>
							<InputText
								value={editItem.code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="text"
							label="Testo"
							validationState={validator.validationState.text}
						>
							<InputTextarea
								value={editItem.text || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Attivo */}
					<Col all={4}>
						<FormGroup
							name="active_flag"
							label="Attivo"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.active_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
