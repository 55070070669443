import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as ModulesList } from '../../Components/Module'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context as ServerContext } from 'apps/environment/context'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)
	const { selectedServer, selectedEnvironment } = useContext(ServerContext)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer, selectedEnvironment])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/cha/modules/list?cols=module_id,name,description,active_state,default_flag&order=name`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleEnvEnable = async(id) => {
		await http.post(`${window.__endpoint}/environment/cha/modules/add_to_environment?module_id=${id}`)
		handleList(filtersValues)
	}

	const handleEnvDisable = async(id) => {
		await http.post(`${window.__endpoint}/environment/cha/modules/remove_from_environment?module_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title="Moduli">
		<ModulesList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onEnvEnable={handleEnvEnable}
			onEnvDisable={handleEnvDisable}
		/>
	</Card>
}

export default PageList
