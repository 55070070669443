import React, { useState, useEffect } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import { ColorPicker } from 'primereact/colorpicker'

function Edit({ portalId, editItem, onChange, onSubmit, isCreate }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const portals = await http.get(`${window.__endpoint}/environment/bm/portals/list?cols=portal_id,bm_url&order=bm_url`)
		const organizations = await http.get(`${window.__endpoint}/environment/cha/organizations/list?cols=organization_id,name&order=name`)

		setSelectOptions({
			portals: portals.data,
			organizations: organizations.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['portal_id', 'organization_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Ambiente */}
					<Col all={6}>
						<FormGroup
							name="portal_id"
							label="Portale"
							validationState={validator.validationState.portal_id}
						>
							<Select
								value={editItem.portal_id || ''}
								onChange={onChange}
								options={selectOptions.portals || []}
								optionValue={(o) => o.portal_id}
								optionLabel={(o) => o.bm_url}
								all={{
									label: ' - Seleziona - '
								}}
								disabled
							/>
						</FormGroup>
					</Col>
					<Col all={6}>
						<FormGroup
							name="organization_id"
							label="Organizzazione"
							validationState={validator.validationState.organization_id}
						>
							<Select
								value={editItem.organization_id || ''}
								onChange={onChange}
								options={selectOptions.organizations || []}
								optionValue={(o) => o.organization_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
								disabled={!isCreate}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* intro text customer */}
					<Col all={12}>
						<FormGroup label="Messaggio di benvenuto per cliente" name="customer_intro_text">
							<HTMLEditor
								value={editItem.customer_intro_text || ''}
								onChange={onChange}
								customHeight={150}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* intro text supplier */}
					<Col all={12}>
						<FormGroup label="Messaggio di benvenuto per fornitore" name="supplier_intro_text">
							<HTMLEditor
								value={editItem.supplier_intro_text || ''}
								onChange={onChange}
								customHeight={150}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* intro text agent */}
					<Col all={12}>
						<FormGroup label="Messaggio di benvenuto per agente" name="agent_intro_text">
							<HTMLEditor
								value={editItem.agent_intro_text || ''}
								onChange={onChange}
								customHeight={150}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col all={6}>
						<FormGroup
							name="bg_color"
							label="Colore di sfondo"
						>
							<ColorPicker
								value={editItem.bg_color || ''}
								onChange={(e) => onChange(e)}
								width={100}
							/>
						</FormGroup>
					</Col>
					<Col all={6}>
						<FormGroup
							name="main_color"
							label="Colore primario"
						>
							<ColorPicker
								value={editItem.main_color || ''}
								onChange={(e) => onChange(e)}
								width={100}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col all={6}>
						<FormGroup
							name="footer_text"
							label="Testo del footer"
							validationState={validator.validationState.footer_text}
						>
							<InputText
								value={editItem.footer_text || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col all={12}>
						<FormGroup
							name="show_privacy_flag"
							label="Richiedi accettazione privacy"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.show_privacy_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				{editItem.show_privacy_flag == 1 && <Row>
					<Col all={12}>
						<FormGroup
							name="privacy_text"
							label="Testo privacy"
						>
							<HTMLEditor
								value={editItem.privacy_text || ''}
								onChange={onChange}
								customHeight={150}
							/>
						</FormGroup>
					</Col>
				</Row>}
			</form>}}
	</FormValidator>
}
export default Edit
