import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Topics } from '../modules/Services'

function App() {
	return <Routes>
		<Route path="services/*" element={<Topics />} />
	</Routes>
}

export default App
