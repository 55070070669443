import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { classNames, DomHandler } from 'primereact/utils'
import { useEventListener, useUnmountEffect } from 'primereact/hooks'
import { setLayoutState } from 'reducers/ui/uiSlice'

import AppConfig from './Components/AppConfig'
import AppFooter from './Components/AppFooter'
import AppSidebar from './Components/AppSidebar'
import AppTopbar from './Components/AppTopbar'
import Router from './Routes/Router'

function Layout() {
	const location = useLocation()
	const dispatch = useDispatch()
	const { layoutConfig, layoutState } = useSelector(state => state.ui)

	const topbarRef = useRef(null)
	const sidebarRef = useRef(null)

	const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
		type: 'click',
		listener: (event) => {
			const isOutsideClicked = !(sidebarRef.current.isSameNode(event.target) || sidebarRef.current.contains(event.target) || topbarRef.current.menubutton.isSameNode(event.target) || topbarRef.current.menubutton.contains(event.target))

			if (isOutsideClicked) {
				hideMenu()
			}
		}
	})

	const [bindProfileMenuOutsideClickListener, unbindProfileMenuOutsideClickListener] = useEventListener({
		type: 'click',
		listener: (event) => {
			const isOutsideClicked = !(
				topbarRef.current.topbarmenu.isSameNode(event.target) ||
				topbarRef.current.topbarmenu.contains(event.target) ||
				topbarRef.current.topbarmenubutton.isSameNode(event.target) ||
				topbarRef.current.topbarmenubutton.contains(event.target)
			)

			if (isOutsideClicked) {
				hideProfileMenu()
			}
		}
	})

	const hideMenu = () => {
		dispatch(setLayoutState({ overlayMenuActive: false, staticMenuMobileActive: false, menuHoverActive: false }))
		unbindMenuOutsideClickListener()
		unblockBodyScroll()
	}

	const hideProfileMenu = () => {
		dispatch(setLayoutState({ profileSidebarVisible: false }))
		unbindProfileMenuOutsideClickListener()
	}

	const blockBodyScroll = () => {
		DomHandler.addClass('blocked-scroll')
	}

	const unblockBodyScroll = () => {
		DomHandler.removeClass('blocked-scroll')
	}

	useEffect(() => {
		if (layoutState.overlayMenuActive || layoutState.staticMenuMobileActive) {
			bindMenuOutsideClickListener()
		}

		layoutState.staticMenuMobileActive && blockBodyScroll()
	}, [layoutState.overlayMenuActive, layoutState.staticMenuMobileActive])

	useEffect(() => {
		if (layoutState.profileSidebarVisible) {
			bindProfileMenuOutsideClickListener()
		}
	}, [layoutState.profileSidebarVisible])

	useEffect(() => {
		hideMenu()
		hideProfileMenu()
	}, [location])

	useUnmountEffect(() => {
		unbindMenuOutsideClickListener()
		unbindProfileMenuOutsideClickListener()
	})

	const containerClass = classNames('layout-wrapper', {
		'layout-overlay': layoutConfig.menuMode === 'overlay',
		'layout-static': layoutConfig.menuMode === 'static',
		'layout-static-inactive': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
		'layout-overlay-active': layoutState.overlayMenuActive,
		'layout-mobile-active': layoutState.staticMenuMobileActive,
		'p-input-filled': layoutConfig.inputStyle === 'filled',
		'p-ripple-disabled': !layoutConfig.ripple
	})

	return <div className={containerClass}>
		<AppTopbar ref={topbarRef} />
		<div ref={sidebarRef} className="layout-sidebar">
			<AppSidebar />
		</div>
		<div className="layout-main-container">
			<div className="layout-main">
				<Router />
			</div>
			<AppFooter />
		</div>
		<AppConfig />
		<div className="layout-mask"></div>
	</div>
}

export default Layout
