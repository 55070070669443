import { useLocation, Link } from 'react-router-dom'
import { Ripple } from 'primereact/ripple'
import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveMenu } from 'reducers/ui/uiSlice'

const AppMenuitem = ({
	...props
}) => {
	const dispatch = useDispatch()
	const { activeMenu } = useSelector(state => state.ui)

	const { group_url } = props

	const location = useLocation()
	const item = props.item

	const element_url = group_url ? (item.force_base && item.force_base != '' ? `${item.force_base}${item.to}` : `${group_url}${item.to}`) : item.to

	const key = props.parentKey ? props.parentKey + '-' + props.index : String(props.index)
	// Ricavo solo la prima parte del percorso per evidenziale il menù attivo anche quando sono nelle sotto pagine
	const locationMain = !item.to ? false : location.pathname.split('/').slice(0, (element_url.split('/').length)).join('/')
	const isActiveRoute = element_url && locationMain === element_url
	const active = activeMenu === key || activeMenu.startsWith(key + '-')

	useEffect(() => {
		if (isActiveRoute) {
			dispatch(setActiveMenu(key))
		}
	}, [])

	const itemClick = (event) => {
		//avoid processing disabled items
		if (item.disabled) {
			event.preventDefault()
			return
		}

		//execute command
		if (item.command) {
			item.command({ originalEvent: event, item: item })
		}

		// toggle active state
		if (item.items) {
			dispatch(setActiveMenu(active ? props.parentKey : key))
		}
	}

	const subMenu = item.items && item.visible !== false && (
		<CSSTransition timeout={{ enter: 1000, exit: 450 }} classNames="layout-submenu" in={props.root ? true : active} key={item.label}>
			<ul>
				{item.items.map((child, i) => {
					return <AppMenuitem
						group_url={item.to}
						item={child}
						index={i}
						className={child.badgeClass}
						parentKey={key}
						key={child.label}
					/>
				})}
			</ul>
		</CSSTransition>
	)

	return (
		<li className={classNames({ 'layout-root-menuitem': props.root, 'active-menuitem': active })}>
			{props.root && item.visible !== false && <div className="layout-menuitem-root-text">{item.label}</div>}
			{(!item.to || item.items) && item.visible !== false ? (
				<a href={item.url} onClick={(e) => itemClick(e)} className={classNames(item.class, 'p-ripple')} target={item.target} tabIndex="0">
					<i className={classNames('layout-menuitem-icon', item.icon)}></i>
					<span className="layout-menuitem-text">{item.label}</span>
					{item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
					<Ripple />
				</a>
			) : null}

			{item.to && !item.items && item.visible !== false ? (
				<Link to={element_url} replace={item.replaceUrl} target={item.target} onClick={(e) => itemClick(e)} className={classNames(item.class, 'p-ripple', { 'active-route': isActiveRoute })} tabIndex={0}>
					<i className={classNames('layout-menuitem-icon', item.icon)}></i>
					<span className="layout-menuitem-text">{item.label}</span>
					{item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
					<Ripple />
				</Link>
			) : null}

			{subMenu}
		</li>
	)
}

export default AppMenuitem
