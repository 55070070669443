import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Col, Row, Select, Button } from 'components/Ui'
import http from 'axios'
import { Tag } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onList, onSetDefault, filtersValues, list_id, ...props }) {
	const { onSubmit } = props
	const [referementTypes, setRefermentTypes] = React.useState([])
	const [selectedReferType, setSelectedReferType] = React.useState()

	// Carico i tipi di riferimento solo di cha
	const handleLoadReferTypes = async() => {
		const referTypes = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name&order=name&images_enable=1`)

		setRefermentTypes(referTypes.data)
	}

	React.useEffect(() => {
		handleLoadReferTypes()
	}, [])

	// Salvo il NUOVO tipo di riferimento
	const handleSubmit = () => {
		onSubmit({
			refer_type_id: selectedReferType,
		})
		setSelectedReferType(null)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedReferType || ''}
					onChange={(e) => setSelectedReferType(e.target.value)}
					options={referementTypes || []}
					optionValue={(o) => o.refer_type_id}
					optionLabel={(o) => o.name}
					all={{
						label: ' - Seleziona - '
					}}
					filter
				/>
				<Button onClick={handleSubmit}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			items={items}
			fieldId='image_type_refer_type_id'
			editInline
			columns={[
				{ field: 'image_type_refer_type_id', header: 'Id', sortable: true },
				{ field: 'refer_type_id', header: 'Id refer', sortable: true },
				{ field: 'refer_type', header: 'Nome', sortable: true },
				{ field: 'default_flag', header: 'Default', sortable: false, type: 'boolean' },
			]}
			modalActions={[
				{ label: 'Imposta come default', action: onSetDefault, icon: 'pi pi-check' },
				{ label: 'Elimina', action: onDelete, icon: 'pi pi-exclamation-triangle' },
			]}
		/>
	</React.Fragment>
}

export default List
