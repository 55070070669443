import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['email']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Email (dove viene inviata la mail di avvenuta richiesta registrazione) */}
					<Col all={12}>
						<FormGroup
							name="email"
							label="Email (dove viene inviata la mail di avvenuta richiesta registrazione)"
							validationState={validator.validationState.email}
						>
							<InputText
								value={editItem.email || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
