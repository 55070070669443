import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<ListToolbar
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnFilter
			editRoot={'settings'}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			editRoot={'settings'}
			fieldId='setting_id'
			columns={[
				{ field: 'setting_id', header: 'Id' },
				{ field: 'organization_name', header: 'Organizzazione' },
			]}
		/>
	</React.Fragment>
}

export default List
