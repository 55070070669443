import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Categories } from '../modules/Categories'

function App() {
	return <Routes>
		<Route path="categories/*" element={<Categories />} />
	</Routes>
}

export default App
