import { React, useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, Select, InputTextarea, Row, InputText } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ text_id, lang_id, editItem, setEditItem, onChange, onSubmit, isNew }) {
	const [selectedOptions, setSelectOptions] = useState([])
	const [item, setItem] = useState({})

	const loadItem = async() => {
		const curItem = await http.get(`${window.__endpoint}/cha/texts/load?text_id=${text_id}`)
		setItem(curItem.data)
	}

	useEffect(() => {
		loadSelectedOptions()
		loadItem()
	}, [])

	const loadSelectedOptions = async() => {
		let langs
		if (isNew){
			langs = await http.get(`${window.__endpoint}/cha/texts/listUnusedLangs?cols=lang_id,name&order=name&text_id=${text_id}`)

		} else {
			langs = await http.get(`${window.__endpoint}/cha/text_translations/languages?cols=lang_id,name&order=name`)

		}
		setSelectOptions({
			langs: langs.data
		})
	}
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['lang_id', 'text']}
	>
		{(validator) => {

			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Lingua di traduzione */}
					<Col all={12}>
						<FormGroup
							name="lang_id"
							label="Lingua di traduzione"
							validationState={validator.validationState.code}
						>
							<Select
								disabled={!isNew}
								value={lang_id || editItem.lang_id || ''}
								onChange={onChange}
								options={selectedOptions.langs || []}
								optionValue={(o) => o.lang_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Testo gestibile dall'utente */}
					<Col all={12}>
						<FormGroup
							name="customer_edit_flag"
							label="Testo gestibile dall'utente"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.customer_edit_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Testo originale */}
					<Col all={12}>
						<FormGroup
							name="text"
							label="Testo originale"
						>
							<InputTextarea
								disabled
								value={item.text}
								//onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo tradotto */}
					<Col all={12}>
						<FormGroup
							name="text"
							label="Testo tradotto"
						>
							<InputTextarea
								value={editItem.text || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
