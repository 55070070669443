import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ data, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	const [editItem, setEditItem] = useState(data || {})

	const onChange = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const refers = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name&order=name`)
		const owns = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${105}`)

		setSelectOptions({
			refers: refers.data,
			all: owns.data,
			owns: owns.data,
		})
	}

	const handleSubmit = () => {
		onSubmit(editItem)
	}

	return <FormValidator
		onSubmit={handleSubmit}
		editItem={editItem}
		requiredFields={['refer_type_id', 'all_access_level_id', 'owner_access_level_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar hideGoBackButton />
				<Row>
					{/* Codice */}
					<Col all={12}>
						<FormGroup
							name="refer_type_id"
							label="Valore"
							validationState={validator.validationState.refer_type_id}
						>
							<Select
								value={editItem.refer_type_id || ''}
								onChange={onChange}
								options={selectOptions.refers || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="all_access_level_id"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<Select
								value={editItem.all_access_level_id || ''}
								onChange={onChange}
								options={selectOptions.all || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="owner_access_level_id"
							label="Descrizione"
						>
							<Select
								value={editItem.owner_access_level_id || ''}
								onChange={onChange}
								options={selectOptions.owns || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>
		}}
	</FormValidator>
}

export default Edit
