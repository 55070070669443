import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Releases } from '../modules/Releases'

function App() {
	return <Routes>
		<Route path="releases/*" element={<Releases />} />
	</Routes>
}

export default App
