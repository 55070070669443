import React from 'react'
import { ListGridDraggable, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	return <React.Fragment>
		<ListToolbar
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={'fields'}
			hideBtnFilter
		/>

		<ListGridDraggable
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='field_id'
			editRoot={'fields'}
			columns={[
				{ field: 'field_id', header: 'Id', sortable: true, width: '50%' },
				{ field: 'name', header: 'Nome campo', sortable: true },
				{ field: 'fixed_value', header: 'Valore fisso', sortable: true },
				{ field: 'val_order', header: 'Ordine', sortable: true },
				{ field: 'required', header: 'Campo richiesto', type: 'boolean' },
				{ field: 'user_flag', header: 'Modificabile dall\'utente', type: 'boolean' },
			]}
		/>
	</React.Fragment>
}

export default List
