import React from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ serviceId, editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['endpoint']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="endpoint"
						label="Endpoint API"
						validationState={validator.validationState.endpoint}
					>
						<InputText
							value={editItem.endpoint || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6} sm={6}>
					<FormGroup
						name="billable_flag"
						label="Fatturabile"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.billable_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6} sm={6}>
					<FormGroup
						name="amount_per_call"
						label="Costo per chiamata"
						validationState={validator.validationState.amount_per_call}
					>
						<InputText
							type="number"
							value={editItem.amount_per_call || ''}
							onChange={onChange}
							disable={editItem.billable_flag != 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
