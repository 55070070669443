import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditAttributes } from '../../Components/Attributes'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()
	let { id } = useParams()
	let refer_type_id = query.get('refer_type_id')
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
		if (isNew){
			setEditItem({ ...editItem, refer_type_id: refer_type_id })
		}
	}, [id])

	const handleChangeComboFlag = async(e) => {
		let value = Number(e.target.checked)
		let params = {}
		if (value == 0){
			params = {
				combo_table: null,
				combo_field_id: null,
				combo_query: null
			}
		}
		setEditItem({
			...editItem,
			...params,
			combo_flag: value
		})
	}

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/prc/attributes/load?attribute_id=${id}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/prc/attributes/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/prc/attributes/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea attributo' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'attributo'}`}`}>
			<EditAttributes
				refer_type_id={refer_type_id}
				onMultipleChange={handleChangeComboFlag}
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

function useQuery() {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default PageEdit
