import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	layoutConfig: {
		ripple: false,
		inputStyle: 'outlined',
		menuMode: 'static',
		colorScheme: 'dark',
		theme: 'lara-light-indigo',
		scale: 14
	},
	layoutState: {
		staticMenuDesktopInactive: false,
		overlayMenuActive: false,
		profileSidebarVisible: false,
		configSidebarVisible: false,
		staticMenuMobileActive: false,
		menuHoverActive: false
	},
	loading: 0,
	message: null,
	activeMenu: '',
}

const counterSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setLayoutConfig: (state, { payload }) => {
			state.layoutConfig = {
				...state.layoutConfig,
				...payload,
			}
		},
		setLayoutState: (state, { payload }) => {
			state.layoutState = {
				...state.layoutState,
				...payload,
			}
		},
		setMessage: (state, { payload }) => {
			state.message = payload.message
		},
		startLoad: (state) => {
			state.loading += 1
		},
		stopLoad: (state) => {
			state.loading -= 1
		},
		setActiveMenu: (state, { payload }) => {
			state.activeMenu = payload
		},
		onMenuToggle: (state) => {
			if (state.layoutConfig.menuMode === 'overlay') {
				state.layoutState = {
					...state.layoutState,
					overlayMenuActive: !state.layoutState.overlayMenuActive,
				}
			}

			if (window.innerWidth > 991) {
				state.layoutState = {
					...state.layoutState,
					staticMenuDesktopInactive: !state.layoutState.staticMenuDesktopInactive,
				}
			} else {
				state.layoutState = {
					...state.layoutState,
					staticMenuMobileActive: !state.layoutState.staticMenuMobileActive,
				}
			}
		},
		showProfileSidebar: (state) => {
			state.layoutState = {
				...state,
				profileSidebarVisible: !state.layoutState.profileSidebarVisible,
			}
		},
	},
})

export const { onMenuToggle, showProfileSidebar, setActiveMenu, setLayoutConfig, setLayoutState, setMessage, startLoad, stopLoad } = counterSlice.actions
export default counterSlice.reducer
