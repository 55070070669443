import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [filters, setFilters] = useState({})

	const handleListFilters = async() => {
		// Carico le response di tutti i filtri
		let responses = {
			modules: {
				data: await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`),
				key: 'module_id'
			},
		}

		// For each key in responses, map the value to a new array of objects with value and label, then put it in the filters state with the same key
		let _filters = {}
		for (const [key, value] of Object.entries(responses)) {
			_filters[key] = value.data.data.map(item => ({ value: item[value.key], label: item.name }))
		}

		// Set the filters state
		setFilters(_filters)
	}

	React.useEffect(() => {
		handleListFilters()
	}, [])

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
				{
					type: 'select',
					label: 'Modulo',
					name: 'module_id',
					options: filters.modules,
				},
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			items={items}
			fieldId='report_id'
			columns={[
				{ field: 'report_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'description', header: 'Descrizione', sortable: true },
				{ field: 'default_active_flag', header: 'Attivo di default', type: 'boolean', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
