import React, { useEffect, useState } from 'react'
import { Tag } from 'components/Ui'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			hideBtnAdd
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={'targets'}
			hideBtnFilter
		/>

		<ListGrid
			{...props}
			editRoot={'targets'}
			onDelete={onDelete}
			items={items}
			fieldId='phone_message_model_target_id'
			columns={[
				{ field: 'phone_message_model_target_id', header: 'Id', sortable: true },
				{ field: 'type', header: 'Tipo', sortable: true },
				{ field: 'refer_id', header: 'Valore', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'email', header: 'Email', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
