import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * pages
import { PageList, PageEdit, PageEditShop } from './Pages'

function Router() {
	return <Routes>
		<Route index element={<PageList />} />
		<Route path="create" element={<PageEdit />} />
		<Route path="edit/:id" element={<PageEdit />} />
		<Route path="edit/:envId/shops/create" element={<PageEditShop />} />
		<Route path="edit/:envId/shops/edit/:id" element={<PageEditShop />} />
	</Routes>
}

export default Router
