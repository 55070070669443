import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
	}, [])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['check']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Controllo */}
					<Col all={12}>
						<FormGroup
							name="check"
							label="Controllo"
							validationState={validator.validationState.check}
						>
							<InputText
								value={editItem.check || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Message */}
					<Col all={12}>
						<FormGroup
							name="message"
							label="Messaggio"
							validationState={validator.validationState.message}
						>
							<InputTextarea
								value={editItem.message || ''}
								onChange={onChange}
								rows={5}
							/>
						</FormGroup>
					</Col>
					{/* Link */}
					<Col all={12}>
						<FormGroup
							name="link"
							label="Link"
							validationState={validator.validationState.link}
						>
							<InputText
								value={editItem.link || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
