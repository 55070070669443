import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListGrid, ListToolbar } from 'components/List'
import { useNavigate } from 'react-router-dom'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, item_id }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => {
				navigate(`/inv/system-items/translations/create?item_id=${item_id}`)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='lang_id'
			columns={[
				{ field: 'lang_name', header: 'Lingua', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'description', header: 'Descrizione', sortable: true }
			]}
			onEdit={(lang_id) => {
				navigate(`/inv/system-items/translations/edit?item_id=${item_id}&lang_id=${lang_id}`)
			}}
		/>
	</React.Fragment>
}

export default List
