import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditVariations } from '../../Components/CodeVariations'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()

	const driver_field_id = query.get('driver_field_id')
	const condition_driver_field_id = query.get('condition_driver_field_id')
	const condition_value = query.get('condition_value')

	const isNew = (condition_driver_field_id == undefined || condition_value == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [driver_field_id, condition_driver_field_id, condition_value])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/int/drivers/fields/code_variations/load?condition_driver_field_id=${condition_driver_field_id}&driver_field_id=${driver_field_id}&condition_value=${condition_value}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const data = await http.post(`${window.__endpoint}/int/drivers/fields/code_variations/create`, {
				...editItem,
				driver_field_id: driver_field_id
			})
			const keys = data.data
			navigate(`/int/drivers/fields/code-variations/edit?driver_field_id=${keys['driver_field_id']}&condition_driver_field_id=${keys['condition_driver_field_id']}&condition_value=${keys['condition_value']}`, { replace: true })

		} else {
			await http.put(`${window.__endpoint}/int/drivers/fields/code_variations/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea variazione' : `Modifica ${editItem && editItem.field_code != '' ? editItem.field_code : 'variazione'}`}`}>
			<EditVariations
				isNew={isNew}
				driver_field_id={driver_field_id}
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

function useQuery() {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default PageEdit