import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit, driver_field_id, isNew }) {

	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])
	const loadSelectOptions = async() => {
		const drivers = await http.get(`${window.__endpoint}/int/drivers/fields/code_variations/driverFieldsList?driver_field_id=${driver_field_id}&cols=name,driver_field_id`)
		setSelectOptions({
			driver_fields: drivers.data,
			withoutSelected: drivers.data.filter((o) => o.driver_field_id != driver_field_id)
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['condition_driver_field_id','condition_value','field_code']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={6}>
					<FormGroup
						name="driver_field_id"
						label="Campo del driver su cui applicare la variazione"
						validationState={validator.validationState.driver_field_id}
					>
						<Select
							disabled
							value={ driver_field_id }
							onChange={onChange}
							options={selectOptions.driver_fields || []}
							optionValue={(o) => o.driver_field_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="condition_driver_field_id"
						label="Campo di differenziazione"
						validationState={validator.validationState.condition_driver_field_id}
					>
						<Select
							disabled={!isNew}
							value={ editItem.condition_driver_field_id || '' }
							onChange={onChange}
							options={selectOptions.withoutSelected || []}
							optionValue={(o) => o.driver_field_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="condition_value"
						label="Valore condizione"
						validationState={validator.validationState.condition_value}
					>
						<InputText
							disabled={!isNew}
							value={editItem.condition_value || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="field_code"
						label="Codice campo"
						validationState={validator.validationState.field_code}
					>
						<InputText
							value={editItem.field_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
