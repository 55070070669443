import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Topics } from '../modules/Topics'

function App() {
	return <Routes>
		<Route path="topics/*" element={<Topics />} />
	</Routes>
}

export default App
