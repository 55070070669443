import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={4}>
					<FormGroup
						name="code"
						label="Codice"
						validationState={validator.validationState.code}
					>
						<InputText
							value={editItem.code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="e_inv_code"
						label="Codice fattura elettronica"
						validationState={validator.validationState.e_inv_code}
					>
						<InputText
							value={editItem.e_inv_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
					>
						<InputTextarea
							value={editItem.description || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={4}>
					<FormGroup
						name="automatic_flag"
						label="Applicazione automatica"
						validationState={validator.validationState.automatic_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.automatic_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="calculate_vat_flag"
						label="Comprendo IVA su contributo previdenziale"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.calculate_vat_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="active_flag"
						label="Attivo"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
