import React, { useEffect, useState, useContext } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as ListsList } from '../../Components/Portal'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context } from 'apps/server/context'

function PageList() {
	const { contextSearch } = useContext(SearchContext)
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { selectedServer } = useContext(Context)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/server/bm/portals/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/server/bm/portals/delete?portal_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title="Portali BM">
		<ListsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
		/>
	</Card>
}

export default PageList
