import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGridDraggable } from 'components/List'
import { useNavigate } from 'react-router-dom'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, driver_id, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => {
				navigate(`/int/drivers/${driver_id}/fields/create`)
			}}
		/>

		<ListGridDraggable
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='driver_field_id'
			columns={[
				{ field: 'driver_field_id', header: 'Id' },
				{ field: 'name', header: 'Nome' },
				{ field: 'field_code', header: 'Codice' },
			]}
			onEdit={id => {
				navigate(`/int/drivers/fields/edit/${id}`)
			}}/>
	</React.Fragment>
}

export default List
