import React from 'react'
import { Col, FormGroup, Checkbox, HTMLEditor, InputText, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={3}>
					<FormGroup
						name="enable_from_sal_projects"
						label="Abilita il tipo dalla progettazione ordini di vendita"
						validationState={validator.validationState.enable_from_sal_projects}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.enable_from_sal_projects == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
