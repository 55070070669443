import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ print_reports, data, onSubmit }) {
	const [editItem, setEditItem] = useState(data || {})

	const onChange = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleSubmit = () => {
		const data = {
			...editItem,
			communication_model_attachment_id: editItem.communication_model_attachment_id || -1,
		}
		onSubmit(data)
	}

	return <FormValidator
		onSubmit={handleSubmit}
		editItem={editItem}
		requiredFields={['refer_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar hideGoBackButton />
				<Row>
					{/* Codice */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Referement type"
						>
							<InputText
								value={'Report di stampa'}
								disabled
							/>
						</FormGroup>
					</Col>
					{/* Codice */}
					<Col all={12}>
						<FormGroup
							name="refer_id"
							label="Nome"
							validationState={validator.validationState.refer_id}
						>
							<Select
								value={editItem.refer_id || ''}
								onChange={onChange}
								options={print_reports || []}
								optionValue={(o) => o.print_report_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>
		}}
	</FormValidator>
}

export default Edit
