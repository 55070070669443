import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ areaId, editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const listOrderTypes = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${22}`)

		setSelectOptions({
			listOrderTypes: listOrderTypes.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'val_order']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={3}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={9}>
					<FormGroup
						name="description"
						label="Descrizione"
					>
						<InputText
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={3} sm={3}>
					<FormGroup
						name="val_order"
						label="Ordine"
						validationState={validator.validationState.val_order}
					>
						<InputText
							type="number"
							value={editItem.val_order || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={9}>
					<FormGroup
						name="order_by_id"
						label="Ordine delle linee"
						validationState={validator.validationState.order_by_id}
					>
						<Select
							value={editItem.order_by_id || ''}
							onChange={onChange}
							options={selectOptions.listOrderTypes || []}
							optionValue={(o) => o.list_value_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={3} sm={3}>
					<FormGroup
						name="active_flag"
						label="Attiva"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={9} sm={9}>
					<FormGroup
						name="user_visibility_flag"
						label="Valori delle linee gestibili da utente (list line data)"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_visibility_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={12}>
					<FormGroup
						name="note"
						label="Note"
						validationState={validator.validationState.note}
					>
						<InputTextarea
							value={editItem.note || ''}
							onChange={onChange}
							rows={5}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
