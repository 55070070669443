import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputTextarea, InputText, Select, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})

	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const refer_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,referment_full_name`)

		setSelectOptions({
			refer_types: refer_types.data
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name','field_name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				{/* Riferimento */}
				<Col all={12}>
					<FormGroup
						name="refer_type_id"
						label="Riferimento"
						validationState={validator.validationState.refer_type_id}
					>
						<Select
							value={editItem.refer_type_id || ''}
							onChange={onChange}
							options={selectOptions.refer_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.referment_full_name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="field_name"
						label="Campo"
						validationState={validator.validationState.field_name}
					>
						<InputText
							value={editItem.field_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
					>
						<InputTextarea
							value={editItem.description || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="sql_query"
						label="Query SQL"
					>
						<InputTextarea
							value={editItem.sql_query || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
