import React from 'react'
import { Col, Checkbox, FormGroup, Select, InputTextarea, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [languages, setLanguages] = React.useState([])
	const [texts, setTexts] = React.useState([])
	const [untranslatedTexts, setUntranslatedTexts] = React.useState([])
	const [text, setText] = React.useState({})

	const loadLanguages = async() => {
		const response = await http.get(`${window.__endpoint}/cha/text_translations/languages?cols=lang_id,name`)
		setLanguages(response.data)
	}

	const loadTextGivenLanguage = async(e) => {
		const lang_id = e.target.value
		onChange(e)
		const response = await http.get(`${window.__endpoint}/cha/texts/list_untranslated?cols=text_id,code&lang_id=${lang_id}`)
		setUntranslatedTexts(response.data)
	}

	const handleLoadTextToTranslate = async(e) => {
		const text_id = e.target.value
		onChange(e)
		const response = await http.get(`${window.__endpoint}/cha/texts/load?text_id=${text_id}`)
		setText(response.data)
	}

	React.useEffect(() => {
		loadLanguages()
	}, [])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['lang_id', 'text']}
	>
		{(validator) => {

			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Lingua di traduzione */}
					<Col all={4}>
						<FormGroup
							name="lang_id"
							label="Lingua di traduzione"
							validationState={validator.validationState.code}
						>
							<Select
								value={editItem.lang_id || ''}
								onChange={loadTextGivenLanguage}
								options={languages || []}
								optionValue={(o) => o.lang_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Testo da tradurre */}
					<Col all={4}>
						<FormGroup
							name="text_id"
							label="Testo da tradurre"
							validationState={validator.validationState.text_id}
						>
							<Select
								disabled={!editItem.lang_id}
								value={editItem.text_id || ''}
								onChange={handleLoadTextToTranslate}
								options={untranslatedTexts || []}
								optionValue={(o) => o.text_id}
								optionLabel={(o) => o.code}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Testo gestibile dall'utente */}
					<Col all={4}>
						<FormGroup
							name="customer_edit_flag"
							label="Testo gestibile dall'utente"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.customer_edit_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Testo originale */}
					<Col all={12}>
						<FormGroup
							name="interface_flag"
							label="Testo originale"
						>
							<InputTextarea
								value={text.text || ''}
								disabled
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Testo tradotto */}
					<Col all={12}>
						<FormGroup
							name="text"
							label="Testo tradotto"
						>
							<InputTextarea
								value={editItem.text || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>

				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
