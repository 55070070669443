import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit modulen
import { Edit as OrganizationsEdit } from '../../Components/Organizations'
import { List as ListOrganizationModules } from '../../Components/OrganizationModules'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [modules, setModules] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		organization_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListModules(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei piani
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (! isNew) {
			const module = await http.get(`${window.__endpoint}/environment/cha/organizations/load?organization_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/environment/cha/organizations/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/environment/cha/organizations/update?organization_id=${id}`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei moduli
	const handleListModules = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/cha/organizations/modules/list`, {
			params: {
				...filters,
				cols: 'env_module_id,module_id,name'
			}
		})
		setModules(list.data)
	}
	const handleDeleteModules = async(id) => {
		await http.delete(`${window.__endpoint}/environment/cha/organizations/modules/delete?env_module_id=${id}`)
		handleListModules(filtersValues)
	}
	const handleCreateModules = async(data) => {
		await http.post(`${window.__endpoint}/environment/cha/organizations/modules/save`, {
			organization_id: id, // ID Ruolo
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModules(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea organizzazione' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'organizzazione'}`}`}>
			<OrganizationsEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Modifica moduli">
				<ListOrganizationModules
					organization_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={modules}
					onList={handleListModules}
					onDelete={handleDeleteModules}
					onSubmit={handleCreateModules}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
