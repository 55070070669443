import { configureStore } from '@reduxjs/toolkit'
import uiReducer from './ui/uiSlice'
import userReducer from './user/userSlice'

export default configureStore({
	reducer: {
		ui: uiReducer,
		user: userReducer,
	}
})
