import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit FileTypes
import { Edit as EditScriptParameter } from '../../Components/ScriptParameters'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const query = useQuery()
	let scriptId = query.get('script_id')
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	//* Gestione dei parametri
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/develop/scripts/parameters/load?script_parameter_id=${id}`)
			setEditItem(module.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/develop/scripts/parameters/create`, {
				...editItem,
				script_id: scriptId
			})
			navigate(`/develop/scripts/parameters/edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/develop/scripts/parameters/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea parametro' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'parametro'}`}`}>
			<EditScriptParameter
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

function useQuery() {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default PageEdit
