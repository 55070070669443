import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as ModuleEdit } from '../../Components/Module'
import { List as ListReferements } from '../../Components/ModuleReferements'
import { List as ListNodes } from '../../Components/ModuleNodes'
import { List as ListRoles } from '../../Components/ModuleRoles'
import { List as ListObjects } from '../../Components/ModuleObjects'
import { List as ListChecks } from '../../Components/ModuleChecks'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [referementTypes, setReferementTypes] = useState([])
	const [nodes, setNodes] = useState([])
	const [roles, setRoles] = useState([])
	const [objects, setObjects] = useState([])
	const [checks, setChecks] = useState([])

	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		module_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListReferementTypes(defaultFiltersValues)
			handleListNodes(defaultFiltersValues)
			handleListRoles(defaultFiltersValues)
			handleListObjects(defaultFiltersValues)
			handleListChecks(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/cha/modules/load?module_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/modules/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/modules/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei riferimenti
	const handleListReferementTypes = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/modules/referement_types/list`, {
			params: {
				...filters
			}
		})
		setReferementTypes(list.data)
	}
	const handleDeleteReferementTypes = async(id) => {
		await http.delete(`${window.__endpoint}/cha/modules/referement_types/delete?module_refer_type_id=${id}`)
		handleListReferementTypes(filtersValues)
	}
	const handleCreateReferementTypes = async(data) => {
		await http.post(`${window.__endpoint}/cha/modules/referement_types/create`, {
			module_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListReferementTypes(filtersValues)
	}

	//* Gestione dei nodi
	const handleListNodes = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/modules/nodes/list`, {
			params: {
				...filters,
			}
		})
		setNodes(list.data)
	}
	const handleDeleteNode = async(id) => {
		await http.delete(`${window.__endpoint}/cha/modules/nodes/delete?module_node_id=${id}`)
		handleListNodes(filtersValues)
	}
	const handleCreateNode = async(data) => {
		await http.post(`${window.__endpoint}/cha/modules/nodes/create`, {
			module_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListNodes(filtersValues)
	}

	//* Gestione dei ruoli
	const handleListRoles = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/modules/roles/list`, {
			params: {
				...filters,
			}
		})
		setRoles(list.data)
	}
	const handleDeleteRoles = async(id) => {
		await http.delete(`${window.__endpoint}/cha/modules/roles/delete?module_role_id=${id}`)
		handleListRoles(filtersValues)
	}
	const handleCreateRoles = async(data) => {
		await http.post(`${window.__endpoint}/cha/modules/roles/create`, {
			module_id: id, // ID Lista valore
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListRoles(filtersValues)
	}

	//* Gestione dei Oggetti
	const handleListObjects = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/modules/objects/list`, {
			params: {
				...filters,
			}
		})
		setObjects(list.data)
	}
	const handleDeleteObjects = async(id) => {
		await http.delete(`${window.__endpoint}/cha/module_objects/delete?module_object_id=${id}`)
		handleListObjects(filtersValues)
	}
	const handleCreateObjects = async(data) => {
		await http.post(`${window.__endpoint}/cha/module_objects/create`, {
			module_id: id, // ID Lista valore
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListObjects(filtersValues)
	}
	//* Gestione dei controlli
	const handleListChecks = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/modules/checks/list`, {
			params: {
				...filters,
			}
		})
		setChecks(list.data)
	}
	const handleDeleteChecks = async(id) => {
		await http.delete(`${window.__endpoint}/cha/modules/checks/delete?module_check_id=${id}`)
		handleListChecks(filtersValues)
	}
	const handleCreateChecks = async() => {
		navigate(`/cha/modules/${id}/checks/create`)

	}

	return <>
		<Card title={`${isNew ? 'Crea modulo' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'modulo'}`}`}>
			<ModuleEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Modifica riferimenti">
				<ListReferements
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={referementTypes}
					onList={handleListReferementTypes}
					onDelete={handleDeleteReferementTypes}
					onSubmit={handleCreateReferementTypes}
				/>
			</Card>
			<br />
			<Card title="Modifica nodi">
				<ListNodes
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={nodes}
					onList={handleListNodes}
					onDelete={handleDeleteNode}
					onSubmit={handleCreateNode}
					list_id={id}
				/>
			</Card>
			<br />
			<Card title="Modifica ruoli">
				<ListRoles
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={roles}
					onList={handleListRoles}
					onDelete={handleDeleteRoles}
					onSubmit={handleCreateRoles}
					list_id={id}
				/>
			</Card>
			<br />
			<Card title="Modifica oggetti">
				<ListObjects
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={objects}
					onList={handleListObjects}
					onDelete={handleDeleteObjects}
					onSubmit={handleCreateObjects}
					module_id={id}
				/>
			</Card>
			<br />
			<Card title="Lista controlli">
				<ListChecks
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={checks}
					onList={handleListChecks}
					onDelete={handleDeleteChecks}
					onSubmit={handleCreateChecks}
					module_id={id}
				/>
			</Card>
		</React.Fragment>}
	</>
}

export default PageEdit
