import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, HTMLEditor, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)

		setSelectOptions({
			modules: modules.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['title']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={6}>
					<FormGroup
						name="title"
						label="Titolo"
						validationState={validator.validationState.title}
					>
						<InputText
							value={editItem.title || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="module_id"
						label="Modulo"
						validationState={validator.validationState.module_id}
					>
						<Select
							value={Number(editItem.module_id) || ''}
							onChange={onChange}
							options={selectOptions.modules || []}
							optionValue={(o) => Number(o.module_id)}
							optionLabel={(o) => o.name}
							all={{
								label: 'Piattaforma'
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="intro"
						label="Testo introduttivo"
						validationState={validator.validationState.intro}
					>
						<InputText
							type="text"
							value={editItem.intro || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="text"
						label="Testo"
						validationState={validator.validationState.text}
					>
						<HTMLEditor
							value={editItem.text}
							onChange={onChange}
							modules={{
								toolbar: [
									[{ 'header': [1, 5, false] }],
									['bold', 'italic', 'underline','strike', 'blockquote'],
									[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
									[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
									['link', 'image'],
									['clean']
								]
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="video_url"
						label="Codice video youtube"
						validationState={validator.validationState.video_url}
					>
						<InputText
							value={editItem.video_url || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="intro_flag"
						label="Argomento introduttivo"
						validationState={validator.validationState.intro_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.intro_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="active_flag"
						label="Attivo"
						validationState={validator.validationState.active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
