import React, { useState, useEffect } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const portals = await http.get(`${window.__endpoint}/environment/bm/server_portals/list?cols=portal_id,web_url&order=web_url`)

		setSelectOptions({
			portals: portals.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'bm_url']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					<Col all={6}>
						<FormGroup
							name="name"
							label="Nome del portale"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{editItem.portal_id > 0 && <Col all={6}>
						<FormGroup
							name="bm_url"
							label="URL del portale"
							validationState={validator.validationState.bm_url}
						>
							<InputText
								value={editItem.bm_url || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>}
					{!(editItem.portal_id > 0) && <Col all={6}>
						<FormGroup
							name="bm_url"
							label="URL del portale"
							validationState={validator.validationState.bm_url}
						>
							<Select
								value={editItem.bm_url || ''}
								onChange={onChange}
								options={selectOptions.portals || []}
								optionValue={(o) => o.web_url}
								optionLabel={(o) => o.web_url}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>}
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
