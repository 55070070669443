import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as ListFormat } from '../../Components/PrintReportsFormats'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context as ServerContext } from 'apps/environment/context'

function PageList() {
	const [items, setItems] = React.useState([])
	const [filtersValues, setFiltersValues] = React.useState({})
	const { contextSearch } = useContext(SearchContext)
	const { selectedServer, selectedEnvironment } = useContext(ServerContext)
	const defaultFiltersValues = {
		cols: 'format_id,name,active_state',
	}

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer, selectedEnvironment])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/cha/print_reports_formats/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleEnvEnable = async(id) => {
		await http.post(`${window.__endpoint}/environment/cha/print_reports_formats/add_to_environment?format_id=${id}`)
		handleList(filtersValues)
	}

	const handleEnvDisable = async(id) => {
		await http.post(`${window.__endpoint}/environment/cha/print_reports_formats/remove_from_environment?format_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title="Formati di stampa">
		<ListFormat
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onEnvEnable={handleEnvEnable}
			onEnvDisable={handleEnvDisable}
		/>
	</Card>
}

export default PageList
