import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const refer_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name&order=name`)
		const report_types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${23}`)
		const report_generation_types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${53}`)

		setSelectOptions({
			refer_types: refer_types.data,
			report_generation_types: report_generation_types.data,
			report_types: report_types.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'refer_type_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Attivo di default (report standard) */}
					<Col all={12}>
						<FormGroup
							name="default_active_flag"
							label="Attivo di default su ogni ambiente per ogni organizzazione"
							validationState={validator.validationState.default_active_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.default_active_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Ordine */}
					<Col all={6}>
						<FormGroup
							name="list_order"
							label="Ordine"
							validationState={validator.validationState.list_order}
						>
							<InputText
								type="number"
								value={editItem.list_order || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Referement type */}
					<Col all={6}>
						<FormGroup
							name="refer_type_id"
							label="Referement type"
							validationState={validator.validationState.refer_type_id}
						>
							<Select
								value={editItem.refer_type_id || ''}
								onChange={onChange}
								options={selectOptions.refer_types || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tipo di report */}
					<Col all={6}>
						<FormGroup
							name="print_report_type_id"
							label="Tipo di report"
							validationState={validator.validationState.print_report_type_id}
						>
							<Select
								value={editItem.print_report_type_id || ''}
								onChange={onChange}
								options={selectOptions.report_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Tipo generazione report */}
					<Col all={6}>
						<FormGroup
							name="generator_id"
							label="Tipo generazione report"
							validationState={validator.validationState.generator_id}
						>
							<Select
								value={editItem.generator_id || ''}
								onChange={onChange}
								options={selectOptions.report_generation_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome da parent refer type */}
					<Col all={6}>
						<FormGroup
							name="file_name_from_parent_refer_type_id"
							label="Nome da parent refer type"
							validationState={validator.validationState.file_name_from_parent_refer_type_id}
						>
							<Select
								value={editItem.file_name_from_parent_refer_type_id || ''}
								onChange={onChange}
								options={selectOptions.refer_types || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Parametro (refer_id del refer_type_id) da utilizzare per comporre il nome */}
					<Col all={6}>
						<FormGroup
							name="file_name_from_parent_refer_id"
							label="Parametro (refer_id del refer_type_id) da utilizzare per comporre il nome"
							validationState={validator.validationState.file_name_from_parent_refer_id}
						>
							<InputText
								type="number"
								value={editItem.file_name_from_parent_refer_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome */}
					<Col all={6}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								type="text"
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* File generatore del report (.jasper, .php o servizio template engine) */}
					<Col all={6}>
						<FormGroup
							name="generator_file"
							label="File generatore del report (.jasper, .php o servizio template engine)"
							validationState={validator.validationState.generator_file}
						>
							<InputText
								type="text"
								value={editItem.generator_file || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome file */}
					<Col all={12}>
						<FormGroup
							name="file_name"
							label="Nome file"
							validationState={validator.validationState.file_name}
						>
							<InputText
								type="number"
								value={editItem.file_name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome file da funzione SQL */}
					<Col all={12}>
						<FormGroup
							name="file_name_function"
							label="Nome file da funzione SQL"
							validationState={validator.validationState.file_name_function}
						>
							<InputText
								type="number"
								value={editItem.file_name_function || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								rows={5}
								type="number"
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Genera allegato nel record padre della comunicazione */}
					<Col all={12}>
						<FormGroup
							name="generate_attachment_flag"
							label="Genera allegato nel record padre della comunicazione"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.generate_attachment_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				{editItem.generator_id == 4 && <>
					<Row>
						{/* Print report con tema (altrimenti utilizza system) */}
						<Col all={4}>
							<FormGroup
								name="themed_flag"
								label="Print report con tema (altrimenti utilizza system)"
							>
								<Checkbox
									onChange={onChange}
									checked={editItem.themed_flag == 1}
								/>
							</FormGroup>
						</Col>
						{/* Stampa header */}
						<Col all={4}>
							<FormGroup
								name="print_header_flag"
								label="Stampa header"
							>
								<Checkbox
									onChange={onChange}
									checked={editItem.print_header_flag == 1}
								/>
							</FormGroup>
						</Col>
						{/* Stampa footer */}
						<Col all={4}>
							<FormGroup
								name="print_footer_flag"
								label="Stampa footer"
							>
								<Checkbox
									onChange={onChange}
									checked={editItem.print_footer_flag == 1}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						{/* Orientamento foglio */}
						<Col all={6}>
							<FormGroup
								name="orientation"
								label="Orientamento foglio"
								validationState={validator.validationState.orientation}
							>
								<Select
									value={editItem.orientation || ''}
									onChange={onChange}
									options={[
										{ orientation: 'p', name: 'Verticale' },
										{ orientation: 'l', name: 'Orizzontale' },
									]}
									optionValue={(o) => o.orientation}
									optionLabel={(o) => o.name}
									// all={{
									// 	label: ' - Seleziona - '
									// }}
								/>
							</FormGroup>
						</Col>
					</Row>
				</>}
			</form>}}
	</FormValidator>
}
export default Edit
