import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='concurrent_id'
			columns={[
				{ field: 'concurrent_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Concorrente', sortable: true },
				{ field: 'module_name', header: 'Modulo', sortable: true  },
				{ field: 'concurrent_type_name', header: 'Tipo concorrente', sortable: true },
				{ field: 'default_active_flag', header: 'Attivo di default', type: 'boolean', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
