import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})

	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const refer_type_groups = await http.get(`${window.__endpoint}/cha/referement_types/groups/list?cols=group_id,full_name&order=name`)

		setSelectOptions({
			refer_type_groups: refer_type_groups.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'db_field_id', 'db_table']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome singolare */}
					<Col all={6}>
						<FormGroup
							name="name"
							label="Nome singolare"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Nome plurale */}
					<Col all={6}>
						<FormGroup
							name="name_plural"
							label="Nome plurale"
							validationState={validator.validationState.name_plural}
						>
							<InputText
								value={editItem.name_plural || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputText
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="group_id"
							label="Gruppo del riferimento"
						>
							<Select
								value={editItem.group_id || ''}
								onChange={onChange}
								options={selectOptions.refer_type_groups || []}
								optionValue={(o) => o.group_id}
								optionLabel={(o) => o.full_name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tabella */}
					<Col all={3}>
						<FormGroup
							name="db_table"
							label="Tabella"
							validationState={validator.validationState.db_table}
						>
							<InputText
								value={editItem.db_table || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Ordine */}
					<Col all={3}>
						<FormGroup
							name="db_field_id"
							label="Campo ID"
							validationState={validator.validationState.db_field_id}
						>
							<InputText
								value={editItem.db_field_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Attivo */}
					<Col all={3}>
						<FormGroup
							name="db_field_name"
							label="Campo nome"
						>
							{/* <Checkbox
								onChange={onChange}
								checked={editItem.db_field_name == 1}
							/> */}
							<InputText
								value={editItem.db_field_name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={3}>
						<FormGroup
							name="db_field_description"
							label="Campo descrizione"
						>
							<InputText
								value={editItem.db_field_description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Campo Stato */}
					<Col all={3}>
						<FormGroup
							name="db_field_state_id"
							label="Campo Stato"
							validationState={validator.validationState.db_field_state_id}
						>
							<InputText
								value={editItem.db_field_state_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Campo Condizione */}
					<Col all={3}>
						<FormGroup
							name="db_field_condition"
							label="Campo Condizione"
							validationState={validator.validationState.db_field_condition}
						>
							<InputText
								value={editItem.db_field_condition || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Ordinamento */}
					<Col all={3}>
						<FormGroup
							name="db_field_orderby"
							label="Ordinamento"
							validationState={validator.validationState.db_field_orderby}
						>
							<InputText
								value={editItem.db_field_orderby || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Campo Condizione (query list) */}
					<Col all={3}>
						<FormGroup
							name="db_condition"
							label="Campo Condizione (query list)"
						>
							<InputText
								value={editItem.db_condition || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* *Campo Info Query* */}
					<Col all={3}>
						<FormGroup
							name="db_filed_info_query"
							label="*Campo Info Query*"
							validationState={validator.validationState.db_filed_info_query}
						>
							<InputText
								value={editItem.db_filed_info_query || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Procedura Cambio Stato */}
					<Col all={6}>
						<FormGroup
							name="db_procedure_change_state"
							label="Procedura Cambio Stato"
							validationState={validator.validationState.db_procedure_change_state}
						>
							<InputText
								value={editItem.db_procedure_change_state || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Tabella del db cha */}
					<Col all={3}>
						<FormGroup
							name="cha_flag"
							label="Tabella del db cha"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.cha_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Info query */}
					<Col all={12}>
						<FormGroup
							name="info_query"
							label="Info query"
							validationState={validator.validationState.info_query}
						>
							<InputTextarea
								value={editItem.info_query || ''}
								onChange={onChange}
								rows={5}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Note */}
					<Col all={12}>
						<FormGroup
							name="note"
							label="Note"
							validationState={validator.validationState.note}
						>
							<InputTextarea
								value={editItem.note || ''}
								onChange={onChange}
								rows={5}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Comunicazioni */}
					<Col all={2}>
						<FormGroup
							name="communications_enable"
							label="Comunicazioni"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.communications_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Files */}
					<Col all={2}>
						<FormGroup
							name="files_enable"
							label="Files"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.files_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Immagini */}
					<Col all={2}>
						<FormGroup
							name="images_enable"
							label="Immagini"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.images_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Print reports */}
					<Col all={2}>
						<FormGroup
							name="print_reports_enable"
							label="Print reports"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.print_reports_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Reports */}
					<Col all={2}>
						<FormGroup
							name="reports_enable"
							label="Reports"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.reports_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Commenti */}
					<Col all={2}>
						<FormGroup
							name="comments_enable"
							label="Commenti"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.comments_enable == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Eventi */}
					<Col all={2}>
						<FormGroup
							name="events_enable"
							label="Eventi"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.events_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Promemoria */}
					<Col all={2}>
						<FormGroup
							name="tasks_enable"
							label="Promemoria"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.tasks_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Tags */}
					<Col all={2}>
						<FormGroup
							name="tags_enable"
							label="Tags"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.tags_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Modifica del riferimento */}
					<Col all={2}>
						<FormGroup
							name="refer_change_enable"
							label="Modifica del riferimento"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.refer_change_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Gestione degli utenti per refer_id */}
					<Col all={2}>
						<FormGroup
							name="module_user_objects_enable"
							label="Gestione degli utenti per refer_id"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.module_user_objects_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Gestione stato personalizzato */}
					<Col all={2}>
						<FormGroup
							name="state_custom_enable"
							label="Gestione stato personalizzato"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.state_custom_enable == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Messaggi (whatsapp) */}
					<Col all={2}>
						<FormGroup
							name="phone_messages_enable"
							label="Messaggi (whatsapp)"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.phone_messages_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Note personalizzate */}
					<Col all={2}>
						<FormGroup
							name="note_enable"
							label="Note personalizzate"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.note_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Registro */}
					<Col all={2}>
						<FormGroup
							name="register_enable"
							label="Abilita registro prelievi/restituzioni"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.register_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Riferimento contenitore (es. Cantieri) */}
					<Col all={2}>
						<FormGroup
							name="is_container_flag"
							label="Riferimento contenitore (es. Cantieri)"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.is_container_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Abilita traduzione */}
					<Col all={2}>
						<FormGroup
							name="translatable"
							label="Abilita traduzione"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.translatable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Abilita assegnazione utente */}
					<Col all={2}>
						<FormGroup
							name="assigned_by_enable"
							label="Abilita assegnazione utente"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.assigned_by_enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Scadenza visibile */}
					<Col all={2}>
						<FormGroup
							name="expiration_visibility"
							label="Scadenza visibile"
						>
							<Checkbox
								checked={editItem.db_filed_info_query == 1}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col all={3}>
						<FormGroup
							name="pricing_visibility"
							label="Visibile nei modificatori di prezzo/listini prezzo"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.pricing_visibility == 1}
							/>
						</FormGroup>
					</Col>
					{/* Object Modules and Roles */}
					<Col all={3}>
						<FormGroup
							name="object_visibility"
							label="Visibile negli oggetti (module_objects per esempio)"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.object_visibility == 1}
							/>
						</FormGroup>
					</Col>
					{/* Riferimenti visibili genericamente quado è abilitata la modifica del riferimento */}
					<Col all={3}>
						<FormGroup
							name="refer_change_assign_visibility"
							label="Visibile nell'assegnazione del riferimento"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.refer_change_assign_visibility == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
