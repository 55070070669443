import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditDriver } from '../../Components/Drivers'
import { List as ListScripts } from '../../Components/Scripts'
import { List as ListGroups } from '../../Components/FieldsGroups'
import { List as ListFields } from '../../Components/Fields'
import { orderBy } from 'lodash'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [scripts, setScripts] = useState({})
	const [groups, setGroups] = useState({})
	const [fields, setFields] = useState({})

	const [isNewGroup, setIsNewGroup] = useState(true) //verifico se sto creando un nuovo gruppo o ne sto modificando uno

	useEffect(() => {
		handleLoad()
		handleListScripts()
		handleListGroups()
		handleListFields()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/int/drivers/load?driver_id=${id}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/int/drivers/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/int/drivers/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//gestione scripts
	const handleListScripts = async(filters) => {
		const list = await http.get(`${window.__endpoint}/int/drivers/scripts/list`, {
			params: {
				...filters,
				driver_id: id,
			}
		})
		setScripts(list.data)
	}

	const handleDeleteScript = async(id) => {
		await http.delete(`${window.__endpoint}/int/drivers/scripts/delete?driver_script_id=${id}`)
		handleListScripts()
	}

	//gestione gruppi
	const handleListGroups = async(filters) => {
		const list = await http.get(`${window.__endpoint}/int/drivers/fields_groups/list`, {
			params: {
				...filters,
				driver_id: id,
			}
		})
		setGroups(list.data)
	}
	const handleSaveGroups = async(data) => {
		if (isNewGroup) {
			await http.post(`${window.__endpoint}/int/drivers/fields_groups/create`, {
				...data,
				driver_id: id,
			})
		} else {
			await http.put(`${window.__endpoint}/int/drivers/fields_groups/update`, data)
		}
		handleListGroups()
	}

	const handleDeleteGroup = async(id) => {
		await http.delete(`${window.__endpoint}/int/drivers/fields_groups/delete?driver_fields_group_id=${id}`)
		handleListGroups()
	}

	// gestione campi
	const handleSortFields = async(filters) => {
		await http.post(`${window.__endpoint}/int/drivers/fields/sort`, {
			topics: filters,
			driver_id: id
		})
	}

	const handleListFields = async(filters) => {
		const list = await http.get(`${window.__endpoint}/int/drivers/fields/list`, {
			params: {
				driver_id: id,
				order: 'val_order'
			}
		})
		setFields(list.data)
	}

	const handleDeleteField = async(id) => {
		await http.delete(`${window.__endpoint}/int/drivers/fields/delete?driver_field_id=${id}`)
		handleListFields()
	}

	const handleSetFields = ordered_fields => {
		setFields(ordered_fields)
	}
	return <>
		<Card title={`${isNew ? 'Crea driver' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'driver'}`}`}>
			<EditDriver
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Elenco scripts">
				<ListScripts
					driver_id={id}
					items={scripts}
					onList={handleListScripts}
					onDelete={handleDeleteScript}
				/>
			</Card>
			<br />
			<Card title="Elenco gruppi di campi">
				<ListGroups
					setIsNewGroup={setIsNewGroup}
					onSubmit={handleSaveGroups}
					items={groups}
					onList={handleListGroups}
					onDelete={handleDeleteGroup}
				/>
			</Card>
			<br />
			<Card title="Elenco campi">
				<ListFields
					driver_id={id}
					handleSort={handleSortFields}
					items={fields}
					onList={handleListFields}
					onDelete={handleDeleteField}
					handleSetItems={handleSetFields}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
