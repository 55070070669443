import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, Button } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, list_id, ...props }) {
	const { onSubmit } = props
	const [nodes, setNodes] = React.useState([])

	const [selectedNode, setSelectedNode] = React.useState()

	const handleLoadOptions = async() =>{
		const nodes = await http.get(`${window.__endpoint}/cha/nodes/listAll?cols=node_id,node_full_name`)

		setNodes(nodes.data)
	}

	React.useEffect(() => {
		handleLoadOptions()
	}, [])

	const handleSubmit = () =>{
		onSubmit({
			node_permission_id: -1,
			node_id: selectedNode,
		})
		setSelectedNode(null)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>
		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedNode || ''}
					onChange={(e) => setSelectedNode(e.target.value)}
					options={nodes || []}
					optionValue={(o) => o.node_id}
					optionLabel={(o) => o.node_full_name}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Button onClick={handleSubmit} disabled={! selectedNode}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='node_permission_id'
			columns={[
				{ field: 'node_permission_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
