import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import { ColorPicker } from 'primereact/colorpicker'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputTextarea
							rows={5}
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="folder"
						label="Cartella"
						validationState={validator.validationState.folder}
					>
						<InputText
							value={editItem.folder || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="color_light"
						label="Colore chiaro"
					>
						<ColorPicker
							value={editItem.color_light || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="color_main"
						label="Colore principale"
					>
						<ColorPicker
							value={editItem.color_main || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="color_dark"
						label="Colore scuro"
					>
						<ColorPicker
							value={editItem.color_dark || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="text"
						label="Colore testo"
					>
						<ColorPicker
							value={editItem.text || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="text_light"
						label="Colore testo chiaro"
					>
						<ColorPicker
							value={editItem.text_light || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="text_over_main"
						label="text_over_main"
					>
						<ColorPicker
							value={editItem.text_over_main || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="text_over_dark"
						label="text_over_dark"
					>
						<ColorPicker
							value={editItem.text_over_dark || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="table_header_bg"
						label="Sfondo intestazione tabella"
					>
						<ColorPicker
							value={editItem.table_header_bg || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="table_border"
						label="Colore bordo tabela"
					>
						<ColorPicker
							value={editItem.table_border || ''}
							onChange={(e) => onChange(e)}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
