import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['shipping_method']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Metodo di pagamento */}
					<Col all={6}>
						<FormGroup
							name="shipping_method"
							label="Metodo di spedizione"
							validationState={validator.validationState.shipping_method}
						>
							<InputText
								value={editItem.shipping_method || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={6}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputText
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
