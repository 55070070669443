import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputTextarea, InputText, Checkbox, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const item_types = await http.get(`${window.__endpoint}/inv/itemTypes/list?cols=item_type_id,name&order=name`)
		const um = await http.get(`${window.__endpoint}/utl/um/list?cols=um_id,name&order=um_group_id,name`)

		setSelectOptions({
			item_types: item_types.data,
			um: um.data
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name','item_type_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Codice articolo */}
					<Col all={12}>
						<FormGroup
							name="item_code"
							label="Codice articolo"
							validationState={validator.validationState.item_code}
						>
							<InputText
								value={editItem.item_code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* UM */}
					<Col all={6}>
						<FormGroup
							name="um_id"
							label="Unita di misura"
							validationState={validator.validationState.um_id}
						>
							<Select
								value={editItem.um_id || ''}
								onChange={onChange}
								options={selectOptions.um || []}
								optionValue={(o) => o.um_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Tipo articolo */}
					<Col all={6}>
						<FormGroup
							name="item_type_id"
							label="Tipo di articolo"
							validationState={validator.validationState.item_type_id}
						>
							<Select
								value={editItem.item_type_id || ''}
								onChange={onChange}
								options={selectOptions.item_types || []}
								optionValue={(o) => o.item_type_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								rows={4}
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Spedibile */}
					<Col all={3}>
						<FormGroup
							name="shippable"
							label="Spedibile"
							validationState={validator.validationState.shippable}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.shippable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Fatturabile */}
					<Col all={3}>
						<FormGroup
							name="billable"
							label="Fatturabile"
							validationState={validator.validationState.billable}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.billable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Vendibile */}
					<Col all={3}>
						<FormGroup
							name="salable"
							label="Vendibile"
							validationState={validator.validationState.salable}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.salable == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
