import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditField } from '../../Components/Fields'
import { List as ListEnums } from '../../Components/Enums'
import { List as ListCodeVariations } from '../../Components/CodeVariations'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id, field_id } = useParams()
	const isNew = (field_id == undefined)
	const [editItem, setEditItem] = useState({})
	const [enums, setEnums] = useState({})
	const [variations, setVariations] = useState({})

	useEffect(() => {
		handleLoad()
		handleListEnums()
		handleListVariations()
	}, [id, field_id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/int/drivers/fields/load?driver_field_id=${field_id}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const field_id = await http.post(`${window.__endpoint}/int/drivers/fields/create`, {
				...editItem,
				driver_id: id
			})
			navigate(`/int/drivers/fields/edit/${field_id.data}`, { replace: true })

		} else {
			await http.put(`${window.__endpoint}/int/drivers/fields/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}
	//gestione enumeratori
	const handleListEnums = async(filters) => {
		const list = await http.get(`${window.__endpoint}/int/drivers/fields/enums/list`, {
			params: {
				...filters,
				driver_field_id: field_id,
			}
		})
		setEnums(list.data)
	}

	const handleDeleteEnums = async(id) => {
		await http.delete(`${window.__endpoint}/int/drivers/fields/enums/delete?field_enum_id=${id}`)
		handleListEnums()
	}

	//gestione variazioni
	const handleListVariations = async(filters) => {
		const list = await http.get(`${window.__endpoint}/int/drivers/fields/code_variations/list`, {
			params: {
				...filters,
				driver_field_id: field_id,
			}
		})
		setVariations(list.data)
	}

	const handleDeleteVariation = async(txt) => {
		const keys = txt.split(',')
		await http.delete(`${window.__endpoint}/int/drivers/fields/code_variations/delete?driver_field_id=${keys[0]}&condition_driver_field_id=${keys[1]}&condition_value=${keys[2]}`)
		handleListVariations()
	}

	return <>
		<Card title={`${isNew ? 'Crea campo' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'campo'}`}`}>
			<EditField
				driver_id={id}
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Valori">
				<ListEnums
					field_id={field_id}
					items={enums}
					onList={handleListEnums}
					onDelete={handleDeleteEnums}
					onChange={handleChangeEditItem}
				/>
			</Card>
			<br />
			<Card title="Variazioni">
				<ListCodeVariations
					field_id={field_id}
					items={variations}
					onList={handleListVariations}
					onDelete={handleDeleteVariation}
					//onChange={handleChangeVariation}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit