import React from 'react'

function FormGroup({ children, name, label, validationState, description }) {
	// Aggiungo all'elemento contenuto nel Formgroup lo stesso id e le classi full width e small
	let newElement
	if (children.type && children.type.name == 'InputGroup') {
		newElement = React.cloneElement(children, {
			id: name,
			name: name,
			className: `${children.props.className || ''} w-full ${validationState ? 'p-invalid' : ''}`,
			validationState: validationState,
		})
	} else {
		newElement = React.cloneElement(children, {
			id: name,
			name: name,
			className: `${children.props.className || ''} w-full ${validationState ? 'p-invalid' : ''}`,
		})
	}

	return <React.Fragment>
		<label htmlFor={name} className="font-bold block mb-1">
			{label}
		</label>
		{description && <div style={{ paddingBottom: 5 }}>
			<small>
				{description}
			</small>
		</div>}
		{ newElement }
		{!!validationState && <small className='p-error'>{validationState}</small>}
	</React.Fragment>
}

export default FormGroup
