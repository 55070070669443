import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TreeTable } from 'primereact/treetable'
import { Column } from 'primereact/column'
import { confirmDialog } from 'primereact/confirmdialog'
import { SplitButton } from 'components/Ui'

function ListGridTree({ columns, fieldId, items, onDelete, editRoot }) {
	const navigate = useNavigate()

	const actionsTemplate = (value) => {
		const items = [
			{
				label: 'elimina',
				icon: 'pi pi-trash',
				command: () => {
					confirmDialog({
						message: 'Procedere con l\'eliminazione?',
						header: 'Logout utente',
						icon: 'pi pi-exclamation-triangle',
						accept: () => onDelete(value.data[fieldId]),
						reject: () => { /* Do nothing */}
					})
				}
			},
		]
		return <SplitButton label="modifica" onClick={() => {navigate(`./${!editRoot ? '' : `${editRoot}/`}edit/${value.data[fieldId]}`)}} model={items} />
	}

	return <div style={{ width: '100%' }}>
		<TreeTable size='small' value={items} scrollHeight="calc(100vh - 21rem)">
			{columns.map((column, c) => <Column
				expander={c == 0}
				key={c}
				field={column.field}
				body={column.body}
				header={column.header}
				sortable={column.sortable}
			/>)}
			<Column body={actionsTemplate} header="" style={{ width: '155px' }}></Column>
		</TreeTable>
	</div>
}

export default ListGridTree
