import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit, driver_id }) {

	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const groups = await http.get(`${window.__endpoint}/int/drivers/fields_groups/list?driver_id=${driver_id}&cols=name,driver_fields_group_id`)
		const types = [
			{
				name: 'Settings',
				type_id: '1'
			},
			{
				name: 'Fields',
				type_id: '2'
			}
		]
		setSelectOptions({
			groups: groups.data,
			types: types
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name','field_code','type_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="field_code"
						label="Codice campo"
						validationState={validator.validationState.field_code}
					>
						<InputText
							value={editItem.field_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="driver_fields_group_id"
						label="Gruppo dei campi"
						validationState={validator.validationState.driver_fields_group_id}
					>
						<Select
							value={editItem.driver_fields_group_id || ''}
							onChange={onChange}
							options={selectOptions.groups || []}
							optionValue={(o) => o.driver_fields_group_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="type_id"
						label="Tipo"
						validationState={validator.validationState.type_id}
					>
						<Select
							value={editItem.type_id || ''}
							onChange={onChange}
							options={selectOptions.types || []}
							optionValue={(o) => o.type_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="export_display_flag"
						label="export_display_flag"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.export_display_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="required"
						label="Obbligatorio"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.required == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="fix_value"
						label="Valore fisso"
						validationState={validator.validationState.fix_value}
					>
						<InputText
							value={editItem.fix_value || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="enum_description"
						label="Descrizione enumeratore"
						validationState={validator.validationState.enum_description}
					>
						<InputText
							value={editItem.enum_description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="field_sql_script"
						label="Script SQL"
					>
						<InputTextarea
							value={editItem.field_sql_script || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
