import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues, onSubmit }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const editRoot = 'checks'

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={onSubmit}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='module_check_id'
			columns={[
				{ field: 'module_check_id', header: 'Id', sortable: true },
				{ field: 'check', header: 'Controllo', sortable: true, width: '350px' },
			]}
		/>
	</React.Fragment>
}

export default List
