import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { SearchContext } from 'core/contexts/SearchContext'

// * pages
import { PageList, PageEdit } from './Pages'

function Router() {
	const [contextSearch, setContextSearch] = useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageList />} />
			<Route path="create" element={<PageEdit />} />
			<Route path="edit/:id" element={<PageEdit />} />
		</Routes>
	</SearchContext.Provider>
}

export default Router