import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ report_id, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
	}, [])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['serie_title', 'field']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Titolo serie */}
					<Col all={12}>
						<FormGroup
							name="serie_title"
							label="Titolo serie"
							validationState={validator.validationState.serie_title}
						>
							<InputText
								value={editItem.serie_title || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Tipo serie */}
					<Col all={6}>
						<FormGroup
							name="serie_type"
							label="Tipo serie"
							validationState={validator.validationState.serie_type}
						>
							<InputText
								value={editItem.serie_type || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Ordine */}
					<Col all={6}>
						<FormGroup
							name="val_order"
							label="Ordine"
							validationState={validator.validationState.val_order}
						>
							<InputText
								type='number'
								value={editItem.val_order || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Campo */}
					<Col all={12}>
						<FormGroup
							name="field"
							label="Campo"
							validationState={validator.validationState.field}
						>
							<InputText
								value={editItem.field || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* x_field */}
					<Col all={6}>
						<FormGroup
							name="x_field"
							label="Campo x"
							validationState={validator.validationState.x_field}
						>
							<InputText
								value={editItem.x_field || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* y_field */}
					<Col all={6}>
						<FormGroup
							name="y_field"
							label="Campo y"
							validationState={validator.validationState.y_field}
						>
							<InputText
								value={editItem.y_field || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
