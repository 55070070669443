import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import Subscriptions from '../modules/Subscriptions'
import CertificationBodies from '../modules/CertificationBodies'
import EcmEmploymentTypes from '../modules/EcmEmploymentTypes'
import EcmProfessions from '../modules/EcmProfessions'
import EcmDisciplines from '../modules/EcmDisciplines'
import NotificationTypes from '../modules/NotificationTypes'

function App() {
	return <Routes>
		<Route path="subscriptions/*" element={<Subscriptions />} />
		<Route path="certification-bodies/*" element={<CertificationBodies />} />
		<Route path="ecm-employment-types/*" element={<EcmEmploymentTypes />} />
		<Route path="ecm-professions/*" element={<EcmProfessions />} />
		<Route path="ecm-disciplines/*" element={<EcmDisciplines />} />
		<Route path="notification-types/*" element={<NotificationTypes />} />

	</Routes>
}

export default App
