import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as CommunicationsTaxes } from '../modules/Communications'
import PhoneMessageModels from '../modules/PhoneMessageModels'

function App() {
	return <Routes>
		<Route path="communications/*" element={<CommunicationsTaxes />} />
		<Route path="phone-messages/*" element={<PhoneMessageModels />} />

	</Routes>
}

export default App
