import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import http from 'axios'
import { Card } from 'components/Ui'
import labels from 'core/labels'
import { setMessage } from 'reducers/ui/uiSlice'

// Components
import { Edit as EditTheme } from '../../Components/PrintReportsThemes'
function PageEdit() {
	let { id } = useParams()
	const dispatch = useDispatch()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [theme, setTheme] = useState({})

	const defaultFiltersValues = {
		env_theme_id: id,
	}

	useEffect(() => {
		handleLoad()
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	//* Load theme
	const handleLoad = async() => {
		const theme = await http.get(`${window.__endpoint}/cha/print_reports_themes/load?theme_id=${id}`)
		setTheme(theme.data)
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/environment/cha/print_reports_themes/load?theme_id=${id}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		await http.post(`${window.__endpoint}/environment/cha/print_reports_themes/save`, {
			...editItem,
			theme_id: id
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title={`Personalizza ${theme.name}`}>
		<EditTheme
			readOnly
			editItem={editItem}
			theme={theme}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
		/>
	</Card>
}

export default PageEdit
