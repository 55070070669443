import React from 'react'
import { ListGrid, ListToolbar, ListGridDraggable } from 'components/List'
import { useNavigate } from 'react-router-dom'
import http from 'axios'

function List({ items, onDelete, onList, filtersValues, refer_type_id, refer_id, ...props }) {
	const navigate = useNavigate()

	const copy = {
		onClick: async(id) => {
			await http.post(`${window.__endpoint}/cha/parameters/copy?parameter_id=${id}`)
			onList(filtersValues)
		},
		label: 'copia',
		icon: 'fa-solid fa-copy'
	}

	return <React.Fragment>
		<ListToolbar
			onShowFilters={() => {}}
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot='/parameters'
			customAdd={() => {
				navigate(`/cha/parameters/create?refer_type_id=${refer_type_id}&refer_id=${refer_id}`)
			}}
			hideBtnFilter
		/>
		<ListGridDraggable
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='parameter_id'
			columns={[
				{ field: 'parameter_id', header: 'Id', sortable: true },
				//{ field: 'val_order', header: '#', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'param_name', header: 'Parametro', sortable: true },
				{ field: 'parameter_type_name', header: 'Tipo parametro', sortable: true },
				{ field: 'default_value', header: 'Valore di default', sortable: true },
				{ field: 'active_flag', header: 'Attivo', type: 'boolean' },
				{ field: 'required_flag', header: 'Obbligatorio', type: 'boolean' },
				{ field: 'is_multi', header: 'Multiplo', type: 'boolean' },
			]}
			onEdit={(id) => {
				navigate(`/cha/parameters/edit/${id}`)
			}}
			customItems={[copy]}
		/>
	</React.Fragment>
}

export default List
