import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card, Alert } from 'components/Ui'

// Edit module
import { Edit as NotificationModelsEdit } from '../../Components/Concurrents'
import { List as ParametersList } from '../../../Parameters/Components/Parameters'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [parameters, setParameters] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		concurrent_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListParameters(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/cha/concurrents/load?concurrent_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/concurrents/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/concurrents/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei parametri
	const handleListParameters = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/parameters/list`, {
			params: {
				refer_type_id: 33, // concorrenti
				refer_id: id
			}
		})
		setParameters(list.data)
	}
	const handleDeleteParameters = async(id) => {
		await http.delete(`${window.__endpoint}/cha/parameters/delete?parameter_id=${id}`)
		handleListParameters(filtersValues)
	}
	const handleCreateParameters = async(data) => {
		await http.post(`${window.__endpoint}/cha/parameters/create`, {
			...data,
			refer_type_id: 33,
			refer_id: id
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListParameters(filtersValues)
	}

	const handleSortParameters = async(filters) => {
		await http.post(`${window.__endpoint}/cha/parameters/sort`, {
			topics: filters,
			refer_id: id,
			refer_type_id: 33
		})
	}
	const handleSetParameters = parameters => {
		setParameters(parameters)
	}

	return <>
		<Alert
			message="La gestione dei parametri all'interno dei programmi concorrenti segue la nomenclatura in_argument + X. Possono essere aggiunti fino a 20 parametri nel concorrente. I parametri saranno nominati quindi in_argument1 in_argument2 in_argument3 Il campo Procedura da eseguire non si riferisce alla procedura database da eseguire ma al nome del file contenente la logica del concorrente. va aggiunto .php come desinenza del nome"
			type="info"
		/>
		<br />
		<Card title={`${isNew ? 'Crea concorrente' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'concorrente'}`}`}>
			<NotificationModelsEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Parametri">
				<ParametersList
					refer_type_id={33}
					refer_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={parameters}
					handleSort={handleSortParameters}
					onList={handleListParameters}
					onDelete={handleDeleteParameters}
					onSubmit={handleCreateParameters}
					handleSetItems={handleSetParameters}
				/>
			</Card>
		</React.Fragment>}
	</>
}

export default PageEdit
