import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import { filter, forEach } from 'lodash'

function Edit({ moduleId, editItem, onMultipleChange, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
	}, [])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name','causal_code']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Codice causale */}
					<Col all={6}>
						<FormGroup
							name="causal_code"
							label="Codice causale"
							validationState={validator.validationState.causal_code}
						>
							<InputText
								value={editItem.causal_code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Tipologia documento */}
					<Col all={6}>
						<FormGroup
							name="influence_type_id"
							label="Influence type"
						>
							<InputText
								type='number'
								value={editItem.influence_type_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
