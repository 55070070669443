import { Dropdown as PrDropdown } from 'primereact/dropdown'
import labels from 'core/labels'

function Select({ all, options, optionLabel, optionValue, ...props }) {

	const { hideFilter } = props

	const allValue = all ? all.value || '' : ''
	const allLabel = all ? all.label || labels.select_value_not_selected : labels.select_value_not_selected
	const myOptions = [
		...[{ value: allValue, label: allLabel.toUpperCase() }],
		...options.map(item => {
			return {
				value: optionValue ? optionValue(item) : item.value,
				label: optionLabel ? optionLabel(item) : item.label,
			}
		})
	]

	return <PrDropdown
		options={myOptions}
		filter={!hideFilter}
		{...props}
	/>
}

export default Select
