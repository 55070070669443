import React from 'react'
import { Button } from 'components/Ui'

function InputGroup({ children, beforeAddOn, beforeButton, afterAddOn, afterButton, name, validationState }) {
	// Aggiungo all'elemento contenuto nel Formgroup lo stesso id e le classi full width e small
	const newElement = React.cloneElement(children, {
		id: name,
		name: name,
		className: `${validationState ? 'p-invalid' : ''}`,
	})

	return <div className="p-inputgroup flex-1">
		{beforeAddOn && <span className="p-inputgroup-addon">{beforeAddOn}</span>}
		{beforeButton && <Button icon={beforeButton.icon} severity={beforeButton.severity} raised={false} text={false} />}
		{newElement}
		{afterAddOn && <span className="p-inputgroup-addon">{afterAddOn}</span>}
		{afterButton && <Button icon={afterButton.icon} severity={afterButton.severity} raised={false} text={false} />}
	</div>
}

export default InputGroup
