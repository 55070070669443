import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * pages
import {
	PageList,
	PageEdit,
	PageEditScript,
	PageEditField,
	PageEditEnum,
	PageEditVariations
} from './Pages'

import { SearchContext } from 'core/contexts/SearchContext'

function Router() {
	const [contextSearch, setContextSearch] = React.useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageList />} />
			<Route path="create" element={<PageEdit />} />
			<Route path="edit/:id" element={<PageEdit />} />
			<Route path=":id/scripts/create" element={<PageEditScript />} />
			<Route path=":id/scripts/edit/:script_id" element={<PageEditScript />} />
			<Route path=":id/fields/create" element={<PageEditField />} />
			<Route path="fields/edit/:field_id" element={<PageEditField />} />
			<Route path="fields/:field_id/enums/create" element={<PageEditEnum/>} />
			<Route path="fields/enums/edit/:enum_id" element={<PageEditEnum />} />
			<Route path="fields/code-variations/create" element={<PageEditVariations/>} />
			<Route path="fields/code-variations/edit" element={<PageEditVariations />} />
		</Routes>
	</SearchContext.Provider>
}

export default Router
