import React, { useState } from 'react'
import { FiltersSidebar, ListGridDraggable, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	const inlineActions = [
		{
			label: 'Piano per periodo di prova',
			icon: 'pi pi-check',
			onClick: (plan_id) => {
				props.toggleDefault(plan_id)
			}
		},
	]

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGridDraggable
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='plan_id'
			columns={[
				{ field: 'plan_id', header: 'Id' },
				{ field: 'name', header: 'Nome' },
				{ field: 'description', header: 'Descrizione' },
				{ field: 'price_per_month', header: 'Pr / mese' },
				{ field: 'active_flag', header: 'Attivo', type: 'boolean', editable: false, sortable: true },
				{ field: 'highlight_flag', header: 'Evidenza', type: 'boolean', editable: false, sortable: true },
				{ field: 'trial_plan_flag', header: 'Piano periodo prova', type: 'boolean', editable: false, sortable: true },
				{ field: 'hide_flag', header: 'nascosto', type: 'boolean', editable: false, sortable: true },
			]}
			customItems={inlineActions}
			scrollHeight="calc(100vh - 23rem)"
		/>
	</React.Fragment>
}

export default List
