import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ role_id, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)
		const role_type = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${187}`)

		setSelectOptions({
			modules: modules.data,
			role_type: role_type.data
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['cha_role_id', 'name']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={6}>
						<FormGroup
							name="cha_role_id"
							label="Codice"
							validationState={validator.validationState.cha_role_id}
						>
							<InputText
								value={editItem.cha_role_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={6}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								value={editItem.description || ''}
								rows={5}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tipo di ruolo */}
					<Col all={4}>
						<FormGroup
							name="role_type_id"
							label="Tipo di ruolo"
						>
							<Select
								value={editItem.role_type_id || ''}
								onChange={onChange}
								options={selectOptions.role_type || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Modulo applicativo */}
					<Col all={4}>
						<FormGroup
							name="module_id"
							label="Modulo applicativo"
						>
							<Select
								value={editItem.module_id || ''}
								onChange={onChange}
								disabled={editItem.role_type_id == 1}
								options={selectOptions.modules || []}
								optionValue={(o) => o.module_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Livello utente massimo per la quale il ruolo può essere gestito */}
					<Col all={4}>
						<FormGroup
							name="max_user_level_auto_assign"
							label="Livello utente massimo per la quale il ruolo può essere gestito"
							validationState={validator.validationState.max_user_level_auto_assign}
						>
							<InputText
								type="number"
								value={editItem.max_user_level_auto_assign || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
