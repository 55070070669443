import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Subscriptions } from '../modules/Subscriptions'

function App() {
	return <Routes>
		<Route path="subscriptions/*" element={<Subscriptions />} />
	</Routes>
}

export default App
