import React, { useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ data, onSubmit }) {
	const [editItem, setEditItem] = useState(data || {})

	const onChange = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleSubmit = () => {
		onSubmit(editItem)
	}

	return <FormValidator
		onSubmit={handleSubmit}
		editItem={editItem}
		requiredFields={['value_perc', 'coefficient_taxable']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="value_perc"
						label="Percentuale dell'importo"
						validationState={validator.validationState.value_perc}
					>
						<InputText
							type="number"
							value={editItem.value_perc || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="coefficient_taxable"
						label="Valore cassa"
						validationState={validator.validationState.coefficient_taxable}
					>
						<InputText
							type="number"
							value={editItem.coefficient_taxable || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="start_date"
						label="Inizio validità"
					>
						<InputText
							type="date"
							value={editItem.start_date || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="end_date"
						label="Fine validità"
						validationState={validator.validationState.end_date}
					>
						<InputText
							type="date"
							value={editItem.end_date || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
