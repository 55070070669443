import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const regions = await http.get(`${window.__endpoint}/utl/regions/list?cols=region_id,name&order=name`)
		const provinces = await http.get(`${window.__endpoint}/utl/provinces/list?cols=province_id,name,region_id&order=name`)
		const countries = await http.get(`${window.__endpoint}/utl/countries/list?cols=country_id,name&order=name`)

		setSelectOptions({
			provinces: provinces.data,
			regions: regions.data,
			countries: countries.data,
		})
	}

	const handleFilterProvinces = (region_id) => {
		const provinces = selectOptions.provinces.filter(p => p.region_id == region_id)
		setSelectOptions({
			...selectOptions,
			filtered_provinces: provinces,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'region_id', 'province_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={4}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="region_id"
						label="Regione associata"
						validationState={validator.validationState.region_id}
					>
						<Select
							value={editItem.region_id || ''}
							onChange={(e) => {
								onChange(e)
								handleFilterProvinces(e.target.value)
							}}
							options={selectOptions.regions || []}
							optionValue={(o) => o.region_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="province_id"
						label="Provincia associata"
						validationState={validator.validationState.province_id}
					>
						<Select
							value={editItem.province_id || ''}
							onChange={onChange}
							options={selectOptions.filtered_provinces || (selectOptions.provinces || [])}
							optionValue={(o) => o.province_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="province_code"
						label="Codice provincia"
						validationState={validator.validationState.province_code}
					>
						<InputText
							value={editItem.province_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="country_id"
						label="Paese associato"
					>
						<Select
							value={editItem.country_id || ''}
							onChange={onChange}
							options={selectOptions.countries || []}
							optionValue={(o) => o.country_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="code_land"
						label="Codice zona"
						validationState={validator.validationState.code_land}
					>
						<InputText
							value={editItem.code_land || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="zip_code"
						label="Codice postale"
						validationState={validator.validationState.zip_code}
					>
						<InputText
							value={editItem.zip_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="active_flag"
						label="Attivo"
						validationState={validator.validationState.active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
