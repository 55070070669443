import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as TopicsList } from '../../Components/Topics'
import { SearchContext } from 'core/contexts/SearchContext'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/help/topics/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/help/topics/delete?topic_id=${id}`)
		handleList(filtersValues)
	}

	const handleSort = async(filters) => {
		await http.post(`${window.__endpoint}/help/topics/sort`, {
			topics: filters
		})
	}

	const handleSetItems = items => {
		setItems(items)
	}

	return <Card title="Aiuto in linea">
		<TopicsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			handleSort={handleSort}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
			handleSetItems={handleSetItems}
		/>
	</Card>
}

export default PageList
