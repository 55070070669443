import React from 'react'
import { Col, Checkbox, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit, states }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['enabled_refer_state_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="enabled_refer_state_id"
						label="Stato"
						validationState={validator.validationState.enabled_refer_state_id}
					>
						<Select
							value={editItem.enabled_refer_state_id || ''}
							onChange={onChange}
							options={states || []}
							optionValue={(o) => o.refer_state_id}
							optionLabel={(o) => o.name}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
