import { TreeSelect } from 'primereact/treeselect'
import labels from 'core/labels'

function Select({ all, onChange, options, optionLabel, optionValue, value, ...props }) {
	const allValue = all ? all.value || 'null' : 'null'
	const allLabel = all ? all.label || labels.select_value_not_selected : labels.select_value_not_selected

	const extractOptions = (options) => {
		return options.map(item => ({
			key: optionValue ? optionValue(item.data) : item.data.value,
			data: optionValue ? optionValue(item.data) : item.data.value,
			label: optionValue ? optionLabel(item.data) : item.data.label,
			children: extractOptions(item.children)
		}))
	}

	const myOptions = [
		{
			key: allValue,
			label: allLabel.toUpperCase(),
			data: allValue,
			children: extractOptions(options) }
	]

	const handleChange = (e) => {
		onChange({
			target: {
				name: e.target.name,
				value: e.target.value == 'null' ? '' : e.target.value,
			}
		})
	}

	return <TreeSelect
		options={myOptions}
		value={value == '' ? 'null' : value}
		onChange={handleChange}
		{...props}
	/>
}

export default Select
