import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { confirmDialog } from 'primereact/confirmdialog'
import { Button, SplitButton } from 'components/Ui'

function ListGridDraggable({ columns, customItems, fieldId, items, onDelete, editRoot, onEdit, scrollHeight, editInline, ...props }) {
	const navigate = useNavigate()

	const actionsTemplate = (value) => {
		let items = []
		if (onDelete) {
			items = [
				...items,
				{
					label: 'elimina',
					icon: 'pi pi-trash',
					command: () => {
						confirmDialog({
							message: 'Procedere con l\'eliminazione?',
							header: 'Logout utente',
							icon: 'pi pi-exclamation-triangle',
							accept: () => onDelete(value[fieldId]),
							reject: () => { /* Do nothing */}
						})
					}
				},
			]
		}

		if (customItems) {
			let actions = []
			actions = customItems.map(action => {
				return {
					label: action.label,
					icon: action.icon,
					// Ciclo e vado a aggiungere l'ID sull'action
					command: () => action.onClick(value[fieldId])
				}
			})
			items = [
				...items,
				...actions
			]
		}

		if (items.length > 0) {
			return <SplitButton
				className="w-full"
				label="modifica"
				onClick={() => {
					if (onEdit) {
						onEdit(value[fieldId])
					}
					if (!editInline && !onEdit)
						navigate(`./${!editRoot ? '' : `${editRoot}/`}edit/${value[fieldId]}`)
				}}
				model={items}
			/>
		} else {
			return <Button
				className="w-full"
				label="modifica"
				onClick={() => {
					if (!editInline)
						navigate(`./${!editRoot ? '' : `${editRoot}/`}edit/${value[fieldId]}`)
				}}
			/>
		}
	}

	const setProducts = (items) => {
		props.handleSetItems(items)
		if (Array.isArray(items)) {
			const ordered_items = items.map(item => { return {
				[fieldId]: item[fieldId],
				val_order: item.val_order
			}})
			props.handleSort(ordered_items)
		}
	}

	const deleteActionTemplate = (value) => {
		return <Button className="w-full" label="" icon='pi pi-trash' onClick={() => onDelete(value[fieldId])} />
	}

	const onRowEditComplete = (e) => {
		let _products = [...items]
		let { newData, index } = e

		_products[index] = newData

		props.onUpdate(newData)
	}

	const verifiedBodyTemplate = (rowData, column) => {
		const { field } = column
		return <i className={`pi ${rowData[field] == 1 ? 'pi-check-circle' : 'pi-times-circle'} ${rowData[field] == 1 ? 'text-green-500' : 'text-red-500'}`}></i>
	}

	return <div style={{ width: '100%' }}>
		<DataTable
			reorderableRows
			onRowReorder={(e) => setProducts(e.value)}
			size='small'
			value={items}
			sortMode="multiple"
			reorderableColumns
			scrollable
			scrollHeight={scrollHeight || 'calc(100vh - 21rem)'}
			onRowEditComplete={editInline && onRowEditComplete}
		>
			<Column rowReorder style={{ width: '3rem' }} />
			{columns.map((column, c) => <Column
				key={c}
				field={column.field}
				body={editInline ? null : (column.type != 'boolean' ? column.body : (row) => verifiedBodyTemplate(row, column))}

				header={column.header}
				sortable={column.sortable}
				rowEditor={editInline}
			/>)}
			<Column
				body={actionsTemplate}
				header=""
				style={{ width: '25px' }}
				rowEditor={editInline}
			></Column>
		</DataTable>
	</div>
}

export default ListGridDraggable
