import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='document_transaction_causal_id'
			columns={[
				{ field: 'document_transaction_causal_id', header: 'Id', sortable: true },
				{ field: 'document_name', header: 'Documento', sortable: true },
				{ field: 'document_causal', header: 'Causale del documento', sortable: true },
				{ field: 'transaction', header: 'Causale di magazzino', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
