import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onMultipleChange, onSubmit, refer_type_id }) {

	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const referement_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name&list_id=${48}`)

		setSelectOptions({
			referement_types: referement_types.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={6}>
					<FormGroup
						name="refer_type_id"
						label="Riferimento"
						validationState={validator.validationState.refer_type_id}
					>
						<Select
							disabled
							value={refer_type_id || editItem.refer_type_id || ''}
							onChange={onChange}
							options={selectOptions.referement_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="refer_field"
						label="Campo di riferimento"
						validationState={validator.validationState.refer_field}
					>
						<InputText
							value={editItem.refer_field || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="header"
						label="Intestazione"
						validationState={validator.validationState.header}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.header == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="value_refer_field"
						label="value_refer_field"
						validationState={validator.validationState.value_refer_field}
					>
						<InputText
							value={editItem.value_refer_field || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="db_field"
						label="db_field"
						validationState={validator.validationState.db_field}
					>
						<InputText
							value={editItem.db_field || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="value_refer_type_id"
						label="value_refer_type_id"
						validationState={validator.validationState.value_refer_type_id}
					>
						<Select
							value={editItem.value_refer_type_id || ''}
							onChange={onChange}
							options={selectOptions.referement_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="db_field_refer_type_id"
						label="Riferimento"
						validationState={validator.validationState.db_field_refer_type_id}
					>
						<Select
							value={editItem.db_field_refer_type_id || ''}
							onChange={onChange}
							options={selectOptions.referement_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="operator"
						label="operator"
					>
						<InputTextarea
							value={editItem.operator || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="combo_flag"
						label="combo_flag"
						validationState={validator.validationState.combo_flag}
					>
						<Checkbox
							onChange={onMultipleChange}
							checked={editItem.combo_flag == 1}
						/>
					</FormGroup>
				</Col>
				{editItem.combo_flag == 1 && <>
					<Col all={12}>
						<FormGroup
							name="combo_query"
							label="combo_query"
						>
							<InputTextarea
								value={editItem.combo_query || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12}>
						<FormGroup
							name="combo_table"
							label="combo_table"
							validationState={validator.validationState.combo_table}
						>
							<InputText
								value={editItem.combo_table || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12}>
						<FormGroup
							name="combo_field_id"
							label="combo_field_id"
							validationState={validator.validationState.combo_field_id}
						>
							<InputText
								value={editItem.combo_field_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</>}
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
