import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import { Modal } from 'components/Ui'
import http from 'axios'
import { Edit } from '..'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, driver_id, onSubmit, setIsNewGroup, ...props }) {
	const [modalOpen, setModalOpen] = useState(false)
	const [editItem, setEditItem] = useState({})

	const handleEdit = async(id) => {
		setIsNewGroup(false)
		const response = await http.get(`${window.__endpoint}/int/drivers/fields_groups/load?cols=name,driver_fields_group_id&driver_fields_group_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}
	return <React.Fragment>

		<ListToolbar
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>
		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
				setIsNewGroup(true)
			}}
			label='Nuovo valore'
		>
			<Edit
				{...props}
				data={editItem}
				onSubmit={onSubmit}
			/>
		</Modal>
		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='driver_fields_group_id'
			columns={[
				{ field: 'driver_fields_group_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
