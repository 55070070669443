import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Shops } from '../modules/Shops'
import { default as PaymentMethods } from '../modules/PaymentMethods'
import { default as ShippingMethods } from '../modules/ShippingMethods'
import { default as Blogs } from '../modules/Blogs'

function App() {
	return <Routes>
		<Route path="shops/*" element={<Shops />} />
		<Route path="payment-methods/*" element={<PaymentMethods />} />
		<Route path="shipping-methods/*" element={<ShippingMethods />} />
		<Route path="blogs/*" element={<Blogs />} />
	</Routes>
}

export default App
