import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox, Select, HTMLEditor, InputPassword } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const list_lines = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,list_value_code,name&list_id=25`)

		setSelectOptions({
			list_lines: list_lines.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'mail']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={6}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* mail */}
					<Col all={6}>
						<FormGroup
							name="mail"
							label="Email"
							validationState={validator.validationState.mail}
						>
							<InputText
								value={editItem.mail || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Username */}
					<Col all={6}>
						<FormGroup
							name="user_name"
							label="Username"
							validationState={validator.validationState.user_name}
						>
							<InputText
								value={editItem.user_name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Email */}
					<Col all={6}>
						<FormGroup
							name="password"
							label="Password"
							validationState={validator.validationState.password}
						>
							{/*
								// TODO
								// Password input component
							*/}
							<InputPassword
								value={editItem.password || ''}
								onChange={onChange}
								placeholder="***********"
								toggleMask
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Username */}
					<Col all={4}>
						<FormGroup
							name="smtp_bool_auth"
							label="Richiede connessione sicura"
							validationState={validator.validationState.smtp_bool_auth}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.smtp_bool_auth == 1}
							/>
						</FormGroup>
					</Col>
					{/* Sicurezza della connessione */}
					<Col all={4}>
						<FormGroup
							name="smtp_secure"
							label="Sicurezza della connessione"
						>
							<Select
								value={editItem.smtp_secure || ''}
								onChange={onChange}
								options={selectOptions.list_lines || []}
								optionValue={(o) => o.list_value_code}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Email */}
					<Col all={4}>
						<FormGroup
							name="smtp_port"
							label="Porta"
							validationState={validator.validationState.smtp_port}
						>
							<InputText
								type="number"
								value={editItem.smtp_port || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Firma */}
					<Col all={12}>
						<FormGroup
							name="signature"
							label="Firma"
						>
							<HTMLEditor
								value={editItem.signature || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
