import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const professions = await http.get(`${window.__endpoint}/eln/ecm_professions/list?cols=profession,profession_id`)

		setSelectOptions({
			professions: professions.data
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['discipline', 'profession_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Tipo dell'abbonamento */}
					<Col all={12}>
						<FormGroup
							name="discipline"
							label="Nome"
							validationState={validator.validationState.discipline}
						>
							<InputText
								value={editItem.discipline || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* professione */}
					<Col all={12}>
						<FormGroup
							name="profession_id"
							label="Professione"
							validationState={validator.validationState.profession_id}
						>
							<Select
								value={editItem.profession_id || ''}
								onChange={onChange}
								options={selectOptions.professions || []}
								optionValue={(o) => o.profession_id}
								optionLabel={(o) => o.profession}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
