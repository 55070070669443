import React, { useEffect, useState } from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Edit } from '..'
import { Modal } from 'components/Ui'
import http from 'axios'

function List({ items, onDelete, onList, filtersValues, onSubmit, ...props }) {
	const [modalOpen, setModalOpen] = React.useState(false)
	const [editItem, setEditItem] = React.useState({})

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/cha/profiles/lines/load?profile_line_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}

	return <React.Fragment>
		<ListToolbar
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
			}}
			label={editItem.profile_line_id > 0 ? `Modifica ${editItem.name || 'valore'}` : 'Nuovo valore'}
		>
			<Edit
				{...props}
				onSubmit={onSubmit}
				data={editItem}
			/>
		</Modal>

		<ListGrid
			onDelete={onDelete}
			onUpdate={onSubmit}
			items={items}
			fieldId='profile_line_id'
			columns={[
				{ field: 'profile_line_id', header: 'Id' },
				{ field: 'line_value', header: 'Valore' },
				{ field: 'name', header: 'Nome' },
				{ field: 'description', header: 'Descrizione' },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
