import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Countries } from '../modules/Countries'
import { default as CountryZones } from '../modules/CountryZones'
import { default as Regions } from '../modules/Regions'
import { default as Provinces } from '../modules/Provinces'
import { default as Cities } from '../modules/Cities'
import { default as TerritoriesUpdate } from '../modules/TerritoriesUpdate'
import { default as UmGroups } from '../modules/UmGroups'
import { default as Um } from '../modules/Um'

function App() {
	return <Routes>
		<Route path="countries/*" element={<Countries />} />
		<Route path="country-zones/*" element={<CountryZones />} />
		<Route path="regions/*" element={<Regions />} />
		<Route path="provinces/*" element={<Provinces />} />
		<Route path="cities/*" element={<Cities />} />
		<Route path="territories-update/*" element={<TerritoriesUpdate />} />
		<Route path="um-groups/*" element={<UmGroups />} />
		<Route path="um/*" element={<Um />} />
	</Routes>
}

export default App
