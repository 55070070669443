import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const concurrent_types = await http.get(`${window.__endpoint}/cha/list_lines/list?cols=list_value_id,name&list_id=${125}`)
		const applications = await http.get(`${window.__endpoint}/cha/applications/list?cols=application_id,description&order=description`)
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)

		setSelectOptions({
			concurrent_types: concurrent_types.data,
			applications: applications.data,
			modules: modules.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'concurrent_type_id', 'application_id', 'module_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Attivo di default (report standard) */}
					<Col all={6}>
						<FormGroup
							name="default_active_flag"
							label="Attivo di default su ogni ambiente per ogni organizzazione"
							validationState={validator.validationState.default_active_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.default_active_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tipo di concorrente */}
					<Col all={4}>
						<FormGroup
							name="concurrent_type_id"
							label="Tipo di concorrente"
							validationState={validator.validationState.concurrent_type_id}
						>
							<Select
								value={editItem.concurrent_type_id || ''}
								onChange={onChange}
								options={selectOptions.concurrent_types || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Nome */}
					<Col all={8}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Applicazione */}
					<Col all={4}>
						<FormGroup
							name="application_id"
							label="Applicazione"
							validationState={validator.validationState.application_id}
						>
							<Select
								value={editItem.application_id || ''}
								onChange={onChange}
								options={selectOptions.applications || []}
								optionValue={(o) => o.application_id}
								optionLabel={(o) => o.description}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Modulo */}
					<Col all={4}>
						<FormGroup
							name="module_id"
							label="Modulo"
							validationState={validator.validationState.module_id}
						>
							<Select
								value={editItem.module_id || ''}
								onChange={onChange}
								options={selectOptions.modules || []}
								optionValue={(o) => o.module_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Procedura da eseguire */}
					<Col all={4}>
						<FormGroup
							name="procedure_executable"
							label="Procedura da eseguire"
							validationState={validator.validationState.procedure_executable}
						>
							<InputText
								value={editItem.procedure_executable || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>

				<Row>
					{/* Tabella dell'interfaccia di importazione */}
					<Col all={4}>
						<FormGroup
							name="import_interface_table"
							label="Tabella dell'interfaccia di importazione"
							validationState={validator.validationState.import_interface_table}
						>
							<InputText
								value={editItem.import_interface_table || ''}
								onChange={onChange}
								disable={editItem.concurrent_type_id != 4}
							/>
						</FormGroup>
					</Col>
					{/* Campi dell'interfaccia da caricare nel report */}
					<Col all={4}>
						<FormGroup
							name="import_interface_fields"
							label="Campi dell'interfaccia da caricare nel report"
							validationState={validator.validationState.import_interface_fields}
						>
							<InputText
								value={editItem.import_interface_fields || ''}
								onChange={onChange}
								disable={editItem.concurrent_type_id != 4}
							/>
						</FormGroup>
					</Col>
				</Row>

				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								type="text"
								row={6}
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
