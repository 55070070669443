import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
				{
					type: 'select',
					label: 'Tipologia',
					name: 'type_id',
					options: [
						{ value: 1, label: 'Web' },
						{ value: 2, label: 'B.M.' },
					]
				}
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='area_id'
			columns={[
				{ field: 'area_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Area', sortable: true },
				{ field: 'type_id', header: 'Tipo', sortable: false, body: ({ type_id }) => {
					switch (Number(type_id)) {
						case 1: return <Tag severity="info" value="Web" />
						case 2: return <Tag severity="success" value="B.M" />
						default: return ''
					}
				} },
				{ field: 'area_order', header: 'Ordine', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
