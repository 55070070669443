import { Password as PrPassword } from 'primereact/password'

function InputPassword({ ...props }) {
	return <div className="p-inputgroup flex-1">
		<PrPassword
			{...props}
		/>
	</div>
}

export default InputPassword
