import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, Button } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onUpdate, onList, filtersValues, list_id, ...props }) {
	const { onSubmit } = props
	const [modules, setModules] = React.useState([])

	const [selectedModule, setSelectedModule] = React.useState()

	const handleLoadOptions = async() =>{
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name,description&order=name`)

		setModules(modules.data)
	}

	React.useEffect(() => {
		handleLoadOptions()
	}, [])

	const handleSubmit = () =>{
		onSubmit({
			module_object_id: -1,
			module_id: selectedModule,
		})
		setSelectedModule(null)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>
		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedModule || ''}
					onChange={(e) => setSelectedModule(e.target.value)}
					options={modules || []}
					optionValue={(o) => o.module_id}
					optionLabel={(o) => o.name}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Button onClick={handleSubmit} disabled={! selectedModule}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='module_object_id'
			columns={[
				{ field: 'module_object_id', header: 'Id', sortable: true },
				{ field: 'module', header: 'Nome', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
