import React from 'react'
import { Button } from 'components/Ui'
import { useNavigate } from 'react-router-dom'

function ListToolbar({ editRoot, hideBtnAdd, hideBtnFilter, onReload, onShowFilters, ...props }) {
	const { columns, customAdd, customActions } = props
	const navigate = useNavigate()

	return <div className='buttons-group' style={{ marginBottom: 8 }}>
		{!hideBtnFilter && <Button tooltip="Filtra" icon="pi pi-filter" onClick={onShowFilters} />}
		<Button tooltip="Aggiorna" icon="pi pi-refresh" onClick={onReload} />
		{!hideBtnAdd && <Button tooltip="Aggiungi" icon="pi pi-plus" onClick={() => {
			if (customAdd) {
				customAdd()
			} else {
				navigate(`./${!editRoot ? '' : `${editRoot}/`}create`)
			}
		}} />}
		{customActions && customActions.map((action, index) => <Button key={index} tooltip={action.tooltip} icon={action.icon} onClick={action.action} disabled={action.disabled} />)}
	</div>
}

export default ListToolbar
