import React, { useContext, useState, useEffect, useMemo } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as ListTranslations } from '../../Components/Translations'
import { SearchContext } from 'core/contexts/SearchContext'
import { useLocation } from 'react-router-dom'

function PageList() {
	const [items, setItems] = useState([])
	const query = useQuery()
	const [filtersValues, setFiltersValues] = useState({})
	const { contextSearch } = useContext(SearchContext)
	const defaultFiltersValues = {}
	let refer_id = query.get('id')
	let refer_type_id = query.get('refer_type_id')
	const [item, setItem] = useState([])

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
		loadItem()
	}, [])

	const loadItem = async() => {
		const curItem = await http.get(`${window.__endpoint}/cha/referement_types/get/load`, {
			params: { refer_type_id: refer_type_id, refer_id: refer_id }
		})
		setItem(curItem.data)
	}

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/referement_type_texts_translations/list`, {
			params: { ...filters, refer_type_id: refer_type_id, refer_id: refer_id }
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/cha/referement_types/delete?refer_type_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title={`Traduzioni ${item.name || ''}`}>
		<ListTranslations
			refer_id={refer_id}
			refer_type_id={refer_type_id}
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
		/>
	</Card>
}

function useQuery() {
	const { search } = useLocation()

	return useMemo(() => new URLSearchParams(search), [search])
}

export default PageList
