import React, { useState, useEffect } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select, HTMLEditor, Button } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	const [modalOpen, setModalOpen] = useState(false)

	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const referement_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name&order=name`)

		setSelectOptions({
			referement_types: referement_types.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'refer_type_id', 'object', 'message']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				{/* Attivo di default (report standard) */}
				<Col all={6}>
					<FormGroup
						name="default_active_flag"
						label="Attivo di default su ogni ambiente per ogni organizzazione"
						validationState={validator.validationState.default_active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.default_active_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="user_visibility_flag"
						label="Visibile sulle impostazioni"
						validationState={validator.validationState.user_visibility_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_visibility_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Nome */}
				<Col all={6}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				{/* Referment types */}
				<Col all={6}>
					<FormGroup
						name="refer_type_id"
						label="Referment type"
						validationState={validator.validationState.refer_type_id}
					>
						<Select
							value={editItem.refer_type_id || ''}
							onChange={onChange}
							options={selectOptions.referement_types || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputTextarea
							type="text"
							row={6}
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="object"
						label="Oggetto"
						validationState={validator.validationState.object}
					>
						<InputText
							value={editItem.object || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="message"
						label="Messaggio"
						validationState={validator.validationState.message}
					>
						<HTMLEditor
							value={editItem.message || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				{/* Targets query */}
				<Col all={12}>
					<FormGroup
						name="target_query"
						label="Targets query"
						validationState={validator.validationState.target_query}
					>
						<InputTextarea
							type="text"
							row={6}
							value={editItem.target_query || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Attachments query */}
				<Col all={12}>
					<FormGroup
						name="attachment_query"
						label="Attachments query"
						validationState={validator.validationState.attachment_query}
					>
						<InputTextarea
							type="text"
							row={6}
							value={editItem.attachment_query || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={4}>
					<FormGroup
						name="send_link_files_attachs_flag"
						label="Invia allegati come link"
						validationState={validator.validationState.send_link_files_attachs_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.send_link_files_attachs_flag == 1}
						/>
					</FormGroup>
				</Col>
				{/* Procedura di callback */}
				<Col all={4}>
					<FormGroup
						name="callback_procedure"
						label="Procedura di callback"
						validationState={validator.validationState.callback_procedure}
					>
						<InputText
							value={editItem.callback_procedure || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				{/* Script automazione */}
				<Col all={4}>
					<FormGroup
						name="automation_script"
						label="Script automazione"
						validationState={validator.validationState.automation_script}
					>
						<InputText
							value={editItem.automation_script || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
