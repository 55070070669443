import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as TargetEdit } from '../../Components/NotificationsModelTargets'

function PageEditTarget() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { notificationModelId, id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const target = await http.get(`${window.__endpoint}/cha/notificationModels/targets/load?notification_model_target_id=${id}`)
			setEditItem(target.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			// Aggiungo l'id dell'area ricevuta come prop
			editItem.notification_model_id = notificationModelId
			const id = await http.post(`${window.__endpoint}/cha/notificationModels/targets/create`, editItem)
			navigate(`./../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/notificationModels/targets/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title={`${isNew ? 'Crea destinatario' : 'Modifica destinatario'}`}>
		<TargetEdit
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
			notification_model_id={notificationModelId}
		/>
	</Card>
}

export default PageEditTarget
