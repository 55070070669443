import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import TableDetails from '../TableDetails/TableDetails'

function Data({ defaultFiltersValues, items, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [tableDetails, setTableDetails] = useState({
		open: false,
		table: {}
	})

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
		/>

		<ListGrid
			items={items}
			fieldId='name'
			columns={[
				{ field: 'name', header: 'Tabella', sortable: true },
			]}
			scrollHeight="calc(100vh - 23rem)"
			hideBtnEdit
			customItems={[
				{
					label: 'Dettaglio',
					onClick: (tableName) => {
						setTableDetails({
							open: true,
							table: items.find(item => item.name == tableName)
						})
					}
				},
			]}
		/>

		<TableDetails
			{...tableDetails}
			onClose={() => setTableDetails({ open: false, table: {} })}
		/>
	</React.Fragment>
}

export default Data
