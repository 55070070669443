import React from 'react'
import { useSelector } from 'react-redux'

const AppFooter = () => {
	const { layoutConfig } = useSelector(state => state.ui)

	return (
		<div className="layout-footer">
			<img src={`/layout/images/logo-${layoutConfig.colorScheme === 'light' ? 'dark' : 'white'}.svg`} alt="Logo" height="20" className="mr-2" />
			by
			<span className="font-medium ml-2">Partita Tripla</span>
		</div>
	)
}

export default AppFooter
