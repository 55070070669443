import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * pages
import { PageList, PageEdit, PageEditTarget } from './Pages'

import { SearchContext } from 'core/contexts/SearchContext'

function Router() {
	const [contextSearch, setContextSearch] = React.useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageList />} />
			<Route path="create" element={<PageEdit />} />
			<Route path="edit/:id" element={<PageEdit />} />
			<Route path="edit/:notificationModelId/targets/create" element={<PageEditTarget />} />
			<Route path="edit/:notificationModelId/targets/edit/:id" element={<PageEditTarget />} />
		</Routes>
	</SearchContext.Provider>
}

export default Router
