import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as StatesEdit } from '../../Components/States'
import { List as EnabledList } from '../../Components/StatesEnabled'
import { act } from 'react'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	let { id, referTypeId } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({
		active_flag: 1,
	})

	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	let defaultFiltersValues = {}

	useEffect(() => {
		const load = () => {
			handleLoad().then(() => {
				handleListStatesEnabled(defaultFiltersValues)
			}).catch((error) => {
				dispatch(setMessage({ message: { severity: 'error', summary: labels.error, detail: error.message } }))
			})
		}
		load()
	}, [id])
	useEffect(() => {
		setEditItem({
			refer_type_id: referTypeId,
			active_flag: 1,
		})
	}, [referTypeId])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		let state = {}
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/cha/states/load?state_id=${id}`)
			state = area.data
			setEditItem(area.data)
		}

		defaultFiltersValues = {
			refer_type_id: state.refer_type_id ? state.refer_type_id : referTypeId,
			state_id: id,
		}
	}

	const handleSave = async() => {
		// Esiste solo l'update
		await http.put(`${window.__endpoint}/cha/states/update`, editItem)
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/states/create`, editItem)
			navigate(`./../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/states/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleListStatesEnabled = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/states/enabled/list`, {
			params: {
				...filters,
			}
		})
		setItems(list.data)
	}

	const handleSaveStatesEnabled = async(data) => {
		if (data.enable_state_id > 0) {
			await http.put(`${window.__endpoint}/cha/states/enabled/update`, data)
		} else {
			await http.post(`${window.__endpoint}/cha/states/enabled/create`, {
				...data,
				refer_state_id: editItem.refer_state_id,
				refer_type_id: editItem.refer_type_id,
			})
		}

		handleListStatesEnabled(filtersValues)
	}

	const handleDeleteStatesEnabled = async(_id) => {
		await http.delete(`${window.__endpoint}/cha/states/enabled/delete?enable_state_id=${_id}`)
		handleListStatesEnabled(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea stato' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'stato'}`}`}>
			<StatesEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
				referTypeId={referTypeId}
			/>
		</Card>
		{(!isNew && editItem) && <>
			<br />
			<Card title="Elenco stati abilitati">
				<EnabledList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={items}
					headerItem={editItem}
					onList={handleListStatesEnabled}
					onDelete={handleDeleteStatesEnabled}
					onChange={handleChangeEditItem}
					onSubmit={handleSaveStatesEnabled}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
