import React, { useState, useEffect } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues,refer_type_id }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [referements, setReferements] = useState([])

	const handleListReferements = async() => {
		const list = await http.get(`${window.__endpoint}/cha/referement_types/list?order=name`)

		const referements = list.data.map(item => ({
			value: item.refer_type_id,
			label: item.name
		}))

		setReferements(referements)
	}

	useEffect(() => {
		handleListReferements()
	}, [])

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
				{
					type: 'select',
					label: 'Riferimento',
					name: 'refer_type_id',
					options: referements
				}
			]}
			filterSelect
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot = {refer_type_id ? 'states' : null}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='state_id'
			editRoot = {refer_type_id ? 'states' : null}
			columns={[
				{ field: 'state_id', header: 'Id', sortable: true },
				{ field: 'refer_state_id', header: 'Valore', sortable: true },
				{ field: 'name', header: 'Stato', sortable: true },
				{ field: 'refer_type', header: 'Riferimento', sortable: true },
				{ field: 'active_flag', header: 'Stato', sortable: false, body: ({ active_flag }) => {
					switch (Number(active_flag)) {
						case 1: return <Tag severity="success" value="Si" />
						case 0: return <Tag severity="danger" value="No" />
						default: return ''
					}
				} },
			]}
		/>
	</React.Fragment>
}

export default List
