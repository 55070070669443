import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as ShippingMethodsEdit } from '../../Components/ShippingMethods'
import { List as ShippingPaymentMethodsList } from '../../Components/Payments'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [shippingPaymentMethods, setShippingPaymentMethods] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		shipping_method_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListShippingPaymentMethods(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/ecom/shipping_methods/load?shipping_method_id=${id}&order=shipping_payment_id`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/ecom/shipping_methods/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/ecom/shipping_methods/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei metodi di pagamento
	const handleListShippingPaymentMethods = async(filtersValues) => {
		setFiltersValues(filtersValues)
		const shippingPaymentMethods = await http.get(`${window.__endpoint}/ecom/shipping_methods/payments/list`, { params: filtersValues })
		setShippingPaymentMethods(shippingPaymentMethods.data)
	}
	const handleCreateShippingPaymentMethods = async(data) => {
		await http.post(`${window.__endpoint}/ecom/shipping_methods/payments/create`, {
			shipping_method_id: id, // ID Lista valore
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListShippingPaymentMethods(filtersValues)
	}
	const handleDeleteShippingPaymentMethods = async(id) => {
		await http.delete(`${window.__endpoint}/ecom/shipping_methods/payments/delete?shipping_payment_id=${id}`)
		handleListShippingPaymentMethods(filtersValues)
	}
	return <>
		<Card title={`${isNew ? 'Crea metodo di spedizione' : `Modifica ${editItem && editItem.shipping_method != '' ? editItem.shipping_method : 'metodo di spedizione'}`}`}>
			<ShippingMethodsEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Gestisci metodo di pagamento per la spedizione">
				<ShippingPaymentMethodsList
					shipping_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={shippingPaymentMethods}
					onList={handleListShippingPaymentMethods}
					onDelete={handleDeleteShippingPaymentMethods}
					onSubmit={handleCreateShippingPaymentMethods}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
