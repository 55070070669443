import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as ImageTypes } from '../modules/ImageTypes'
import { default as FileTypes } from '../modules/FileTypes'

function App() {
	return <Routes>
		<Route path="image-types/*" element={<ImageTypes />} />
		<Route path="file-types/*" element={<FileTypes />} />
	</Routes>
}

export default App
