import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='mail_account_id'
			columns={[
				{ field: 'mail_account_id', header: 'Id' },
				{ field: 'name', header: 'Metodo di spedizione' },
				{ field: 'mail', header: 'Mail' },
				{ field: 'main_flag', header: 'Principale', type: 'boolean', editable: false, sortable: true, body: ({ main_flag }) => {
					switch (Number(main_flag)) {
						case 0: return <Tag severity="danger" value="No" />
						case 1: return <Tag severity="success" value="Si" />
						default: return ''
					}
				} },
			]}
			envCustomizeButton
			envEnableButtons={{
				onEnable: onEnvEnable,
				onDisable: onEnvDisable,
			}}
			scrollHeight="calc(100vh - 23rem)"
		/>
	</React.Fragment>
}

export default List
