import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { HeaderLinesSeparator } from 'components/Page'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { Edit as TopicEdit } from '../../Components/Topics'
import { List as TopicModulesList } from '../../Components/TopicModules'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const topic = await http.get(`${window.__endpoint}/help/topics/load?topic_id=${id}`)
			setEditItem(topic.data)

			handleListModules()
		}
	}

	const handleListModules = async() => {
		const list = await http.get(`${window.__endpoint}/help/topic_modules/list?topic_id=${id}`)
		setItems(list.data)
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/help/topics/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/help/topics/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleCreateTopicModule = async(data) => {
		await http.post(`${window.__endpoint}/help/topic_modules/create`, {
			topic_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModules()
	}

	const handleDeleteTopicModule = async(id) => {
		await http.delete(`${window.__endpoint}/help/topic_modules/delete?topic_module_id=${id}`)
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModules()
	}

	return <>
		<Card title={`${isNew ? 'Crea argomento aiuto in linea' : `Modifica ${editItem && editItem.title != '' ? editItem.title : 'argomento aiuto in linea'}`}`}>
			<TopicEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Moduli aggiuntivi collegati" style={{ marginTop: 15 }}>
				<TopicModulesList
					topic_id={id}
					editItem={editItem}
					onDelete={handleDeleteTopicModule}
					onSave={handleCreateTopicModule}
					filtersValues={filtersValues}
					items={items}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
