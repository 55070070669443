import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as WithHoldingTaxes } from '../modules/WithHoldingTaxes'
import { default as Taxes } from '../modules/Taxes'
import { default as SocialSecurityTaxes } from '../modules/SocialSecurityTaxes'
import InvoiceTypes from '../modules/InvoiceTypes'
import PayableInvoiceTypes from '../modules/PayableInvoiceTypes'

function App() {
	return <Routes>
		<Route path="with-holding-taxes/*" element={<WithHoldingTaxes />} />
		<Route path="social-security-taxes/*" element={<SocialSecurityTaxes />} />
		<Route path="taxes/*" element={<Taxes />} />
		<Route path="invoice-types/*" element={<InvoiceTypes />} />
		<Route path="payable-invoice-types/*" element={<PayableInvoiceTypes />} />

	</Routes>
}

export default App
