import React from 'react'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, ColorPicker } from 'components/Ui'

function Edit({ editItem, theme, onChange, onSubmit }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		//requiredFields={[]}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					<Col all={6} >
						<Row>
							<Col all={12}>
								<h5>Valori personalizzati</h5>
							</Col>
							<Col all={12}>
								<FormGroup
									name="color_light"
									label="Colore chiaro"
								>
									<ColorPicker
										value={editItem.color_light || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="color_main"
									label="Colore principale"
								>
									<ColorPicker
										value={editItem.color_main || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="color_dark"
									label="Colore scuro"
								>
									<ColorPicker
										value={editItem.color_dark  || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="text"
									label="Colore testo"
								>
									<ColorPicker
										value={editItem.text || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="text_light"
									label="Colore testo chiaro"
								>
									<ColorPicker
										value={editItem.text_light || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="text_over_main"
									label="Testo sul colore principale"
								>
									<ColorPicker
										value={editItem.text_over_main || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="text_over_dark"
									label="Testo sul colore scuro"
								>
									<ColorPicker
										value={editItem.text_over_dark || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="table_header_bg"
									label="Sfondo intestazione tabella"
								>
									<ColorPicker
										value={editItem.table_header_bg || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name="table_border"
									label="Colore bordo tabela"
								>
									<ColorPicker
										value={editItem.table_border || ''}
										onChange={(e) => onChange(e)}
									/>
								</FormGroup>
							</Col>
						</Row>
					</Col>
					<Col all={6}>
						<Row>
							<Col all={12}>
								<h5>Valori di default</h5>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Colore chiaro"
								>
									<ColorPicker
										disabled
										value={ theme.color_light || ''}
										onChange={()=>{}}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Colore principale"
								>
									<ColorPicker
										disabled
										value={ theme.color_main || ''}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Colore scuro"
								>
									<ColorPicker
										disabled
										value={ theme.color_dark || ''}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Colore testo"
								>
									<ColorPicker
										disabled
										value={theme.text || ''}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Colore testo chiaro"
								>
									<ColorPicker
										disabled
										value={ theme.text_light || ''}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Testo sul colore principale"
								>
									<ColorPicker
										disabled
										value={	theme.text_over_main || ''}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Testo sul colore scuro"
								>
									<ColorPicker
										disabled
										value={ theme.text_over_dark || ''}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Sfondo intestazione tabella"
								>
									<ColorPicker
										disabled
										value={ theme.table_header_bg || ''}
									/>
								</FormGroup>
							</Col>
							<Col all={12}>
								<FormGroup
									name=""
									label="Colore bordo tabela"
								>
									<ColorPicker
										disabled
										value={ theme.table_border || ''}
									/>
								</FormGroup>
							</Col>
						</Row>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
