import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as ConnectorsEdit } from '../../Components/TriggerConnectors'
import { List as ConnectorActionsList } from '../../Components/ConnectorActions'

function PageEdit() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [connectorActions, setConnectorActions] = useState([])

	const defaultFiltersValues = {
		cols: 'connector_id,connector'
	}

	useEffect(() => {
		handleLoad()
		handleListConnectorActions()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/cha/trigger/connectors/load?connector_id=${id}`)
			setEditItem(area.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/trigger/connectors/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/trigger/connectors/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}
	//Gestion connector actions
	const handleCreateConnectorAction = async(data) => {
		await http.post(`${window.__endpoint}/cha/trigger/connectors/actions/create`, {
			...data,
			connector_id: id,
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListConnectorActions()
	}

	const handleListConnectorActions = async(filters) => {
		const list = await http.get(`${window.__endpoint}/cha/trigger/connectors/actions/list`, {
			params: {
				connector_id: id
			}
		})
		setConnectorActions(list.data)
	}

	const handleDeleteConnectorAction = async(id) => {
		await http.delete(`${window.__endpoint}/cha/trigger/connectors/actions/delete?connector_action_id=${id}`)
		handleListConnectorActions()
	}

	return <>
		<Card title={`${isNew ? 'Crea connettore' : `Modifica ${editItem && editItem.connector != '' ? editItem.connector : 'connettore'}`}`}>
			<ConnectorsEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
				setEditItem={setEditItem}
			/>
		</Card>
		{!isNew && <> <br />
			<Card title={'Elenco azioni connettori'}>
				<ConnectorActionsList
					items={connectorActions}
					onDelete={handleDeleteConnectorAction}
					onSubmit={handleCreateConnectorAction}
					setEditItem={setConnectorActions}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
