import React from 'react'
import { Panel } from 'primereact/panel'
import { Button } from '..'

const stylings = {
	alert: {
		icon: 'fa fa-exclamation',
		color: '#856404',
		bgColor: '#fff3cd',
	},
	danger: {
		icon: 'fa fa-exclamation-triangle',
		color: '#721c24',
		bgColor: '#f8d7da',
	},
	success: {
		icon: 'fa fa-check',
		color: '#155724',
		bgColor: '#d4edda',
	},
	info: {
		icon: 'fa fa-info',
		color: '#0c5460',
		bgColor: '#d1ecf1',
	}
}

const Alert = ({
	message,
	type,
	...props
}) => {

	const {
		isHtml,
		notToggleable
	} = props

	const bsType = stylings[type] || stylings.info

	const template = (options) => {
		const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
		const className = `${options.className} justify-content-start`
		const titleClassName = `${options.titleClassName} ml-2 red-500`
		const style = { fontSize: '1.25rem', color: '#000' }

		return <div className={className} style={{ backgroundColor: bsType.bgColor }}>
			<Button icon={toggleIcon} className={options.togglerClassName} onClick={options.onTogglerClick} />
			<span className={titleClassName} style={style}>
				<i className={bsType.icon}></i>
			</span>
		</div>
	}

	return <Panel headerTemplate={template} toggleable={!notToggleable} style={{ backgroundColor: bsType.bgColor }}>
		{isHtml ?
			<div dangerouslySetInnerHTML={{ __html: message }} />
			:
			<p className="m-0" style={{ color: bsType.color, fontWeight: 'bold' }}>
				{message}
			</p>
		}
	</Panel>
}

export default Alert