import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, Button } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onUpdate, onList, filtersValues, list_id, ...props }) {
	const { onSubmit } = props
	const [roles, setRoles] = React.useState([])

	const [selectedRole, setSelectedRole] = React.useState()

	const handleLoadOptions = async() =>{
		const roles = await http.get(`${window.__endpoint}/cha/roles/list?cols=role_id,name,description&order=name`)

		setRoles(roles.data)
	}

	React.useEffect(() => {
		handleLoadOptions()
	}, [])

	const handleSubmit = () =>{
		onSubmit({
			role_object_id: -1,
			role_id: selectedRole,
		})
		setSelectedRole(null)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>
		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedRole || ''}
					onChange={(e) => setSelectedRole(e.target.value)}
					options={roles || []}
					optionValue={(o) => o.role_id}
					optionLabel={(o) => o.name}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Button onClick={handleSubmit} disabled={! selectedRole}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='role_object_id'
			columns={[
				{ field: 'role_object_id', header: 'Id', sortable: true },
				{ field: 'role', header: 'Nome', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
