import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as PlansEdit } from '../../Components/Plans'
import { List as ListModulePlans } from '../../Components/PlansModules'

function PageEdit() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [modules, setModules] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		plan_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListModules(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei piani
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (! isNew) {
			const module = await http.get(`${window.__endpoint}/environment/cha/plans/load?plan_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (! isNew) {
			await http.put(`${window.__endpoint}/environment/cha/plans/update?plan_id=${id}`, editItem)
		} else {
			const id = await http.post(`${window.__endpoint}/environment/cha/plans/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei moduli
	const handleListModules = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/cha/plans/modules/list`, {
			params: {
				...filters,
			}
		})
		setModules(list.data)
	}
	const handleDeleteModules = async(id) => {
		await http.delete(`${window.__endpoint}/environment/cha/plans/modules/delete?plan_module_id=${id}`)
		handleListModules(filtersValues)
	}
	const handleCreateModules = async(data) => {
		await http.post(`${window.__endpoint}/environment/cha/plans/modules/create`, {
			plan_id: id, // ID Ruolo
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModules(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea piano' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'piano'}`}`}>
			<PlansEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Modifica moduli">
				<ListModulePlans
					plan_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={modules}
					onList={handleListModules}
					onDelete={handleDeleteModules}
					onSubmit={handleCreateModules}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
