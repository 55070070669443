import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues, report_id }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [filters, setFilters] = useState({})
	const navigate = useNavigate()
	const editRoot = 'chart-series'

	return <React.Fragment>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={editRoot}
			hideBtnFilter
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='report_chart_serie_id'
			columns={[
				{ field: 'report_chart_serie_id', header: 'Id', sortable: true },
				{ field: 'serie_title', header: 'Titolo', sortable: true },
				{ field: 'serie_type', header: 'Tipo', sortable: true },
				{ field: 'field', header: 'Campo', sortable: true },
				{ field: 'val_order', header: 'Ordine', sortable: true },
			]}
			editRoot={editRoot}
		/>
	</React.Fragment>
}

export default List
