import { React } from 'react'
import { Col, FormGroup, InputText, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import { json } from 'react-router-dom'

function Execute({ editItem, onChange, onSubmit, parameters, id, setPath }) {

	const handleExecute = async() => {
		var url = `${window.__endpoint}/develop/scripts/execute`
		var fileName = 'download.zip'
		var xhr = new XMLHttpRequest()
		xhr.open('POST', url, true)
		xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'))
		xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')

		xhr.responseType = 'blob'

		xhr.onload = function(e) {
			if (this.status == 200) {
				var blob = this.response
				//TODO fallback needed for IE8 & IE9
				if (navigator.appVersion.toString().indexOf('.NET') > 0) {
					//IE 10+
					window.navigator.msSaveBlob(blob, fileName)
				} else {
					//Firefox, Chrome
					var a = document.createElement('a')
					var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
					document.body.appendChild(a)
					a.style = 'display: none'
					a.href = blobUrl
					a.download = fileName
					a.click()
				}
			}
		}
		xhr.send(JSON.stringify({
			script_id: id,
			params: {
				...editItem
			}
		}))
	}

	const execute = {
		tooltip: 'esegui',
		icon: 'pi pi-play',
		type: 'submit'
	}

	return <FormValidator
		onSubmit={handleExecute}
		editItem={editItem}

		requiredFields={
			Array.isArray(parameters) ? parameters
				.filter((item)=> item.required_flag == 1)
				.map((item,i)=> item.parameter_code)
				: []
		}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar
					hideGoBackButton
					readOnly
					customActions={[execute]}
				/>
				<Row>
					{ Array.isArray(parameters) && parameters.map((item, i)=> {
						return (<Col all={12} key={i}>
							<FormGroup
								name={item.parameter_code}
								label={item.name}
								description={item.description}
								validationState={validator.validationState[item.parameter_code]}
							>
								<InputText
									value={editItem[item.parameter_code] || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>)
					})}
				</Row>
			</form>}}
	</FormValidator>
}
export default Execute
