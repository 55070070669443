import React, { useState, useEffect } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import http from 'axios'
import { filter } from 'lodash'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [filters, setFilters] = useState({})
	const [sidebarFilters, setSidebarFilters] = useState({})
	const handleListFilters = async() => {
		// Carico le response di tutti i filtri
		let responses = {
			applications: {
				data: await http.get(`${window.__endpoint}/cha/applications/list?cols=application_id,description&order=name`),
				key: 'application_id'
			},
			modules: {
				data: await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`),
				key: 'module_id'
			},
			profiles_types: {
				data: await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,name&list_id=${45}`),
				key: 'list_value_id'
			},
		}

		// For each key in responses, map the value to a new array of objects with value and label, then put it in the filters state with the same key
		let _filters = {}
		for (const [key, value] of Object.entries(responses)) {
			_filters[key] = value.data.data.map(item => ({ value: item[value.key], label: item.name ? item.name : item.description }))
		}
		// Set the filters state
		setSidebarFilters(_filters)
	}

	const copy = {
		onClick: async(id) => {
			await http.post(`${window.__endpoint}/cha/profiles/copy?profile_id=${id}`)
			onList(filters)
		},
		label: 'copia',
		icon: 'fa-solid fa-copy'
	}

	useEffect(() => {
		handleListFilters()
	}, [])

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => {
				setFilters(filters)
				onList(filters)
			}}
			defaultValues={defaultFiltersValues}
			filters={[
				{
					type: 'select',
					label: 'Applicazione',
					name: 'application_id',
					options: sidebarFilters.applications,
				},
				{
					type: 'select',
					label: 'Modulo',
					name: 'module_id',
					options: sidebarFilters.modules,
				},
				{
					type: 'select',
					label: 'Tipo di profilo',
					name: 'profile_type_id',
					options: sidebarFilters.profiles_types,
				},
			]}
			filterSelect
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='profile_id'
			columns={[
				{ field: 'profile_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Profilo', sortable: true },
				{ field: 'description', header: 'Descrizione', sortable: true },
				{ field: 'default_value_description', header: 'Default', sortable: true },
				{ field: 'user_visibility_flag', header: 'Utente', sortable: false, type: 'boolean' },
			]}
			customItems={[copy]}
		/>
	</React.Fragment>
}

export default List
