import React, { useEffect, useState, useContext } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as SubscriptionsList } from '../../Components/ShippingMethods'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context as ServerContext } from 'apps/environment/context'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)
	const { selectedServer, selectedEnvironment } = useContext(ServerContext)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer, selectedEnvironment])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/ecom/shipping_methods/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/environment/ecom/shipping_methods/delete?shipping_method_id=${id}`)
		handleList(filtersValues)
	}

	const handleEnvEnable = async(id) => {
		await http.post(`${window.__endpoint}/environment/ecom/shipping_methods/add_to_environment?shipping_method_id=${id}`)
		handleList(filtersValues)
	}

	const handleEnvDisable = async(id) => {
		const env_shipping_method_id = items.find(item => item.shipping_method_id === id).env_shipping_method_id
		await http.post(`${window.__endpoint}/environment/ecom/shipping_methods/remove_from_environment?env_shipping_method_id=${env_shipping_method_id}`)
		handleList(filtersValues)
	}

	return <Card title="Metodi di pagamento">
		<SubscriptionsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
			onEnvEnable={handleEnvEnable}
			onEnvDisable={handleEnvDisable}
		/>
	</Card>
}

export default PageList
