import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox, Select, SelectMulti } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])
	const loadSelectOptions = async() => {
		const shippingMethods = await http.get(`${window.__endpoint}/ecom/shipping_methods/list?cols=shipping_method_id,shipping_method&order=shipping_method`)

		setSelectOptions({
			quantity_types: [
				{
					id: '1',
					text: 'la quantità dev\'essere inserita dall\'utente'
				},
				{
					id: '2',
					text: 'la quantità è fissa (campo quantity_value)'
				},
				{
					id: '3',
					text: 'la quantità è la stessa del prodotto correlato'
				}
			],
			shipping_methods: shippingMethods.data
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['service']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Metodo di pagamento */}
					<Col all={12}>
						<FormGroup
							name="service"
							label="Servizio"
							validationState={validator.validationState.service}
						>
							<InputText
								value={editItem.service || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Tipo di quantità */}
					<Col all={6}>
						<FormGroup
							name="quantity_type"
							label="Tipo di quantità"
							validationState={validator.validationState.quantity_type}
						>
							<Select
								value={editItem.quantity_type || ''}
								onChange={onChange}
								options={selectOptions.quantity_types || []}
								optionValue={(o) => o.id}
								optionLabel={(o) => o.text}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Quantità valore */}
					<Col all={6}>
						<FormGroup
							name="quantity_value"
							label="Quantità"
							validationState={validator.validationState.quantity_value}
						>
							<InputText
								type='number'
								value={editItem.quantity_value || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Metodi di spedizione disabilitati*/}
					<Col all={12}>
						<FormGroup
							name="disabled_shipping_method_ids"
							label="Metodi di spedizione disabilitati"
							validationState={validator.validationState.disabled_shipping_method_ids}
						>
							<SelectMulti
								value={editItem.disabled_shipping_method_ids || ''}
								onChange={onChange}
								options={selectOptions.shipping_methods || []}
								optionValue={(o) => o.shipping_method_id}
								optionLabel={(o) => o.shipping_method}
								placeholder={'Seleziona metodi di spedizione'}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
