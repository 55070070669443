import React, { useEffect, useState, useContext } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as UmGroupsList } from '../../Components/Drivers'
import { SearchContext } from 'core/contexts/SearchContext'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/int/drivers/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/int/drivers/delete?driver_id=${id}`)
		handleList(filtersValues)
	}

	const handleSetItems = items => {
		setItems(items)
	}

	return <Card title="Drivers">
		<UmGroupsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
			handleSetItems={handleSetItems}
		/>
	</Card>
}

export default PageList
