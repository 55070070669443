/* global _ */
import React, { useState } from 'react'
import labels from 'core/labels'

function FormValidator({ children, editItem, onSubmit, requiredFields, validationFunction }) {
	const [validationState, setValidationState] = useState({})

	const handleSubmit = (e) => {
		// Evito il postback del form
		e.preventDefault()
		// Evito la propagazione del submit nel caso di form annidati
		e.stopPropagation()

		let validationErrors = {}

		// Verifico se i campi obbligatori sono compilati
		if (Array.isArray(requiredFields)) {
			requiredFields.forEach(field => {
				if (!editItem[field] != '') {
					validationErrors = {
						...validationErrors,
						[field]: labels.required_field,
					}
				}
			})
		}

		// Se non ci sono campi obbligatori da compilare verifico se c'è una funzione di validazione
		if (Object.values(validationErrors).length == 0 && validationFunction) {
			validationErrors = validationFunction(editItem)
		}

		// Imposto lo stato della validazione
		setValidationState(validationErrors)

		// Se è tutto ok chiamo la funzione onSubmit
		if (Object.values(validationErrors).length == 0) {
			onSubmit()
		}
	}

	return children({
		onSubmit: handleSubmit,
		validationState: validationState,
	})
}

export default FormValidator
