import { React, useState, useEffect } from 'react'
import { Col, Checkbox, FormGroup, Select, InputTextarea, Row, InputText } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ text_id, lang, editItem, setEditItem, onChange, onSubmit, isNew }) {
	const [selectedOptions, setSelectOptions] = useState([])
	const [text, setText] = useState({})

	useEffect(() => {
		loadSelectedOptions()
		loadText()
	}, [])

	const loadText = async() => {
		const curText = await http.get(`${window.__endpoint}/ecom/texts/load?text_id=${text_id}`)

		setText(curText.data)
	}

	const loadSelectedOptions = async() => {
		let langs
		if (isNew){
			langs = await http.get(`${window.__endpoint}/ecom/texts/listUnusedLangs?cols=code,name&order=name&text_id=${text_id}`)

		} else {
			langs = await http.get(`${window.__endpoint}/cha/text_translations/languages?cols=code,name&order=name`)

		}
		setSelectOptions({
			langs: langs.data
		})
	}
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['lang', 'text']}
	>
		{(validator) => {

			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Lingua di traduzione */}
					<Col all={12}>
						<FormGroup
							name="lang"
							label="Lingua di traduzione"
							validationState={validator.validationState.code}
						>
							<Select
								disabled={!isNew}
								value={lang || editItem.lang || ''}
								onChange={onChange}
								options={selectedOptions.langs || []}
								optionValue={(o) => o.code}
								optionLabel={(o) => o.code}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Testo Originale */}
					<Col all={12}>
						<FormGroup
							name="text"
							label="Testo originale"
						>
							<InputTextarea
								disabled
								value={text.text}
								//onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo tradotto */}
					<Col all={12}>
						<FormGroup
							name="text"
							label="Testo tradotto"
						>
							<InputTextarea
								value={editItem.text || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
