import React, { useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { useDispatch, useSelector } from 'react-redux'
import { setMessage } from 'reducers/ui/uiSlice'

function FeedBack() {
	const toast = useRef()
	const dispatch = useDispatch()
	const { message } = useSelector(state => state.ui)

	useEffect(() => {
		// Quando varia il valore di message, se questo è diverso da null, visualizzo il feedback
		// Rimuovo i tag HTML dal testo del messaggio
		if (message) {
			const severity = message.severity || 'info'
			const summary = message.summary || 'Info'
			const life = message.life || 3000
			const sticky = severity == 'error' // Se il messaggio è di errore lo tengo visibile

			let messages = []
			if (Array.isArray(message.detail)) {
				// Se detail è un array creo un messaggio per ogni elemento
				message.detail.forEach(msg => {
					messages = [
						...messages,
						{
							sticky: sticky,
							life: life,
							severity: severity,
							summary: summary,
							detail: msg.replace(/(<([^>]+)>)/gi, '')
						}
					]
				})
			} else {
				// Se ho un singolo messaggio imposto dei valori predefiniti per la durata, il titolo ed il colore
				// A questi concateno i valori ricevuti che possono sovrascrivere quelli di default
				messages = {
					sticky: sticky,
					life: life,
					severity: severity,
					summary: summary,
					detail: message.detail.replace(/(<([^>]+)>)/gi, ''),
				}
			}
			toast.current.show(messages)
			dispatch(setMessage({ message: null }))
		}
	}, [message])

	return <Toast ref={toast} />
}

export default FeedBack
