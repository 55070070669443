import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			// onDelete={onDelete}
			items={items}
			fieldId='env_id'
			columns={[
				{ field: 'env_id', header: 'Id', sortable: true },
				{ field: 'env', header: 'Ambiente', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'offline_flag', header: 'Stato', sortable: true, body: ({ offline_flag }) => {
					switch (Number(offline_flag)) {
						case 1: return <Tag severity="danger" value="offline" />
						default: return <Tag severity="success" value="online" />
					}
				} },
			]}
			scrollHeight="calc(100vh - 23rem)"
		/>
	</React.Fragment>
}

export default List
