import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as NotificationModelsEdit } from '../../Components/NotificationModels'
import { List as ModelObjectsList } from '../../Components/ModelObjects'
import { List as ModelTargetList } from '../../Components/NotificationsModelTargets'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [objects, setObjects] = useState([])
	const [targets, setTargets] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		notification_model_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListObjects(defaultFiltersValues)
			handleListTargets(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/cha/notification_models/load?notification_model_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/notification_models/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/notification_models/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei Oggetti
	const handleListObjects = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/module_objects/list`, {
			params: {
				refer_type_id: 283,
				refer_id: id
			}
		})
		setObjects(list.data)
	}
	const handleDeleteObjects = async(id) => {
		await http.delete(`${window.__endpoint}/cha/module_objects/delete?module_object_id=${id}`)
		handleListObjects(filtersValues)
	}
	const handleCreateObjects = async(data) => {
		await http.post(`${window.__endpoint}/cha/module_objects/create`, {
			...data,
			refer_type_id: 283,
			refer_id: id
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListObjects(filtersValues)
	}

	// Gestione destinatari
	const handleListTargets = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/notificationModels/targets/list`, {
			params: {
				notification_model_id: id,
			}
		})
		setTargets(list.data)
	}
	const handleDeleteTargets = async(id) => {
		await http.delete(`${window.__endpoint}/cha/notificationModels/targets/delete?notification_model_target_id=${id}`)
		handleListTargets(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea modello di notifica' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'modello di notifica'}`}`}>
			<NotificationModelsEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Assegnazione oggetti">
				<ModelObjectsList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={objects}
					onList={handleListObjects}
					onDelete={handleDeleteObjects}
					onSubmit={handleCreateObjects}
				/>
			</Card>
			<br />
			<Card title="Destinatari">
				<ModelTargetList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={targets}
					onList={handleListTargets}
					onDelete={handleDeleteTargets}
					//onSubmit={handleCreateObjects}
				/>
			</Card>
		</React.Fragment>
		}
	</>
}

export default PageEdit
