import React, { useEffect, useState } from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Edit } from '..'
import { Modal } from 'components/Ui'
import http from 'axios'

function List({ items, onDelete, onList, filtersValues, onSubmit, ...props }) {
	const [modalOpen, setModalOpen] = useState(false)
	const [editItem, setEditItem] = useState({})
	const [states, setStates] = useState([])

	// Testata (contiene dei dati per fare un'altra chiamata)
	const { headerItem } = props

	const handleListState = async() => {
		const list = await http.get(`${window.__endpoint}/cha/states/list`, {
			params: {
				refer_type_id: headerItem.refer_type_id,
				cols: 'refer_state_id,name',
			}
		})
		setStates(list.data)
	}

	useEffect(() => {
		if (headerItem.refer_type_id == undefined) return
		handleListState()
	}, [headerItem.refer_type_id, headerItem.refer_state_id])

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/cha/states/enabled/load?enable_state_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}
	const handleSave = async() => {
		let data = {
			...editItem,
			refer_state_id: headerItem.refer_state_id,
		}
		await onSubmit(data)
	}

	return <React.Fragment>
		<ListToolbar
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({
					refer_state_id: headerItem.refer_state_id,
				})
			}}
			label='Nuovo stato abilitato'
		>
			<Edit
				{...props}
				editItem={editItem}
				states={states}
				onChange={(e) => setEditItem({
					...editItem,
					[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
				})}
				onSubmit={handleSave}
			/>
		</Modal>

		<ListGrid
			onDelete={onDelete}
			onUpdate={onSubmit}
			items={items}
			fieldId='enable_state_id'
			columns={[
				{ field: 'enable_state_id', header: 'Id' },
				{ field: 'refer_state_id', header: 'Valore' },
				{ field: 'name', header: 'Stato' },
				{ field: 'description', header: 'Descrizione' },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
