import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as ChecksEdit } from '../../Components/ModuleChecks'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id, module_check_id } = useParams()
	const isNew = (module_check_id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
		setEditItem({
			...editItem,
			module_id: id
		}
		)
	}, [id,module_check_id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/cha/modules/checks/load?module_check_id=${module_check_id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/modules/checks/create`, editItem)
			navigate(`/cha/modules/checks/edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/modules/checks/update`, {
				...editItem,
				module_check_id: module_check_id
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea modulo' : `Modifica ${editItem && editItem.check != '' ? editItem.check : 'modulo'}`}`}>
			<ChecksEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

export default PageEdit
