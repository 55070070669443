import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * pages
import {
	PageList,
	PageEditTranslations,
	PageListTranslations
} from './Pages'

import { SearchContext } from 'core/contexts/SearchContext'

function Router() {
	const [contextSearch, setContextSearch] = React.useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageList />} />
			<Route path="translations" element={<PageListTranslations />} />
			<Route path="translations/create" element={<PageEditTranslations />} />
			<Route path="translations/edit" element={<PageEditTranslations />} />

		</Routes>
	</SearchContext.Provider>
}

export default Router