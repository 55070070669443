import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import Data from './data'

function Cha() {
	return <div>
		<Routes>
			<Route path="/data/*" element={<Data />} />
		</Routes>
	</div>
}

export default Cha
