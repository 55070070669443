import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { HeaderLinesSeparator } from 'components/Page'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { confirmDialog } from 'primereact/confirmdialog'

import { Edit as EnvironmentEdit } from '../../Components/Environments'
import { List as ShopsList } from '../../Components/Shops'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [envIsOk, setEnvIsOk] = useState({
		ok: false,
		disabled: false,
	})

	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListShops(defaultFiltersValues)
		}
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const environment = await http.get(`${window.__endpoint}/server/cha/environments/load?env_id=${id}`)
			setEditItem(environment.data)
			await handleCheckEnv()
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/server/cha/environments/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/server/cha/environments/update`, editItem)
		}
		handleLoad()
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handlePrepareEnvironment = async() => {
		confirmDialog({
			message: 'Procedere con la creazione dell\'organizzazione e dell\'amministratore?',
			header: 'Preparazione ambiente',
			icon: 'pi pi-exclamation-triangle',
			accept: async() => {
				await http.post(`${window.__endpoint}/server/cha/environments/prepare?env_id=${id}`)
				await handleCheckEnv()
			},
			reject: () => { /* Do nothing */}
		})
	}

	const handleCheckEnv = async() => {
		const response = await http.get(`${window.__endpoint}/server/cha/environments/check?env_id=${id}`)
		switch (response.errcode) {
			case 1:
				setEnvIsOk({
					ok: false,
					disabled: false,
				})
				break
			case 2:
				setEnvIsOk({
					ok: false,
					disabled: true,
				})
				break
			default:
				setEnvIsOk({
					ok: true,
					disabled: true,
				})
				break
		}
	}

	const sendEmailToApps = async() => {
		await http.post(`${window.__endpoint}/server/cha/environments/testEmailSmtp?env_id=${id}`)
		dispatch(setMessage({ message: { severity: 'success', summary: 'Email inviata', detail: '' } }))
	}

	const handleListShops = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/server/ecom/shops/list`, {
			params: {
				...filters,
				env_id: id,
			}
		})
		setItems(list.data)
	}

	return <>
		<Card title="Modifica ambiente">
			<EnvironmentEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
				handlePrepareEnvironment={handlePrepareEnvironment}
				envIsOk={envIsOk}
				sendEmailToApps={sendEmailToApps}
			/>
		</Card>
		{(!isNew && (editItem && editItem.shop_type > 0)) && <React.Fragment>
			<br />
			<Card title="Negozi dell'ambiente">
				<ShopsList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={items}
					onList={handleListShops}
				/>
			</Card>
		</React.Fragment>}
	</>
}

export default PageEdit
