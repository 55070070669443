import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, InputTextarea, Row, Checkbox } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import { filter, forEach } from 'lodash'

function Edit({ moduleId, editItem, onMultipleChange, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
	}, [])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Costo */}
					<Col all={6}>
						<FormGroup
							name="cost_flag"
							label="Costo"
							validationState={validator.validationState.cost_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.cost_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Spesa */}
					<Col all={6}>
						<FormGroup
							name="expense_flag"
							label="Spesa"
							validationState={validator.validationState.expense_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.expense_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
