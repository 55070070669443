import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * pages
import { PageList, PageEdit } from './Pages'
import { SearchContext } from 'core/contexts/SearchContext'

function Router() {
	const [contextSearch, setContextSearch] = React.useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageList />} />
		</Routes>
	</SearchContext.Provider>
}

export default Router
