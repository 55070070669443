import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit TextTranslations
import { Edit as ListLtEdit } from '../../Components/ListLt'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()
	const item_id = query.get('item_id')
	const lang_id = query.get('lang_id')

	const isNew = (item_id == undefined || lang_id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
		setEditItem({
			...editItem,
			lang_id: lang_id,
			item_id: item_id
		})
	}, [item_id,lang_id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		const curItem = await http.get(`${window.__endpoint}/inv/system_items/load?item_id=${item_id}`)
		if (!isNew) {
			const text = await http.get(`${window.__endpoint}/inv/system_items_lt/load?item_id=${item_id}&lang_id=${lang_id}`)
			setEditItem(text.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const cur_lang_id = await http.post(`${window.__endpoint}/inv/system_items_lt/create`, editItem)
			navigate(`/inv/system-items/translations/edit?item_id=${item_id}&lang_id=${cur_lang_id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/inv/system_items_lt/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea traduzione' : 'Modifica traduzione'}`}>
			<ListLtEdit
				item_id={item_id}
				lang_id={lang_id}
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
				isNew={isNew}
			/>
		</Card>
	</>
}

function useQuery() {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default PageEdit
