import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const envs = await http.get(`${window.__endpoint}/server/cha/environments/list?cols=env_id,name&order=name`)

		setSelectOptions({
			envs: envs.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['web_url', 'env_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Ambiente */}
					<Col all={6}>
						<FormGroup
							name="env_id"
							label="Ambiente"
							validationState={validator.validationState.env_id}
						>
							<Select
								value={editItem.env_id || ''}
								onChange={onChange}
								options={selectOptions.envs || []}
								optionValue={(o) => o.env_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Nome */}
					<Col all={6}>
						<FormGroup
							name="web_url"
							label="URL portale"
							validationState={validator.validationState.web_url}
						>
							<InputText
								value={editItem.web_url || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
