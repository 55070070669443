import React, { useContext } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as ServicesList } from '../../Components/Services'
import { SearchContext } from 'core/contexts/SearchContext'

function PageList() {
	const [items, setItems] = React.useState([])
	const [filtersValues, setFiltersValues] = React.useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)

	React.useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/ecom/services/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/ecom/services/delete?service_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title="Servizi">
		<ServicesList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
		/>
	</Card>
}

export default PageList
