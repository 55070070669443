import React, { useContext, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as GroupsList } from '../../Components/Groups'
import { SearchContext } from 'core/contexts/SearchContext'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { useDispatch } from 'react-redux'

function PageList() {
	const dispatch = useDispatch()
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)

	React.useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/si/groups/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/si/groups/delete?group_id=${id}`)
		handleList(filtersValues)
	}

	const handleSave = async(data) => {
		if (data.group_id) {
			await http.put(`${window.__endpoint}/si/groups/update`, data)
		} else {
			await http.post(`${window.__endpoint}/si/groups/create`, data)
		}
		handleList(filtersValues)
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleSetItems = items => {
		setItems(items)
	}

	return <Card title="Gruppi di servizi">
		<GroupsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
			onSubmit={handleSave}
			handleSetItems={handleSetItems}
		/>
	</Card>
}

export default PageList
