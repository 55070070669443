import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import { TabView, TabPanel } from 'primereact/tabview'
import http from 'axios'

function Edit({ data, onSubmit }) {
	const [editItem, setEditItem] = useState(data || {
		blog_id: -1,
	})
	const [selectOptions, setSelectOptions] = useState({})

	const onChange = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		onSubmit(editItem)
	}

	const handleListFilters = async() => {
		// Carico le response di tutti i filtri
		const shops = await http.get(`${window.__endpoint}/environment/ecom/shops/list?cols=shop_id,shop_name&order=shop_name`)

		// Set the filters state
		setSelectOptions({
			shops: shops.data,
		})
	}

	useEffect(() => {
		handleListFilters()
	}, [])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['shop_name', 'web_url']}
	>
		{(validator) => {
			return <form onSubmit={handleSubmit}>
				<EditToolbar hideGoBackButton/>
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="shop_id"
							label="Negozio"
							validationState={validator.validationState.shop_id}
						>
							<Select
								value={editItem.shop_id || ''}
								onChange={onChange}
								options={selectOptions.shops || []}
								optionValue={(o) => o.shop_id}
								optionLabel={(o) => o.shop_name}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
