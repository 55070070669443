import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import Cha from './cha/Cha'

function Db() {
	return <div>
		<Routes>
			<Route path="/cha/*" element={<Cha />} />
		</Routes>
	</div>
}

export default Db
