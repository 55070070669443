import { React, useState, useEffect } from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Col, Row, Select, Button } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, print_report_id, items, onDelete, onUpdate, onList, filtersValues, ...props }) {
	const { onSubmit } = props
	const [texts, setTexts] = useState([])

	const [selectedText, setSelectedText] = useState()
	const [selectedType, setSelectedType] = useState()

	const handleLoadOptions = async() =>{
		const texts = await http.get(`${window.__endpoint}/cha/print_reports/texts/unusedText?cols=text_id,code&print_report_id=${print_report_id}`)

		setTexts(texts.data)
	}

	const textTypes = [
		{
			'id': '1',
			'value': 'Etichetta',
		},
		{
			'id': '2',
			'value': 'Sezione',
		}
	]

	useEffect(() => {
		handleLoadOptions()
	}, [])

	const handleSubmit = () =>{
		onSubmit({
			text_id: selectedText,
			text_type_id: selectedType,
		})
		handleLoadOptions()
		setSelectedText(null)
		setSelectedType(null)

	}

	return <>
		<ListToolbar
			{...props}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>
		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedText || ''}
					onChange={(e) => setSelectedText(e.target.value)}
					options={texts || []}
					optionValue={(o) => o.text_id}
					optionLabel={(o) => o.code}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Select
					value={selectedType || ''}
					onChange={(e) => setSelectedType(e.target.value)}
					options={textTypes || []}
					optionValue={(o) => o.id}
					optionLabel={(o) => o.value}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Button onClick={handleSubmit} disabled={!selectedText || !selectedType}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='print_report_text_id'
			columns={[
				{ field: 'text_id', header: 'Id', sortable: true },
				{ field: 'text_code', header: 'Codice', sortable: true },
				{ field: 'text_type_id', header: 'Tipo', sortable: true, body: ({ text_type_id }) => {
					switch (text_type_id) {
						case textTypes[0].id: return textTypes[0].value
						case textTypes[1].id: return textTypes[1].value
						default: return ''
					}
				} },

			]}
		/>
	</>
}

export default List
