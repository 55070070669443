import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * pages
import { PageList, PageEdit } from './Pages'

// Import targets, attachments
import {
	PageEditPhoneMessageTargets as EditPhoneMessageTargets
} from './Pages'

// Importing contexts
import { SearchContext } from 'core/contexts/SearchContext'

function Router() {
	const [contextSearch, setContextSearch] = React.useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageList />} />
			<Route path="create" element={<PageEdit />} />
			<Route path="edit/:id" element={<PageEdit />} />
			{/* Create targets */}
			<Route path="edit/:PhoneMessageModelId/targets/create" element={<EditPhoneMessageTargets />} />
			<Route path="edit/:PhoneMessageModelId/targets/edit/:id" element={<EditPhoneMessageTargets />} />
		</Routes>
	</SearchContext.Provider>
}

export default Router
