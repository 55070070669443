import React from 'react'
import { Tag } from 'components/Ui'
import { ListToolbar, ListGrid } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	return <React.Fragment>
		<ListToolbar
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
			hideBtnFilter
		/>

		<ListGrid
			{...props}
			items={items}
			fieldId='transaction_id'
			hideBtnEdit
			columns={[
				{ field: 'transaction_id', header: 'Id', sortable: true, width: '50%' },
				{ field: 'org_name', header: 'Organizzazione', sortable: true },
				{ field: 'user_name', header: 'Utente', sortable: true },
				{ field: 'transaction_amount', header: 'Ammontare', sortable: true },
				{ field: 'transaction_datetime', header: 'Data', sortable: true },
				{ field: 'transaction_state', header: 'Pagato', type: 'boolean', editable: false, sortable: true, body: ({ transaction_state }) => {
					switch (Number(transaction_state)) {
						case 0: return <Tag severity="danger" value="No" />
						case 1: return <Tag severity="success" value="Si" />
						default: return ''
					}
				} },
			]}
		/>
	</React.Fragment>
}

export default List
