import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setLayoutConfig, setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as PrintReportsEdit } from '../../Components/Reports'
import { List as ParametersList } from '../../../../../cha/modules/Parameters/Components/Parameters'
import { List as ListRoleObjects } from '../../Components/RoleObjects'
import { List as ListFields } from '../../Components/Fields'
import { List as ListModuleObjects } from '../../../../../cha/modules/Modules/Components/ModuleAssignment'
import { List as ChartSeriesList } from '../../Components/ChartSeries'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [parameters, setParameters] = useState([])
	const [fields, setFields] = useState([])
	const [roles, setRoles] = useState([])
	const [objects, setObjects] = useState([])
	const [chartSeries, setChartSeries] = useState([])

	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		report_id: id,
		refer_type_id: 71, // report di stampa
		refer_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListFields(defaultFiltersValues)
			handleListParameters(defaultFiltersValues)
			handleListRoleObjects(defaultFiltersValues)
			handleListModuleObjects(defaultFiltersValues)
			handleListChartSeries()
		}
	}, [id])

	//* Gestione del report
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const report = await http.get(`${window.__endpoint}/rep/reports/load?report_id=${id}`)
			setEditItem(report.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/rep/reports/create`, {
				...editItem,
				default_active_flag: editItem.default_active_flag ? 1 : '0',
				report_id: -1, // report di stampa
			})
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/rep/reports/update`, {
				...editItem,
				report_id: id, // report di stampa
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei campi
	const handleListFields = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/rep/reports/fields/list`, {
			params: {
				...filters,
			}
		})
		setFields(list.data)
	}
	const handleDeleteFields = async(id) => {
		await http.delete(`${window.__endpoint}/rep/reports/fields/delete?report_field_id=${id}`)
		handleListFields(filtersValues)
	}
	const handleCreateFields = async(data) => {
		await http.post(`${window.__endpoint}/rep/reports/fields/create`, {
			report_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListFields(filtersValues)
	}
	const handleGenerateFromQuery = async(report_id) => {
		// Forzo il managed_server_id perché sono su cha e devo generare i campi del report, quindi devo eseguire una query su un ambiente e quell'ambiente è dev
		await http.post(`${window.__endpoint}/rep/reports/fields/generate_from_query?report_id=${report_id}&managed_server_id=1`)
		handleListFields(filtersValues)
	}
	const onCheckQueryBind = async(report_id) => {
		const response = await http.post(`${window.__endpoint}/rep/reports/fields/checkQuery?report_id=${report_id}`)
		if (response.errcode == 1) {
			dispatch(setMessage({ message: { severity: 'error', summary: response.message, detail: '' } }))
		}
		return response
	}

	//* Gestione dei parametri
	const handleListParameters = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/parameters/list`, {
			params: {
				refer_type_id: 71, // report di stampa
				refer_id: id,
			}
		})
		setParameters(list.data)
	}
	const handleDeleteParameters = async(id) => {
		await http.delete(`${window.__endpoint}/cha/parameters/delete?parameter_id=${id}`)
		handleListParameters(filtersValues)
	}
	const handleCreateParameters = async(data) => {
		await http.post(`${window.__endpoint}/cha/parameters/create`, {
			...data,
			refer_type_id: 71, // report di stampa
			refer_id: id,
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListParameters(filtersValues)
	}

	//* Gestione dei ruoli
	const handleListRoleObjects = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/role_objects/list`, {
			params: {
				...filters,
			}
		})
		setRoles(list.data)
	}
	const handleDeleteRoles = async(id) => {
		await http.delete(`${window.__endpoint}/cha/role_objects/delete?role_object_id=${id}`)
		handleListRoleObjects(filtersValues)
	}
	const handleCreateRoles = async(data) => {
		await http.post(`${window.__endpoint}/cha/role_objects/create`, {
			refer_type_id: 71, // report di stampa
			refer_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListRoleObjects(filtersValues)
	}

	//* Gestione dei Oggetti
	const handleListModuleObjects = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/module_objects/list`, {
			params: {
				...filters,
			}
		})
		setObjects(list.data)
	}
	const handleDeleteObjects = async(id) => {
		await http.delete(`${window.__endpoint}/cha/module_objects/delete?module_object_id=${id}`)
		handleListModuleObjects(filtersValues)
	}
	const handleCreateObjects = async(data) => {
		await http.post(`${window.__endpoint}/cha/module_objects/create`, {
			refer_type_id: 71, // report
			refer_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModuleObjects(filtersValues)
	}

	//* Gestione dell'elenco serie grafico
	const handleListChartSeries = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/rep/reports/chart_series/list`, {
			params: {
				report_id: id,
			}
		})
		setChartSeries(list.data)
	}
	const handleDeleteChartSeries = async(id) => {
		await http.delete(`${window.__endpoint}/rep/reports/chart_series/delete?report_chart_serie_id=${id}`)
		handleListChartSeries(filtersValues)
	}
	const handleCreateChartSeries = async(data) => {
		await http.post(`${window.__endpoint}/rep/reports/chart_series/create`, {
			...data,
			report_id: id,
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListChartSeries(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea report ' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'report'}`}`}>
			<PrintReportsEdit
				report_id={id}
				fields={fields}
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Elenco serie grafici">
				<ChartSeriesList
					report_id={id}
					items={chartSeries}
					onList={handleListChartSeries}
					onDelete={handleDeleteChartSeries}
					onSubmit={handleCreateChartSeries}
				/>
			</Card>
			<br />
			<Card title="Elenco campi">
				<ListFields
					refer_type_id={71}
					refer_id={id}
					report_id={id}
					onGenerateFromQuery={handleGenerateFromQuery}
					defaultFiltersValues={defaultFiltersValues}
					onCheckQueryBind={onCheckQueryBind}
					filtersValues={filtersValues}
					items={fields}
					onList={handleListFields}
					onDelete={handleDeleteFields}
					onSubmit={handleCreateFields}
				/>
			</Card>
			<br />
			<Card title="Modifica parametri">
				<ParametersList
					refer_type_id={71}
					refer_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={parameters}
					onList={handleListParameters}
					onDelete={handleDeleteParameters}
					onSubmit={handleCreateParameters}
				/>
			</Card>
			<br />
			<Card title="Assegnazione ruoli">
				<ListRoleObjects
					report_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={roles}
					onList={handleListRoleObjects}
					onDelete={handleDeleteRoles}
					onSubmit={handleCreateRoles}
				/>
			</Card>
			<br />
			<Card title="Assegnazione moduli">
				<ListModuleObjects
					report_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={objects}
					onList={handleListModuleObjects}
					onDelete={handleDeleteObjects}
					onSubmit={handleCreateObjects}
				/>
			</Card>
		</React.Fragment>}
	</>
}

export default PageEdit
