import React from 'react'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'

function Edit({ editItem, onChange, onSubmit }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['file_name']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{editItem.themed_flag == 1 && <Col all={6}>
						<FormGroup
							name="html_theme_id"
							label="Ordine"
							validationState={validator.validationState.html_theme_id}
						>
							<InputText
								value={editItem.html_theme_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>}
					{/* Nome file */}
					<Col all={(editItem.themed_flag != 1 ? 12 : 6)}>
						<FormGroup
							name="file_name"
							label="Nome file"
							validationState={validator.validationState.file_name}
						>
							<InputText
								value={editItem.file_name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Nome file da funzione SQL */}
					<Col all={6}>
						<FormGroup
							name="file_name_function"
							label="Nome file da funzione SQL"
							validationState={validator.validationState.file_name_function}
						>
							<InputText
								value={editItem.file_name_function || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Genera allegato nel record padre della comunicazione  */}
					<Col all={6}>
						<FormGroup
							name="generate_attachment_flag"
							label="Genera allegato nel record padre della comunicazione"
							validationState={validator.validationState.generate_attachment_flag}
						>
							<Select
								value={editItem.generate_attachment_flag || ''}
								onChange={onChange}
								options={[
									{ optionValue: '0', optionLabel: 'No' },
									{ optionValue: '1', optionLabel: 'Si' },
								]}
								optionValue={(o) => o.optionValue}
								optionLabel={(o) => o.optionLabel}
								all={{
									label: `Default (${editItem.default_generate_attachment_flag == '1' ? 'Si' : 'No'})`
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Header custom per l'ambiente */}
					<Col all={4}>
						<FormGroup
							name="custom_header"
							label="Header custom per l'ambiente"
							validationState={validator.validationState.custom_header}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.custom_header == 1}
							/>
						</FormGroup>
					</Col>
					{/* Body custom per l'ambiente */}
					<Col all={4}>
						<FormGroup
							name="custom_body"
							label="Body custom per l'ambiente"
							validationState={validator.validationState.custom_body}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.custom_body == 1}
							/>
						</FormGroup>
					</Col>
					{/* Footer custom per l'ambiente */}
					<Col all={4}>
						<FormGroup
							name="custom_footer"
							label="Footer custom per l'ambiente"
							validationState={validator.validationState.custom_footer}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.custom_footer == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
