import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { Card } from 'components/Ui'
import { SearchContext } from 'core/contexts/SearchContext'
import { List as LtList } from '../../Components/ListLt'

function PageList() {
	const { contextSearch } = useContext(SearchContext)
	const [items, setItems] = React.useState([])
	const query = useQuery()
	const item_id = query.get('item_id')
	const isNew = (item_id == undefined)
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const [item, setItem] = useState({})

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const curItem = await http.get(`${window.__endpoint}/inv/system_items/load?item_id=${item_id}`)
		const list = await http.get(`${window.__endpoint}/inv/system_items_lt/list?item_id=${item_id}`, {
			params: filters,
		})
		setItem(curItem.data)
		setItems(list.data)
	}

	function useQuery() {
		const { search } = useLocation()
		return React.useMemo(() => new URLSearchParams(search), [search])
	}

	return <Card title={`Traduzioni ${item.name || ''}`}>
		<LtList
			item_id={item_id}
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			//onDelete={handleDelete}
		/>
	</Card>
}

export default PageList
