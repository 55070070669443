import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Col, Row, Select } from 'components/Ui'
import http from 'axios'
import { Context } from './context'

// * modules
import Bm from '@environment/bm/app'
import Cha from '@environment/cha/app'
import Si from '@environment/si/app'
import Fls from '@environment/fls/app'
import Ecom from '@environment/ecom/app'
import Eln from '@environment/eln/app'
import Com from '@environment/com/app'
import Rep from '@environment/rep/app'

let serverInterceptor = false
let environmentInterceptor = false
function Environment() {
	const location = useLocation()
	const navigate = useNavigate()

	const [selectedServer, setSelectedServer] = useState('')
	const [selectedEnvironment, setSelectedEnvironment] = useState('')
	const [servers, setServers] = useState([])
	const [environments, setEnvironments] = useState([])

	// Se non ho un server selezionato e sono in una pagina di edit ritorno alla home
	if (location.pathname.split('/').length > 4 && selectedServer == '') {
		navigate('/', { replace: true })
	}

	// Quando cambio il server selezionato ritorno alla pagina della lista
	useEffect(() => {
		navigate(location.pathname.split('/').slice(0, 4).join('/'), { replace: true })
	}, [selectedServer])

	const loadServers = async() => {
		const servers = await http.get(`${window.__endpoint}/server/cha/servers/list?cols=server_id,name&order=val_order`)
		setServers(servers.data)
	}
	const loadEnvironments = async() => {
		const environments = await http.get(`${window.__endpoint}/server/cha/environments/list?cols=env_id,name&order=name`)
		setEnvironments(environments.data)
	}

	useEffect(() => {
		loadServers()

		return () => {
			// On component unmount
			if (serverInterceptor) {
				http.interceptors.request.eject(serverInterceptor)
			}

			if (environmentInterceptor) {
				http.interceptors.request.eject(environmentInterceptor)
			}
		}
	}, [])

	const handleServerChange = (e) => {
		const serverId = e.target.value
		setSelectedServer(serverId)
		setSelectedEnvironment('')

		// Rimuovo l'eventuale parametro managed_server_id già impostato
		if (serverInterceptor) {
			http.interceptors.request.eject(serverInterceptor)
		}
		// Rimuovo l'eventuale parametro managed_env_id già impostato
		if (environmentInterceptor) {
			http.interceptors.request.eject(environmentInterceptor)
		}

		// Imposto il parametro managed_server_id con il server selezionato
		if (serverId > 0) {
			serverInterceptor = http.interceptors.request.use(
				(request) => {
					request.params = { ...request.params, managed_server_id: serverId }
					return request
				}
			)
			loadEnvironments()
		}
	}
	const handleEnvironmentChange = (e) => {
		const envId = e.target.value
		setSelectedEnvironment(envId)

		// Rimuovo l'eventuale parametro managed_env_id già impostato
		if (environmentInterceptor) {
			http.interceptors.request.eject(environmentInterceptor)
		}

		// Imposto il parametro managed_server_id con il server selezionato
		if (envId > 0) {
			environmentInterceptor = http.interceptors.request.use(
				(request) => {
					request.params = { ...request.params, managed_env_id: envId }
					return request
				}
			)
		}
	}

	return <div>
		<Row>
			<Col all={6}>
				<Select
					className="w-full"
					value={selectedServer}
					onChange={handleServerChange}
					options={servers}
					optionValue={(o) => o.server_id}
					optionLabel={(o) => o.name}
					all={{
						label: 'Seleziona un server'
					}}
				/>
			</Col>
			{selectedServer != '' && <Col all={6}>
				<Select
					className="w-full"
					value={selectedEnvironment}
					onChange={handleEnvironmentChange}
					options={environments}
					optionValue={(o) => o.env_id}
					optionLabel={(o) => o.name}
					all={{
						label: 'Seleziona un ambiente'
					}}
				/>
			</Col>}
		</Row>
		{selectedEnvironment != '' && <Context.Provider value={{ selectedServer, selectedEnvironment }}>
			<Routes>
				<Route path="/bm/*" element={<Bm />} />
				<Route path="/cha/*" element={<Cha />} />
				<Route path="/si/*" element={<Si />} />
				<Route path="/fls/*" element={<Fls />} />
				<Route path="/ecom/*" element={<Ecom />} />
				<Route path="/eln/*" element={<Eln />} />
				<Route path="/com/*" element={<Com />} />
				<Route path="/rep/*" element={<Rep />} />
			</Routes>
		</Context.Provider>}
	</div>
}

export default Environment
