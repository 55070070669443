import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit FileTypes
import { Edit as FileTypesEdit } from '../../Components/FileTypes'
import { List as FileReferements } from '../../Components/Referements'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [fileReferements, setFileReferements] = useState([])
	const defaultFiltersValues = {
		image_id: id
	}

	useEffect(() => {
		handleLoad()
		handleListFileReferements(defaultFiltersValues)
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/environment/fls/file_types/load?file_type_id=${id}`)
			setEditItem(module.data)
		}
	}

	const handleSave = async() => {
		if (! isNew) {
			await http.put(`${window.__endpoint}/environment/fls/file_types/update`, {
				...editItem,
				file_type_id: id,
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei riferimenti
	const handleListFileReferements = async(filters) => {
		const module = await http.get(`${window.__endpoint}/environment/fls/file_types/referements/list?file_type_id=${id}`, {
			params: {
				...filters,
			}
		})
		setFileReferements(module.data)
	}
	const handleDeleteFileReferements = async(data) => {
		await http.post(`${window.__endpoint}/environment/fls/file_types/referements/remove_from_environment?file_type_refer_type_id=${data['file_type_refer_type_id']}`)
		handleListFileReferements(defaultFiltersValues)
	}
	const handleCreateFileReferements = async(data) => {
		await http.post(`${window.__endpoint}/environment/fls/file_types/referements/add_to_environment`, {
			file_type_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListFileReferements(defaultFiltersValues)
	}

	const handleSetDefaultFileReferement = async(data) => {
		await http.post(`${window.__endpoint}/environment/fls/file_types/referements/set_default`, {
			file_type_refer_type_id: data['file_type_refer_type_id']
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListFileReferements(defaultFiltersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea tipo di file' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'tipo di file'}`} per l'ambiente`}>
			<FileTypesEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title='Elenco riferimenti collegati'>
				<FileReferements
					file_id={id}
					defaultFiltersValues={defaultFiltersValues}
					items={fileReferements}
					onList={handleListFileReferements}
					onDelete={handleDeleteFileReferements}
					onSubmit={handleCreateFileReferements}
					onSetDefault={handleSetDefaultFileReferement}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
