import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditDriver } from '../../Components/Scripts'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id, script_id } = useParams()
	const isNew = (script_id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/int/drivers/scripts/load?driver_script_id=${script_id}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const script_id = await http.post(`${window.__endpoint}/int/drivers/scripts/create`, {
				...editItem,
				driver_id: id
			})
			navigate(`/int/drivers/${id}/scripts/edit/${script_id.data}`, { replace: true })

		} else {
			await http.put(`${window.__endpoint}/int/drivers/scripts/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}
	return <>
		<Card title={`${isNew ? 'Crea script' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'script'}`}`}>
			<EditDriver
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

export default PageEdit
