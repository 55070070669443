import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ListGrid, ListToolbar } from 'components/List'
import { Edit } from 'apps/cha/modules/Lists/Components/ListValues'
import { Modal } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onUpdate, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = React.useState(false)
	const [modalOpen, setModalOpen] = React.useState(false)
	const [editItem, setEditItem] = React.useState({})
	const navigate = useNavigate()

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/cha/list_lines/load?list_line_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}

	const translate = {
		onClick: (id) => navigate(`/cha/lists/translation?list_line_id=${id}`),
		label: 'traduci',
		icon: 'fas fa-language'
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
			}}
			label='Nuovo elemento'
		>
			<Edit
				{...props}
				data={editItem}
			/>
		</Modal>

		<ListGrid
			onDelete={onDelete}
			onUpdate={onUpdate}
			items={items}
			fieldId='list_line_id'
			columns={[
				{ field: 'list_line_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'val_order', header: 'Ordine', sortable: true },
				{ field: 'list_value_id', header: 'Valore', sortable: true },
			]}
			onEdit={handleEdit}
			customItems={[translate]}
		/>
	</React.Fragment>
}

export default List
