import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [countries, setCountries] = useState([])

	const handleListFilters = async() => {
		const countriesResponse = await http.get(`${window.__endpoint}/utl/countries/list?order=name`)

		const countries = countriesResponse.data.map(item => ({
			value: item.country_id,
			label: item.name
		}))

		setCountries(countries)
	}

	React.useEffect(() => {
		handleListFilters()
	}, [])

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[{
				type: 'select',
				label: 'Regione',
				name: 'country_id',
				options: countries
			}]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='region_id'
			columns={[
				{ field: 'region_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'country', header: 'Paese', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
