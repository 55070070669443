import React from 'react'

function SectionSeparator({ title }) {
	return <React.Fragment>
		<hr/>
		<h6>{title}</h6>
	</React.Fragment>
}

export default SectionSeparator
