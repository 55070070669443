import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Environments } from '../modules/Environments'

function App() {
	return <Routes>
		<Route path="envs/*" element={<Environments />} />
	</Routes>
}

export default App
