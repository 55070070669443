import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	return <React.Fragment>
		<ListToolbar
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={'fields'}
			hideBtnFilter
		/>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='service_field_id'
			editRoot={'fields'}
			columns={[
				{ field: 'service_field_id', header: 'Id', sortable: true, width: '50%' },
				{ field: 'name', header: 'Nome campo', sortable: true },
				{ field: 'value', header: 'Valore', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
