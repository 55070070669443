import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, Button } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, list_id, ...props }) {
	const { onSubmit } = props
	const [referementTypes, setRefermentTypes] = React.useState([])

	const [selectedReferment, setSelectedReferment] = React.useState()

	const handleLoadOptions = async() =>{
		const referementTypes = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name,referment_full_name&order=referment_full_name,name`)

		setRefermentTypes(referementTypes.data)
	}

	React.useEffect(() => {
		handleLoadOptions()
	}, [])

	const handleSubmit = () =>{
		onSubmit({
			module_refer_type_id: -1,
			refer_type_id: selectedReferment,
		})
		setSelectedReferment(null)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>
		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedReferment || ''}
					onChange={(e) => setSelectedReferment(e.target.value)}
					options={referementTypes || []}
					optionValue={(o) => o.refer_type_id}
					optionLabel={(o) => o.referment_full_name}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Button onClick={handleSubmit} disabled={! selectedReferment}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='module_refer_type_id'
			columns={[
				{ field: 'module_refer_type_id', header: 'Id', sortable: true },
				{ field: 'referment_full_name', header: 'Nome', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
