import React from 'react'
import { Col, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={[]}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				{/* Nome */}
				<Col all={12}>
					<FormGroup
						name="object"
						label="Oggetto"
						validationState={validator.validationState.object}
					>
						<InputText
							value={editItem.object || ''}
							onChange={onChange}
							placeholder={editItem.default_object || ''}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* msg */}
				<Col all={6}>
					<FormGroup
						name="message"
						label="Messaggio"
						validationState={validator.validationState.message}
					>
						<HTMLEditor
							value={editItem.message || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				{/* Default msg */}
				<Col all={6}>
					<FormGroup
						name="default_message"
						label="Messaggio di default"
						validationState={validator.validationState.default_message}
					>
						<HTMLEditor
							value={editItem.default_message || ''}
							rows={4}
							readOnly
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				{/* Info query */}
				<Col all={12}>
					<FormGroup
						name="info_query"
						label="Info query"
						validationState={validator.validationState.info_query}
					>
						<InputTextarea
							type="text"
							row={6}
							value={editItem.info_query || ''}
							placeholder={'Inserisci qui eventuale query per sostituire dei valori personalizzati nel modello di comunicazione'}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				{/* Target query */}
				<Col all={12}>
					<FormGroup
						name="target_query"
						label="Target query"
						validationState={validator.validationState.target_query}
					>
						<InputTextarea
							type="text"
							row={6}
							value={editItem.target_query || ''}
							placeholder={editItem.default_target_query || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				{/* Attachments query */}
				<Col all={12}>
					<FormGroup
						name="attachment_query"
						label="Attachments query"
						validationState={validator.validationState.attachment_query}
					>
						<InputTextarea
							type="text"
							row={6}
							value={editItem.attachment_query || ''}
							placeholder={editItem.default_attachment_query || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				{/* Invia allegati come link (No) */}
				<Col all={6}>
					<FormGroup
						name="send_link_files_attachs_flag"
						label="Invia allegati come link"
					>
						<Select
							value={editItem.send_link_files_attachs_flag || ''}
							onChange={onChange}
							options={[
								{ optionValue: '0', optionLabel: 'No' },
								{ optionValue: '1', optionLabel: 'Si' },
							]}
							optionValue={(o) => o.optionValue}
							optionLabel={(o) => o.optionLabel}
							all={{
								label: `Default (${editItem.default_send_link_files_attachs_flag == '1' ? 'Si' : 'No'})`
							}}
						/>
					</FormGroup>
				</Col>
				{/* Procedura di callback */}
				<Col all={6}>
					<FormGroup
						name="callback_procedure"
						label="Procedura di callback"
						validationState={validator.validationState.callback_procedure}
					>
						<InputText
							value={editItem.callback_procedure || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				{/* Abilita script di automazione (Default = No) */}
				<Col all={6}>
					<FormGroup
						name="automation_enabled_flag"
						label="Abilita script di automazione"
					>
						<Select
							value={editItem.automation_enabled_flag || ''}
							onChange={onChange}
							options={[
								{ optionValue: '0', optionLabel: 'No' },
								{ optionValue: '1', optionLabel: 'Si' },
							]}
							optionValue={(o) => o.optionValue}
							optionLabel={(o) => o.optionLabel}
							all={{
								label: `Default (${editItem.default_automation_enabled_flag == '1' ? 'Si' : 'No'})`
							}}
						/>
					</FormGroup>
				</Col>
				{/* Script automazione */}
				<Col all={6}>
					<FormGroup
						name="automation_script"
						label="Script automazione"
						validationState={validator.validationState.automation_script}
					>
						<InputText
							value={editItem.automation_script || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit