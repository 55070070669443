import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditService } from '../../Components/Countries'
import { List as ListCountryZones } from '../../Components/CountryZones'
import { List as ListRegions } from '../../Components/Regions'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [listCountryZones, setListCountryZones] = useState([])
	const [listRegions, setListRegions] = useState([])

	const defaultFiltersValues = {
		country_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (! isNew) {
			handleListCountryZones(defaultFiltersValues)
			handleListRegions(defaultFiltersValues)
		}
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/utl/countries/load?country_id=${id}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/utl/countries/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/utl/countries/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione delle zone
	const handleListCountryZones = async(filters) => {
		const list = await http.get(`${window.__endpoint}/utl/countries/country_zones/list`, {
			params: filters
		})
		setListCountryZones(list.data)
	}
	const handleSaveCountryZones = async(data) => {
		await http.put(`${window.__endpoint}/utl/countries/country_zones/update`, {
			...data,
			country_id: id,
		})
		handleListCountryZones(defaultFiltersValues)
	}
	const handleDeleteCountryZones = async(zone_id) => {
		await http.delete(`${window.__endpoint}/utl/countries/country_zones/delete?zone_id=${zone_id}&country_id=${id}`)
		handleListCountryZones(defaultFiltersValues)
	}
	const handleSetCountryZonesItems = listCountryZones => {
		setListCountryZones(listCountryZones)
	}

	//* Gestione delle regioni
	const handleListRegions = async(filters) => {
		const list = await http.get(`${window.__endpoint}/utl/countries/regions/list`, {
			params: filters
		})
		setListRegions(list.data)
	}
	const handleSaveRegion = async(data) => {
		await http.put(`${window.__endpoint}/utl/countries/regions/update`, {
			...data,
			country_id: id,
		})
		handleListRegions(defaultFiltersValues)
	}
	const handleDeleteRegions = async(region_id) => {
		await http.delete(`${window.__endpoint}/utl/countries/regions/delete?region_id=${region_id}&country_id=${id}`)
		handleListRegions(defaultFiltersValues)
	}
	const handleSetRegionsItems = listRegions => {
		setListRegions(listRegions)
	}
	return <>
		<Card title={`${isNew ? 'Crea nazione' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'nazione'}`}`}>
			<EditService
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
				id={id}
			/>
		</Card>
		{id != undefined && <>
			<br />
			<Card title="Modifica zone">
				<ListCountryZones
					country_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listCountryZones}
					onList={handleListCountryZones}
					onDelete={handleDeleteCountryZones}
					handleSetItems={handleSetCountryZonesItems}
					onSubmit={handleSaveCountryZones}
				/>
			</Card>
			<br />
			<Card title="Modifica regioni">
				<ListRegions
					country_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listRegions}
					onList={handleListRegions}
					onDelete={handleDeleteRegions}
					handleSetItems={handleSetRegionsItems}
					onSubmit={handleSaveRegion}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
