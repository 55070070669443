import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as ProfilesEdit } from '../../Components/Profiles'
import { List as LinesList } from '../../Components/Lines'

function PageEdit() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		cols: 'profile_id,name,description,default_value_description,user_visibility_flag'
	}

	useEffect(() => {
		handleLoad()
		handleListProfilesLines({
			profile_id: id,
		})
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/cha/profiles/load?profile_id=${id}`)
			setEditItem(area.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/profiles/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/profiles/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleListProfilesLines = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/profiles/lines/list`, {
			params: {
				...filters,
			}
		})
		setItems(list.data)
	}

	const handleSaveProfilesLines = async(data) => {
		if (data.profile_line_id) {
			await http.put(`${window.__endpoint}/cha/profiles/lines/update`, data)
			return handleListProfilesLines(filtersValues)
		} else {
			await http.post(`${window.__endpoint}/cha/profiles/lines/create`, {
				...data,
				profile_id: id,
			})
			handleListProfilesLines(filtersValues)
		}
	}

	const handleDeleteProfilesLines = async(id) => {
		await http.delete(`${window.__endpoint}/cha/profiles/lines/delete?profile_line_id=${id}`)
		handleListProfilesLines(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea profilo' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'profilo'}`}`}>
			<ProfilesEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
				setEditItem={setEditItem}
			/>
		</Card>
		{(!isNew && editItem) && <>
			<br />
			<Card title="Elenco valori">
				<LinesList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={items}
					headerItem={editItem}
					onList={handleListProfilesLines}
					onDelete={handleDeleteProfilesLines}
					onSubmit={handleSaveProfilesLines}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
