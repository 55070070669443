import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setMessage } from 'reducers/ui/uiSlice'
import { SearchContext } from 'core/contexts/SearchContext'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import http from 'axios'
// Edit Referements
import { Edit as ReferementsEdit } from '../../Components/Referements'
import { List as ReferementsStatesList } from '../../../States/Components/States'
function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	// elementi necessari alla gestione degli stati del riferimento
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		refer_type_id: id,
	}
	const { contextSearch } = useContext(SearchContext)

	const handleList = async(filters) => {
		if (!isNew) {
			setFiltersValues(filters)
			const list = await http.get(`${window.__endpoint}/cha/states/list`, {
				params: filters
			})
			setItems(list.data)
		}
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/cha/referement_types/load?refer_type_id=${id}`)
			setEditItem(module.data)
		}
	}

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/referement_types/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/referement_types/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}
	useEffect(() => {
		handleLoad()
		handleList({
			...defaultFiltersValues,
		})
	}, [id])
	return <>
		<Card title={`${isNew ? 'Crea riferimento' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'riferimento'}`}`}>
			<ReferementsEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Stati del riferimento">
				<ReferementsStatesList
					refer_type_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={items}
					onList={handleList}
				/>
			</Card>
		</React.Fragment>}
	</>
}

export default PageEdit
