import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditService } from '../../Components/Services'
import { List as ListMapping } from '../../Components/Mapping'
import { List as ListFields } from '../../Components/Fields'
import { List as ListGroups } from '../../Components/Groups'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [listMapping, setListMapping] = useState([])
	const [listFields, setListFields] = useState([])
	const [listGroups, setListGroups] = useState([])

	const defaultFiltersValues = {
		service_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListMapping(defaultFiltersValues)
			handleListFields(defaultFiltersValues)
			handleListGroups(defaultFiltersValues)
		}
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/si/services/load?service_id=${id}`)
			setEditItem(service.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/si/services/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/si/services/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	// * Mapping
	const handleListMapping = async(filters) => {
		const list = await http.get(`${window.__endpoint}/si/service_mappings/list`, {
			params: filters
		})
		setListMapping(list.data)
	}
	const handleDeleteMapping = async(id) => {
		await http.delete(`${window.__endpoint}/si/service_mappings/delete?mapping_id=${id}`)
		handleListMapping(defaultFiltersValues)
	}
	const handleSetMappingItems = listMapping => {
		setListMapping(listMapping)
	}

	// * Fields
	const handleListFields = async(filters) => {
		const list = await http.get(`${window.__endpoint}/si/service_fields/list`, {
			params: filters
		})
		setListFields(list.data)
	}
	const handleDeleteFields = async(id) => {
		await http.delete(`${window.__endpoint}/si/service_fields/delete?field_id=${id}`)
		handleListFields(defaultFiltersValues)
	}
	const handleSetFieldsItems = listFields => {
		setListFields(listFields)
	}
	const handleSortFields = async(filters) => {
		await http.post(`${window.__endpoint}/si/service_fields/sort`, {
			fields: filters
		})
		handleListFields(defaultFiltersValues)
	}

	// * Groups
	const handleListGroups = async(filters) => {
		const list = await http.get(`${window.__endpoint}/si/service_groups/list`, {
			params: filters
		})
		setListGroups(list.data)
	}
	const handleSaveGroups = async(data) => {
		await http.post(`${window.__endpoint}/si/service_groups/create`, {
			...data,
			service_id: id,
		})
		handleListGroups(defaultFiltersValues)
	}
	const handleDeleteGroups = async(id) => {
		await http.delete(`${window.__endpoint}/si/service_groups/delete?service_group_id=${id}`)
		handleListGroups(defaultFiltersValues)
	}
	const handleSetGroupsItems = listGroups => {
		setListGroups(listGroups)
	}

	return <>
		<Card title="Modifica servizio">
			<EditService
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{id > 0 && <>
			<br />
			<Card title="Modifica mapping">
				<ListMapping
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listMapping}
					onList={handleListMapping}
					onDelete={handleDeleteMapping}
					handleSetItems={handleSetMappingItems}
				/>
			</Card>
			<br />
			<Card title="Modifica campi">
				<ListFields
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listFields}
					onList={handleListFields}
					onDelete={handleDeleteFields}
					handleSetItems={handleSetFieldsItems}
					handleSort={handleSortFields}
				/>
			</Card>
			<br />
			<Card title="Modifica gruppi">
				<ListGroups
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listGroups}
					onList={handleListGroups}
					onDelete={handleDeleteGroups}
					handleSetItems={handleSetGroupsItems}
					onSubmit={handleSaveGroups}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
