import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Causals } from '../modules/Causals'
import ExpenseTypes from '../modules/ExpenseTypes'

function App() {
	return <Routes>
		<Route path="causals/*" element={<Causals />} />
		<Route path="expense-types/*" element={<ExpenseTypes />} />
	</Routes>
}

export default App
