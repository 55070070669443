import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import { Modal } from 'components/Ui'
import http from 'axios'
import Edit from './../Edit/Edit'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, onSubmit, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [modalOpen, setModalOpen] = React.useState(false)
	const [editItem, setEditItem] = React.useState({
		name: ''
	})

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/si/groups/load?group_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}

	const onValueChange = (e) => {
		setEditItem({
			...editItem,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = () => {
		onSubmit(editItem)
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
			}}
			label='Nuovo gruppo'
		>
			<Edit
				{...props}
				editItem={editItem}
				onChange={onValueChange}
				onSubmit={handleSubmit}
			/>
		</Modal>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='group_id'
			columns={[
				{ field: 'group_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'module_name', header: 'Modulo', sortable: true },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
