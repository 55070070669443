import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'
import { useNavigate } from 'react-router-dom'
import { replace } from 'lodash'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()

	const customise = {
		onClick: (id) => navigate(`/environment/cha/print-reports-themes/edit/${id}`),
		label: 'personalizza',
		//icon: 'fas fa-language'
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
		/>

		<ListGrid
			items={items}
			fieldId='theme_id'
			columns={[
				{ field: 'theme_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'description', header: 'Descrizione', sortable: true },
			]}
			customItems={[customise]}
			scrollHeight="calc(100vh - 23rem)"
			hideBtnEdit
		/>
	</React.Fragment>
}

export default List
