import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const listLines = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name,e_inv_code&list_id=${48}`)

		setSelectOptions({
			listLines: listLines.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['code','name','description']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="code"
						label="Codice"
						validationState={validator.validationState.code}
					>
						<InputText
							value={editItem.code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="e_inv_description"
						label="Descrizione in fattura elettronica"
						validationState={validator.validationState.e_inv_description}
					>
						<InputText
							value={editItem.e_inv_description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputTextarea
							value={editItem.description || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="value"
						label="Valore"
						validationState={validator.validationState.value}
					>
						<InputText
							type="number"
							value={editItem.value || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="deduct_value"
						label="Deducibilita' (%)"
						validationState={validator.validationState.deduct_value}
					>
						<InputText
							type="number"
							min='0'
							max='100'
							value={editItem.deduct_value || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="reverse_charge_flag"
						label="Reverse charge"
						validationState={validator.validationState.reverse_charge_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.reverse_charge_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="exemption_type_id"
						label="Tipo esenzione"
						validationState={validator.validationState.exemption_type_id}
					>
						<Select
							value={editItem.exemption_type_id || ''}
							onChange={onChange}
							options={selectOptions.listLines || []}
							optionValue={(o) => o.list_value_id}
							optionLabel={(o) => o.e_inv_code + ' - ' + o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
