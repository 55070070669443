import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { SearchContext } from 'core/contexts/SearchContext'

// Edit Texts
import { Edit as TextsEdit } from '../../Components/Texts'
import { List as TraslationsList } from '../../Components/TextTranslations'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const { contextSearch } = useContext(SearchContext)
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		cols: 'text_id,lang_id,lang_name,text,text_code,customer_edit_flag',
		text_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew){
			handleListTraslations(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/cha/texts/load?text_id=${id}`)
			setEditItem(module.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/texts/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/texts/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione traduzioni
	const handleListTraslations = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/text_translations/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDeleteTraslations = async(id) => {
		//await http.delete(`${window.__endpoint}/cha/text_translations/delete?text_id=${id}&lang_id=`)
		//handleListTraslations(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea testo' : `Modifica ${editItem && editItem.code != '' ? editItem.code : 'testo'}`}`}>
			<TextsEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Elenco traduzioni">
				<TraslationsList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={items}
					onList={handleListTraslations}
					text_id={id}
					//onDelete={handleDeleteTraslations}
				/>
			</Card>

		</React.Fragment>
		}

	</>
}

export default PageEdit
