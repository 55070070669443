import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit FileTypes
import { Edit as FileTypesEdit } from '../../Components/FileTypes'
import { List as FileModules } from '../../../../../cha/modules/Modules/Components/ModuleAssignment'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [imageModules, setImageModules] = useState([])
	const defaultFiltersValues = {
		image_id: id,
		refer_type_id: 195,
		refer_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListImageModules(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/fls/file_types/load?file_type_id=${id}`)
			setEditItem(module.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/fls/file_types/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/fls/file_types/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei Oggetti
	const handleListImageModules = async(filters) => {
		const module = await http.get(`${window.__endpoint}/cha/module_objects/list`, {
			params: {
				...filters
			}
		})
		setImageModules(module.data)
	}
	const handleDeleteImageModule = async(id) => {
		await http.delete(`${window.__endpoint}/cha/module_objects/delete?module_object_id=${id}`)
		handleListImageModules(defaultFiltersValues)
	}
	const handleCreateImageModule = async(data) => {
		await http.post(`${window.__endpoint}/cha/module_objects/create`, {
			refer_type_id: 195,
			refer_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListImageModules(defaultFiltersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea tipo di file' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'tipo di file'}`}`}>
			<FileTypesEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title='Assegnazione moduli'>
				<FileModules
					file_id={id}
					defaultFiltersValues={defaultFiltersValues}
					items={imageModules}
					onList={handleListImageModules}
					onDelete={handleDeleteImageModule}
					onSubmit={handleCreateImageModule}
				/>
			</Card></>
		}
	</>
}

export default PageEdit
