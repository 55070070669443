import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='shop_id'
			columns={[
				{ field: 'shop_id', header: 'Id', sortable: true },
				{ field: 'shop_name', header: 'Nome', sortable: true },
				{ field: 'web_url', header: 'Url web', sortable: true },
				{ field: 'pwa_url', header: 'Url pwa', sortable: true },
				{ field: 'ecom_type_id', header: 'Tipologia', editable: false, sortable: true, body: ({ ecom_type_id }) => {
					switch (Number(ecom_type_id)) {
						case 1: return <Tag severity="danger" value="ecommerce" />
						case 2: return <Tag severity="success" value="b2b" />
						case 3: return <Tag severity="info" value="elearning" />
						default: return ''
					}
				} },
			]}
		/>
	</React.Fragment>
}

export default List
