import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ um_id, editItem, onChange, onSubmit }) {

	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const groups = await http.get(`${window.__endpoint}/utl/um_groups/list?cols=name,um_group_id&order=name`)

		setSelectOptions({
			groups: groups.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={6}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="um_group_id"
						label="Gruppo"
						validationState={validator.validationState.um_group_id}
					>
						<Select
							value={editItem.um_group_id || ''}
							onChange={onChange}
							options={selectOptions.groups || []}
							optionValue={(o) => o.um_group_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputTextarea
							rows={5}
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
