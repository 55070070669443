import React, { useEffect, useState, useContext } from 'react'
import http from 'axios'
import { useDispatch } from 'react-redux'
import { Card } from 'components/Ui'
import { List as TypeList } from '../../Components/Types'
import labels from 'core/labels'
import { setMessage } from 'reducers/ui/uiSlice'
import { SearchContext } from 'core/contexts/SearchContext'

function PageList() {
	const dispatch = useDispatch()
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/int/types/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/int/types/delete?type_id=${id}`)
		handleList(filtersValues)
	}

	const handleSetItems = items => {
		setItems(items)
	}

	const handleSave = async(data) => {
		if (!data.type_id && data.type_id != 0) {
			await http.post(`${window.__endpoint}/int/types/create`, data)
		} else {
			await http.put(`${window.__endpoint}/int/types/update`, data)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleList(defaultFiltersValues)
	}

	return <Card title="Tipi di driver">
		<TypeList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onSubmit={handleSave}
			onList={handleList}
			onDelete={handleDelete}
			handleSetItems={handleSetItems}
		/>
	</Card>
}

export default PageList
