import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { HeaderLinesSeparator } from 'components/Page'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { Edit as WithHoldingTaxesEdit } from '../../Components/WithHoldingTaxes'
import { List as WithHoldingTaxesValiditiesList } from '../../Components/WithHoldingTaxesValidities'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const validity = await http.get(`${window.__endpoint}/act/withholding_taxes/load?withholding_tax_id=${id}`)
			setEditItem(validity.data)

			handleListValidities()
		}
	}

	const handleListValidities = async() => {
		const list = await http.get(`${window.__endpoint}/act/withholding_taxes/validities/list?withholding_tax_id=${id}`)
		setItems(list.data)
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.put(`${window.__endpoint}/act/withholding_taxes/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/act/withholding_taxes/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleCreateValidityModule = async(data) => {
		await http.post(`${window.__endpoint}/act/withholding_taxes/validities/create`, {
			withholding_tax_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListValidities()
	}

	const handleDeleteValidityModule = async(id) => {
		await http.delete(`${window.__endpoint}/act/withholding_taxes/validities/delete?withholding_tax_validity_id=${id}`)
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListValidities()
	}

	return <>
		<Card title={`${isNew ? 'Crea ritenuta bancaria' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'ritenuta bancaria'}`}`}>
			<WithHoldingTaxesEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <> <Card title="Validità ritenuta bancaria" style={{ marginTop: 15 }}>
			<WithHoldingTaxesValiditiesList
				withholding_tax_id={id}
				editItem={editItem}
				onList={handleListValidities}
				onDelete={handleDeleteValidityModule}
				onSubmit={handleCreateValidityModule}
				filtersValues={filtersValues}
				items={items}
			/>
		</Card>
		</>}
	</>
}

export default PageEdit
