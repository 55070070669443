import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { confirmDialog } from 'primereact/confirmdialog'
import { Button, SplitButton } from 'components/Ui'
import { InputText } from 'primereact/inputtext'
import ModalActions from './Components/ModalActions'
import { Modal } from 'components/Ui'

// Di default ho un bottone per la modifica e uno per l'eliminazione
// per nascondere la modifica, passare hideBtnEdit

// envEnableButtons: mostra i pulsanti per abilitare/disabilitare l'ambiente
// envCustomizeButton: mostra il pulsante per personalizzare per l'ambiente
// onEdit: funzione personalizzata da eseguire al click del pulsante modifica
// onUpdate: funzione chiama al salvataggio di una riga inline
// onDelete: funzione personalizzata da eseguire al click del pulsante elimina (se false non viene visualizzato il pulsante elimina)
// customItems: array di oggetti per aggiungere pulsanti personalizzati

function ListGrid({ columns, customItems, envCustomizeButton, envEnableButtons, fieldId, items, modalActions, paginator, onDelete, onEdit, onUpdate, hideBtnEdit, editRoot, scrollHeight, editInline, defaultSortField, defaultSortOrder, ...props }) {
	const navigate = useNavigate()
	const [modalOpen, setModalOpen] = useState(false)
	const [modalData, setModalData] = useState({})

	const actionsTemplate = (value) => {
		const items = []

		// Se non nascondo il pulsante di modifica, lo aggiungo
		if (! hideBtnEdit) {
			items.push({
				className: 'w-full',
				label: 'modifica',
				onClick: () => {
					if (onEdit) {
						onEdit(value[fieldId])
					}
					if (!editInline && !onEdit) {
						navigate(`./${!editRoot ? '' : `${editRoot}/`}edit/${value[fieldId]}`)
					}
				},
			})
		}

		// Se ricevo la funzione di eliminazione, aggiungo il pulsante
		if (onDelete) {
			const action = () => {
				confirmDialog({
					message: 'Procedere con l\'eliminazione?',
					header: 'Eliminazione',
					icon: 'pi pi-exclamation-triangle',
					accept: () => onDelete(value[fieldId]),
					reject: () => { /* Do nothing */}
				})
			}
			items.push({
				label: 'elimina',
				icon: 'pi pi-trash',
				onClick: action, // se il pulsante è fuori dallo split button serve onClick
				command: action  // se il pulsante è dentro lo split button serve command
			})
		}

		// Se ricevo funzioni custom, le aggiungo
		if (customItems) {
			let actions = []
			actions = customItems.map(action => {
				return {
					label: action.label,
					icon: action.icon,
					// Command serve se i bottoni sono dentro un split button
					command: () => action.onClick(value[fieldId]),
					// onClick serve se i bottoni sono fuori dal split button (per esempio non ho edit, no delete, customActions = [1])
					onClick: () => action.onClick(value[fieldId])
				}
			})
			items.push(...actions)
		}

		// Se non ci sono pulsanti, non mostro nulla
		if (items.length == 0) {
			return null
		}

		// Se ho più di un pulsante, mostro lo split button (e il primo pulsante mostrato è il primo della lista, quindi il pulsante di modifica)
		if (items.length > 1) {
			const upfrontButton = { ...items.shift() }
			return <SplitButton
				{...upfrontButton}
				model={items}
			/>
		} else {
			// Altrimenti, mostro il pulsante singolo
			//rimuovo 'command' dal pulsante che crea errore
			delete items[0].command
			return <Button
				{...items[0]}
			/>
		}
	}

	const envActionsTemplate = (value) => {
		let items = []

		// Se l'ambiente è abilitato, lo posso disabilitare
		if (value.active_state == 1) {
			if (! envCustomizeButton) {
				return <Button className="w-full" label="disabilita" onClick={() => {envEnableButtons.onDisable(value[fieldId])}} />
			}
			items = [
				{
					label: 'disabilita',
					command: () => envEnableButtons.onDisable(value[fieldId])
				}
			]
		}

		// Se ricevo funzioni custom, le aggiungo
		if (customItems) {
			let actions = []
			actions = customItems.map(action => {
				return {
					label: action.label,
					icon: action.icon,
					// Ciclo e vado a aggiungere l'ID sull'action
					command: () => action.onClick(value[fieldId])
				}
			})
			items = [
				...items,
				...actions
			]
		}

		// Se l'ambiente è abilitato di default, non posso fare nulla
		if (value.active_state == 2) {
			if (! envCustomizeButton) {
				return <Button className="w-full" label="default" disabled />
			}
		}

		// Se l'ambiente è disabilitato, lo posso abilitare
		if (value.active_state == 0) {
			return <Button className="w-full" label="abilita" onClick={() => {envEnableButtons.onEnable(value[fieldId])}} />
		} else {

			// Se l'ambiente è abilitato, posso personalizzarlo se ho il pulsante
			if (items.length > 0) {
				return <SplitButton className="w-full" label="personalizza" onClick={() => {navigate(`./${!editRoot ? '' : `${editRoot}/`}edit/${value[fieldId]}`)}} model={items} />
			} else {
				// Altrimenti, non ho il pulsante, ma posso personalizzarlo solamente
				return <Button className="w-full" label="personalizza" onClick={() => {navigate(`./${!editRoot ? '' : `${editRoot}/`}edit/${value[fieldId]}`)}} />
			}
		}
	}

	const modalActionTemplate = (value) => {
		return <Button className="w-full" label="" icon='pi pi-cog' onClick={() => {
			setModalData({
				field_id: fieldId,
				...value
			})
			setModalOpen(true)
		}} />
	}

	const textEditor = (options) => {
		return fieldId != options.field ?
			<InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
			:
			<p>{options.value}</p>
	}

	const onRowEditComplete = (e) => {
		let _products = [...items]
		let { newData, index } = e

		_products[index] = newData

		onUpdate(newData)
	}

	const verifiedBodyTemplate = (rowData, column) => {
		const { field } = column
		return <i className={`pi ${rowData[field] == 1 ? 'pi-check-circle' : 'pi-times-circle'} ${rowData[field] == 1 ? 'text-green-500' : 'text-red-500'}`}></i>
	}

	return <div style={{ width: '100%' }}>
		<DataTable
			size='small'
			value={items}
			sortField={defaultSortField}
			sortOrder={defaultSortOrder}
			scrollable
			scrollHeight={scrollHeight || 'calc(100vh - 21rem)'}
			onRowEditComplete={editInline && onRowEditComplete}
			editMode="row"
			paginator={paginator || false}
			rows={paginator && paginator.rows ? paginator.rows : 10}
			rowsPerPageOptions={[5, 10, 25, 50]}
		>
			{columns.map((column, c) => <Column
				style={column['width'] ? { width: column['width'] } : {}}
				key={c}
				field={column.field}
				body={column.type != 'boolean' ? column.body : (row) => verifiedBodyTemplate(row, column)}
				header={column.header}
				sortable={column.sortable}
				editor={(options) => textEditor(options)}
			/>)}
			{!modalActions && <Column
				body={editInline ? null : (envEnableButtons ? envActionsTemplate : actionsTemplate)}
				header=""
				style={{ width: '25px' }}
				rowEditor={editInline}
			></Column>}
			{modalActions && <Column body={modalActionTemplate} style={{ width: '25px' }}>
			</Column>}
		</DataTable>
		<Modal
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
			}}
			children={<ModalActions
				actions={modalActions}
				data={modalData}
				onClose={() => {
					setModalOpen(false)
				}}
			/>}
			label={'Azioni standard'}
		/>
	</div>
}

export default ListGrid
