import React, { useEffect, useState } from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import Edit from './../Edit/Edit'
import http from 'axios'
import { Modal } from 'components/Ui'

function List({ communication_model, items, onDelete, onList, filtersValues, ...props }) {
	const [print_reports, setPrintReports] = useState([])
	const [modalOpen, setModalOpen] = useState(false)
	const [editItem, setEditItem] = useState({})

	const handleEdit = async(id) => {
		const print_reports = await http.get(`${window.__endpoint}/cha/print_reports/list?cols=print_report_id,name&order=name`)
		setPrintReports(print_reports.data)
		if (id && id != 0) {
			const attachment = await http.get(`${window.__endpoint}/com/communication_models/attachments/load?communication_model_attachment_id=${id}`)
			setEditItem(attachment.data)
		}
		setModalOpen(true)
	}

	return <React.Fragment>
		<ListToolbar
			onShowFilters={() => {}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnFilter
			customAdd={handleEdit}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
			}}
			label='Nuovo allegato'
		>
			<Edit
				{...props}
				print_reports={print_reports}
				data={editItem}
			/>
		</Modal>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='communication_model_attachment_id'
			columns={[
				{ field: 'communication_model_attachment_id', header: 'Id', sortable: true },
				{ field: 'referement_type', header: 'Refer type', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
