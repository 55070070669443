import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit TextTranslations
import { Edit as TextTranslationsEdit } from '../../Components/TextTranslations'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()
	const text_id = query.get('text_id')
	const lang = query.get('lang')

	const isNew = (text_id == undefined || lang == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
		setEditItem({
			...editItem,
			lang: lang,
			text_id: text_id
		})
	}, [text_id,lang])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const text = await http.get(`${window.__endpoint}/ecom/text_translations/load?text_id=${text_id}&lang=${lang}`)
			setEditItem(text.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const lang = await http.post(`${window.__endpoint}/ecom/text_translations/create`, editItem)
			navigate(`/ecom/texts/translations/edit?text_id=${text_id}&lang=${lang.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/ecom/text_translations/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea traduzione' : 'Modifica traduzione'}`}>
			<TextTranslationsEdit
				text_id={text_id}
				lang={lang}
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
				isNew={isNew}
			/>
		</Card>
	</>
}

function useQuery() {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default PageEdit
