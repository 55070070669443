import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Modal } from 'components/Ui'
import http from 'axios'
import Edit from '../Edit/Edit'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, list_id, ...props }) {
	const [modalOpen, setModalOpen] = React.useState(false)
	const [editItem, setEditItem] = React.useState({})

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/cha/referement_types/permissions/load?refer_type_permission_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
			}}
			label='Nuovo elemento'
		>
			<Edit
				{...props}
				data={editItem}
			/>
		</Modal>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='refer_type_permission_id'
			columns={[
				{ field: 'refer_type_permission_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'all_access_level', header: 'Livello accesso tutti', sortable: true },
				{ field: 'owner_access_level', header: 'Livello accesso propri', sortable: true },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
