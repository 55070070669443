import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const file_measures = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,name&list_id=${58}`)

		setSelectOptions({
			file_measures: file_measures.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
								disabled
							/>
						</FormGroup>
					</Col>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputText
								value={editItem.description || ''}
								onChange={onChange}
								placeholder={editItem.default_description || ''}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Estensioni */}
					<Col all={6}>
						<FormGroup
							name="extensions"
							label="Estensioni"
							validationState={validator.validationState.extensions}
						>
							<InputText
								value={editItem.extensions || ''}
								onChange={onChange}
								placeholder={editItem.default_extensions || ''}
							/>
						</FormGroup>
					</Col>
					{/* Dimensione massima file */}
					<Col all={3}>
						<FormGroup
							name="max_size"
							label="Dimensione massima file"
							validationState={validator.validationState.max_size}
						>
							<InputText
								type="number"
								value={editItem.max_size || ''}
								onChange={onChange}
								placeholder={editItem.default_max_size || ''}
							/>
						</FormGroup>
					</Col>
					{/* Unità di misura */}
					<Col all={3}>
						<FormGroup
							name="max_size_um_id"
							label={'Unità di misura'}
						>
							<Select
								value={editItem.max_size_um_id || ''}
								onChange={onChange}
								options={selectOptions.file_measures || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: `Default (${editItem.file_size_um})`
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
