import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setMessage } from 'reducers/ui/uiSlice'
import http from 'axios'
import _, { set } from 'lodash'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button, Col, Modal, Tag, Row } from 'components/Ui'
import RecordComparison from '../RecordComparison/RecordComparison'

function TableDetails({ open, onClose, table, ...props }) {
	const dispatch = useDispatch()
	const [records, setRecords] = useState([])
	const [showOnlyDifferent, setShowOnlyDifferent] = useState(true)
	const [selectedRecords, setSelectedRecords] = useState([])
	const [recordComparison, setRecordComparison] = useState({
		open: false,
		record: {}
	})

	const handleOpen = async() => {
		const list = await http.get(`${window.__endpoint}/deploy/db/cha/data/listRecords?table=${table.name}`)

		// Ricavo le differenze tra i record
		const finalList = list.data.cha.map(item => {
			// Cerco il record corrispondente su cha_cha_deploy
			const deployRecord = list.data.deploy.find(i => {
				let found = true
				table.pk.split(',').forEach(pkField => {
					if (i[pkField] != item[pkField]) {
						found = false
					}
				})
				return found
			})

			return {
				isEqual: _.isEqual(item, deployRecord),
				...item,
			}
		})
		setRecords(finalList)
	}

	const handleClose = () => {
		setRecords([])
		setSelectedRecords([])
		setShowOnlyDifferent(true)
		onClose()
	}

	const handleDeploy = async() => {
		if (selectedRecords.length > 0 && confirm('Procedere con il rilascio?')) {
			const parameters = {
				table: table.name,
				cond: selectedRecords.map(item => table.pk.split(',').reduce((accumulator, value) => {
					return { ...accumulator, [value]: item[value] }
				}, {}))
			}
			const result = await http.post(`${window.__endpoint}/deploy/db/cha/data/deployRecords`, parameters)

			dispatch(setMessage({ message: { severity: 'success', summary: 'Operazione eseguita', detail: '' } }))
			handleClose()
		}
	}

	const compare = (item) => {
		return <Button icon="pi pi-table" onClick={() => setRecordComparison({ open: true, record: item })} />
	}

	return <React.Fragment>
		<Modal
			open={open}
			onClose={handleClose}
			onOpen={handleOpen}
			label={table.name}
			maximizable
			width={'90vw'}
			position="top"
		>
			<hr/>
			<Row>
				<Col md={6}>
					<Button label={`Mostra ${showOnlyDifferent ? 'tutti' : 'solo differenti'}`} severity={showOnlyDifferent ? 'success' : 'danger'} onClick={() => setShowOnlyDifferent(!showOnlyDifferent)} />
				</Col>
				<Col md={6}>
					<Button label="Rilascia" onClick={handleDeploy} disabled={selectedRecords.length == 0} />
				</Col>
			</Row>
			{records.length > 0 ? <DataTable
				size='small'
				value={showOnlyDifferent ? records.filter(i => !i.isEqual) : records}
				selection={selectedRecords}
				onSelectionChange={(e) => setSelectedRecords(e.value)}
				sortMode="multiple"
				scrollable
				scrollHeight={'calc(100vh - 21rem)'}
				editMode="row"
				paginator
				rows={50}
				rowsPerPageOptions={[25, 50, 200]}
			>
				<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
				<Column body={compare} style={{ width: '75px' }}></Column>
				<Column
					field="isEqual"
					body={(item) => <Tag severity={item.isEqual ? 'success' : 'danger'} value={item.isEqual ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-circle-exclamation"></i>} />}
					sortable
				/>
				{Object.keys(records[0]).filter(c => c != 'isEqual').map((column, c) => <Column
					key={c}
					field={column}
					body={(item) => <span>{item[column] && item[column].length > 1000 ? `${item[column].substring(0, 1000)} ...` : item[column]}</span>}
					header={column}
				/>)}
			</DataTable> : <p>Nessun record presente</p>}
		</Modal>
		<RecordComparison
			{...recordComparison}
			table={table}
			onClose={() => setRecordComparison({ open: false, record: {} })}
		/>
	</React.Fragment>
}

export default TableDetails
