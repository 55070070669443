import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { Edit as FieldsEdit } from '../../Components/ServiceFieldsData'

function PageEditFields() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { serviceId, id } = useParams()
	const isNew = (id == undefined)
	const [service, setService] = useState({})
	const [editItem, setEditItem] = useState({
		env_service_id: serviceId,
	})

	useEffect(() => {
		handleLoad()
		handleLoadService()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	//* Load service
	const handleLoadService = async() => {
		const service = await http.get(`${window.__endpoint}/environment/si/services/load?env_service_id=${serviceId}`)
		setService(service.data)
		setEditItem({
			...editItem,
			service_id: service.data.service_id,
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const mapping = await http.get(`${window.__endpoint}/environment/si/service_fields_data/load?service_field_id=${id}`)
			setEditItem(mapping.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/environment/si/service_fields_data/create`, editItem)
			navigate(`../edit/${serviceId}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/environment/si/service_fields_data/update`, {
				service_field_id: id,
				...editItem
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title="Modifica campo">
		{service && <FieldsEdit
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
			serviceId={service.service_id}
		/>}
	</Card>
}

export default PageEditFields
