import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { Edit as CommunicationEdit } from '../../Components/Communications'

function PageEdit() {
	const dispatch = useDispatch()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (! isNew) {
			const validity = await http.get(`${window.__endpoint}/environment/com/communication_models/load?communication_model_id=${id}`)
			setEditItem(validity.data)
		}
	}
	const handleSave = async() => {
		if (! isNew) {
			await http.put(`${window.__endpoint}/environment/com/communication_models/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <>
		<Card title={`${isNew ? 'Crea modello di comunicazione' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'modello di comunicazione'}`}`}>
			<CommunicationEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
	</>
}

export default PageEdit
