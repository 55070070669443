import React, { useEffect, useState, useContext } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as ActionsList } from '../../Components/TriggerActions'
import { SearchContext } from 'core/contexts/SearchContext'

function PageList() {
	const { contextSearch } = useContext(SearchContext)
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		cols: 'action_id,action,module_name,description'
	}

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/trigger/actions/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/cha/trigger/actions/delete?action_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title="Azioni">
		<ActionsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}

		/>
	</Card>
}

export default PageList
