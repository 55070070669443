import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as ChartSeriesEdit } from '../../Components/ChartSeries'

function PageEditChartSeries() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id, report_chart_serie_id } = useParams()
	const isNew = (report_chart_serie_id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id, report_chart_serie_id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/rep/reports/chart_series/load?report_chart_serie_id=${report_chart_serie_id}`)
			setEditItem(area.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const rep_id = await http.post(`${window.__endpoint}/rep/reports/chart_series/create`, {
				report_id: id,
				...editItem,
			})

			navigate(`/rep/reports/edit/${id}/chart-series/edit/${rep_id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/rep/reports/chart_series/update`, {
				report_chart_serie_id: report_chart_serie_id,
				...editItem,
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title={`${isNew ? 'Crea serie grafico' : `Modifica ${editItem && editItem.serie_title != '' ? editItem.serie_title : 'serie grafico'}`}`}>
		<ChartSeriesEdit
			report_chart_serie_id={report_chart_serie_id}
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
		/>
	</Card>
}

export default PageEditChartSeries
