import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { HeaderLinesSeparator } from 'components/Page'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as ListEdit } from '../../Components/Lists'
import { List as ListValues } from '../../Components/ListValues'
import { List as ListRoleObjects } from '../../Components/RoleObjects'
import { List as ListModuleObjects } from '../../../Modules/Components/ModuleAssignment'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [items, setItems] = useState([])
	const [roleObjects, setRoleObjects] = useState([])
	const [moduleObjects, setModuleObjects] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListListLines(defaultFiltersValues)
			handleListRoleObjects(defaultFiltersValues)
			handleListModuleObjects(defaultFiltersValues)
		}
	}, [id])

	//* Lista in edit
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/cha/lists/load?list_id=${id}`)
			setEditItem(area.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/lists/create`, {
				user_visibility_flag: editItem.user_visibility_flag ? editItem.user_visibility_flag : 0,
				active_flag: editItem.active_flag ? editItem.active_flag : 1,
				...editItem
			})
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/lists/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Linee
	const handleListListLines = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/list_lines/list`, {
			params: {
				...filters,
				list_id: id,
			}
		})
		setItems(list.data)
	}
	const handleDeleteListLine = async(id) => {
		await http.delete(`${window.__endpoint}/cha/list_lines/delete?list_line_id=${id}`)
		handleListListLines(filtersValues)
	}

	const handleSaveListLine = async(data) => {
		if (data.list_line_id > 0) {
			await http.put(`${window.__endpoint}/cha/list_lines/update`, {
				...data
			})
			handleListListLines(filtersValues)
		} else {
			await http.post(`${window.__endpoint}/cha/list_lines/create`, {
				list_id: id,
				user_visibility_flag: data.user_visibility_flag ? data.user_visibility_flag : '0', // leave string
				...data
			})
			dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
			handleListListLines(filtersValues)
		}
	}

	//* Ruoli
	const handleListRoleObjects = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/role_objects/list`, {
			params: {
				...filters,
				refer_type_id: 70,
				refer_id: id,
			}
		})
		setRoleObjects(list.data)
	}
	const handleDeleteRoleObject = async(id) => {
		await http.delete(`${window.__endpoint}/cha/role_objects/delete?role_object_id=${id}`)
		handleListRoleObjects(filtersValues)
	}
	const handleCreateRoleObject = async(data) => {
		await http.post(`${window.__endpoint}/cha/role_objects/create`, {
			refer_type_id: 70, // Liste valori
			refer_id: id, // ID Lista valore
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListRoleObjects(filtersValues)
	}

	//* Moduli
	const handleListModuleObjects = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/module_objects/list`, {
			params: {
				...filters,
				refer_type_id: 70,
				refer_id: id,
			}
		})
		setModuleObjects(list.data)
	}
	const handleDeleteModuleObjects = async(id) => {
		await http.delete(`${window.__endpoint}/cha/module_objects/delete?module_object_id=${id}`)
		handleListModuleObjects(filtersValues)
	}
	const handleCreateModuleObjects = async(data) => {
		await http.post(`${window.__endpoint}/cha/module_objects/create`, {
			refer_type_id: 70, // Liste valori
			refer_id: id, // ID Lista valore
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModuleObjects(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea lista' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'lista'}`}`}>
			<ListEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		<br />
		{!isNew && <>
			{(!editItem.user_visibility_flag || (editItem.user_visibility_flag && editItem.user_visibility_flag != 1)) && <>
				<Card title="Elenco linee">
					<ListValues
						defaultFiltersValues={defaultFiltersValues}
						filtersValues={filtersValues}
						items={items}
						onList={handleListListLines}
						onDelete={handleDeleteListLine}
						onSubmit={handleSaveListLine}
					/>
				</Card>
				<br />
			</>}
			<Card title="Assegnazione ruoli">
				<ListRoleObjects
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={roleObjects}
					onList={handleListRoleObjects}
					onDelete={handleDeleteRoleObject}
					onSubmit={handleCreateRoleObject}
					list_id={id}
				/>
			</Card>
			<br />
			<Card title="Assegnazione moduli">
				<ListModuleObjects
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={moduleObjects}
					onList={handleListModuleObjects}
					onDelete={handleDeleteModuleObjects}
					onSubmit={handleCreateModuleObjects}
					list_id={id}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
