import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as DocumentsTransactionCausals } from '../modules/DocumentsTransactionCausals'
import { default as TransactionCausals } from '../modules/TransactionCausals'
import { default as SystemItems } from '../modules/SystemItems'

function App() {
	return <Routes>
		<Route path="transaction-causals/*" element={<TransactionCausals />} />
		<Route path="documents-transaction-causals/*" element={<DocumentsTransactionCausals />} />
		<Route path="system-items/*" element={<SystemItems />} />

	</Routes>
}

export default App
