import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'

function List({ defaultFiltersValues, items, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			items={items}
			fieldId='report_id'
			columns={[
				{ field: 'report_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'description', header: 'Descrizione', sortable: true },
				{ field: 'active_state', header: 'Stato', sortable: true, body: ({ active_state }) => {
					switch (Number(active_state)) {
						case 0: return <Tag severity="danger" value="Non attivo" />
						case 1: return <Tag severity="success" value="Attivo" />
						case 2: return <Tag severity="info" value="Default" />
						default: return ''
					}
				} },
			]}
			envEnableButtons={{
				onEnable: onEnvEnable,
				onDisable: onEnvDisable,
			}}
			scrollHeight="calc(100vh - 23rem)"
		/>
	</React.Fragment>
}

export default List
