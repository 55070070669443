import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Modal } from 'components/Ui'
import { Edit } from '..'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, onUpdate, filtersValues, list_id, ...props }) {
	const [modalOpen, setModalOpen] = React.useState(false)
	const [editItem, setEditItem] = React.useState({})

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/environment/fls/image_types/resizings/load?image_size_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}

	return <React.Fragment>
		<ListToolbar
			{...props}
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
			}}
			label='Nuovo ridimensionamento'
		>
			<Edit
				{...props}
				data={editItem}
			/>
		</Modal>

		<ListGrid
			onDelete={onDelete}
			onUpdate={onUpdate}
			items={items}
			fieldId='image_size_id'
			columns={[
				{ field: 'image_size_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'width', header: 'Larghezza', sortable: true },
				{ field: 'height', header: 'Altezza', sortable: true },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
