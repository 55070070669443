import { MultiSelect } from 'primereact/multiselect'
import { useState } from 'react'
import labels from 'core/labels'

function SelectMulti({ all, options, optionLabel, optionValue, ...props }) {
	const { hideFilter, onChange, value } = props

	const values = value ? value.split(',') : []
	const [selectedElement, setSelectedElement] = useState(values || null)

	const allValue = all ? all.value || '' : ''
	const allLabel = all ? all.label || labels.select_value_not_selected : labels.select_value_not_selected
	const myOptions = [
		...[{ value: allValue, label: allLabel.toUpperCase() }],
		...options.map(item => {
			return {
				value: optionValue ? optionValue(item) : item.value,
				label: optionLabel ? optionLabel(item) : item.label,
			}
		})
	]

	// https://primereact.org/multiselect/
	return <MultiSelect
		value={selectedElement}
		onChange={(e) => {
			setSelectedElement(e.value)
			onChange({
				target: {
					value: e.value.join(),
					name: props.name
				}
			})
		}}
		options={myOptions}
		filter={!hideFilter}
		optionLabel="label"
		placeholder={props.placeholder || 'Seleziona...'}
		maxSelectedLabels={3}
		className="w-full"
	/>

}

export default SelectMulti
