import React from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ serviceId, editItem, onChange, onSubmit, id }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name','country_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={3}>
					<FormGroup
						name="country_id"
						label="Codice paese (country_id)"
						validationState={validator.validationState.country_id}
					>
						<InputText
							value={editItem.country_id || ''}
							onChange={onChange}
							readOnly={ id !== undefined}
						/>
					</FormGroup>
				</Col>
				<Col all={9}>
					<FormGroup
						name="name"
						label="Nome paese"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={3}>
					<FormGroup
						name="alpha3_code"
						label="Codice alpha3"
						validationState={validator.validationState.alpha3_code}
					>
						<InputText
							value={editItem.alpha3_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="numeric_code"
						label="Codice numerico"
						validationState={validator.validationState.numeric_code}
					>
						<InputText
							type="number"
							value={editItem.numeric_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="iso_code"
						label="Codice ISO"
						validationState={validator.validationState.iso_code}
					>
						<InputText
							value={editItem.iso_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={3}>
					<FormGroup
						name="cf_code"
						label="Codice CF"
						validationState={validator.validationState.cf_code}
					>
						<InputText
							value={editItem.cf_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={3}>
					<FormGroup
						name="ue_member_flag"
						label="Stato membro UE"
						validationState={validator.validationState.ue_member_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.ue_member_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
