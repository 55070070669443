import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const editRoot = 'nodes'

	return <React.Fragment>
		<ListToolbar
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={editRoot}
		/>

		<ListGridTree
			onDelete={onDelete}
			items={items}
			fieldId='node_id'
			columns={[
				{ field: 'node_id', header: 'Id' },
				{ field: 'name', header: 'Nodo' },
				{ field: 'node_type', header: 'Tipo' },
				{ field: 'node_order', header: 'Ordine' },
			]}
			editRoot={editRoot}
		/>
	</React.Fragment>
}

export default List
