import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Types } from '../modules/Types'
import Interfaces from '../modules/Interfaces'
import Drivers from '../modules/Drivers'

function App() {
	return <Routes>
		<Route path="types/*" element={<Types />} />
		<Route path="interfaces/*" element={<Interfaces />} />
		<Route path="drivers/*" element={<Drivers />} />
	</Routes>
}

export default App
