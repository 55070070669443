import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const applications = await http.get(`${window.__endpoint}/cha/applications/list?cols=application_id,name&order=name`)

		setSelectOptions({
			applications: applications.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => {
			const handleValueChangeHTML = (e) => {
				setEditItem({
					...editItem,
					[e.target.name]: e.target.value
				})
			}
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={6}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Applicazione */}
					<Col all={6}>
						<FormGroup
							name="application_id"
							label="Applicazione"
						>
							<Select
								value={editItem.application_id || ''}
								onChange={onChange}
								options={selectOptions.applications || []}
								optionValue={(o) => o.application_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Max Level */}
					<Col all={6} >
						<FormGroup
							name="max_user_level_auto_assign"
							label="Livello massimo"
							validationState={validator.validationState.max_user_level_auto_assign}
						>
							<InputText
								type="number"
								value={editItem.max_user_level_auto_assign || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Ordine */}
					<Col all={6} >
						<FormGroup
							name="val_order"
							label="Ordine"
							validationState={validator.validationState.val_order}
						>
							<InputText
								type="number"
								value={editItem.val_order || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Attivo */}
					<Col all={6} >
						<FormGroup
							name="active_flag"
							label="Attivo"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.active_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Attivo di default per ogni ambiente */}
					<Col all={6}>
						<FormGroup
							name="default_active_flag"
							label="Attivo di default per ogni ambiente (per utenti di livello 10)"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.default_active_flag == 1}
							/>
						</FormGroup>
					</Col>

				</Row>
				<Row>
					<Col all={12}>
						<FormGroup
							name="intro_text"
							label="Testo introduttivo"
						>
							<HTMLEditor
								value={editItem.intro_text || ''}
								name="intro_text"
								onChange={handleValueChangeHTML}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col all={12} sm={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								value={editItem.description || ''}
								onChange={onChange}
								rows={5}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Consenso */}
					<Col all={12} >
						<FormGroup
							name="need_user_consent_flag"
							label="Richiedi consenso utente"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.need_user_consent_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Titolo consenso Utente */}
					<Col all={12}>
						<FormGroup
							name="user_consent_title"
							label="Titolo consenso utente"
							//validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.user_consent_title || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Titolo consenso Utente */}
					<Col all={12} sm={12}>
						<FormGroup
							name="user_consent_text"
							label="Testo consenso utente"
							//validationState={validator.validationState.description}
						>
							<InputTextarea
								value={editItem.user_consent_text || ''}
								onChange={onChange}
								rows={5}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
