import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit FileTypes
import { Edit as EditScript } from '../../Components/Scripts'
import { List as ListParameters } from '../../Components/ScriptParameters'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [parameters, setParameters] = useState({})
	const defaultFiltersValues = {
		script_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListParameters(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/develop/scripts/load?script_id=${id}`)
			setEditItem(module.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/develop/scripts/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/develop/scripts/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei parametri
	const handleListParameters = async(filters) => {
		const module = await http.get(`${window.__endpoint}/develop/scripts/parameters/list`, {
			params: {
				...filters,
				order: 'val_order'
			},
		})
		setParameters(module.data)
	}
	const handleDeleteParameter = async(id) => {
		await http.delete(`${window.__endpoint}/develop/scripts/parameters/delete?script_parameter_id=${id}`)
		handleListParameters(defaultFiltersValues)
	}

	const handleSortParameters = async(filters) => {
		await http.post(`${window.__endpoint}/develop/scripts/parameters/sort`, {
			topics: filters,
			script_id: id,
		})
	}
	const handleSetParameters = parameters => {
		setParameters(parameters)
	}

	return <>
		<Card title={`${isNew ? 'Crea script' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'script'}`}`}>
			<EditScript
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Elenco parametri">
				<ListParameters
					defaultFiltersValues={defaultFiltersValues}
					items={parameters}
					onList={handleListParameters}
					onDelete={handleDeleteParameter}
					script_id={id}
					handleSort={handleSortParameters}
					handleSetItems={handleSetParameters}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
