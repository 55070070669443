import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ serviceId, editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		if (serviceId != undefined)
			loadSelectOptions()
	}, [serviceId])

	const loadSelectOptions = async() => {
		const fields = await http.get(`${window.__endpoint}/si/service_fields/list?cols=field_id,name&order=name&service_id=${serviceId}`)

		setSelectOptions({
			fields: fields.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['field_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={6}>
					<FormGroup
						name="field_id"
						label="Campo"
					>
						<Select
							value={editItem.field_id || ''}
							onChange={onChange}
							options={selectOptions.fields || []}
							optionValue={(o) => o.field_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="value"
						label="Valore del campo"
						validationState={validator.validationState.value}
					>
						<InputText
							value={editItem.value || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
