import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const editRoot = 'targets'
	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={editRoot}
		/>

		<ListGrid
			editRoot={editRoot}
			onDelete={onDelete}
			items={items}
			fieldId='notification_model_target_id'
			columns={[
				{ field: 'notification_model_target_id', header: 'Id', sortable: true },
				{ field: 'role_name', header: 'Ruolo', sortable: true },
				{ field: 'user_level', header: 'Livello' },
			]}
		/>
	</React.Fragment>
}

export default List
