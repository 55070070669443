import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { HeaderLinesSeparator } from 'components/Page'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { Edit as PhoneMessagesEdit } from '../../Components/PhoneMessageModels'
import { List as PhoneMessagesTargetsList } from '../../Components/PhoneMessageTargets'
import { List as PhoneMessagesAttachmentsList } from '../../Components/PhoneMessageAttachments'
import { List as PhoneMessagesModulesList } from '../../../../../cha/modules/Modules/Components/ModuleAssignment'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [listTargets, setListTargets] = useState([])
	const [listAttachments, setListAttachments] = useState([])
	const [listModules, setListModules] = useState([])

	const defaultFiltersValues = {
		phone_message_model_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (! isNew) {
			handleListTargets(defaultFiltersValues)
			handleListAttachments(defaultFiltersValues)
			handleListModules()
		}
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (! isNew) {
			const validity = await http.get(`${window.__endpoint}/com/phone_message_models/load?phone_message_model_id=${id}`)
			setEditItem(validity.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/com/phone_message_models/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/com/phone_message_models/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione destinatari
	const handleListTargets = async(filters) => {
		const list = await http.get(`${window.__endpoint}/com/phone_message_models/targets/list`, {
			params: filters
		})
		setListTargets(list.data)
	}
	const handleDeleteTargets = async(id) => {
		await http.delete(`${window.__endpoint}/com/phone_message_models/targets/delete?phone_message_model_target_id=${id}`)
		handleListTargets(defaultFiltersValues)
	}
	const handleSetTargetsItems = listTargets => {
		setListTargets(listTargets)
	}

	//* Gestione allegati
	const handleListAttachments = async(filters) => {
		const list = await http.get(`${window.__endpoint}/com/phone_message_models/attachments/list`, {
			params: filters
		})
		setListAttachments(list.data)
	}
	const handleSaveAttachments = async(_data) => {
		const data = {
			..._data,
			phone_message_model_id: id,
			refer_type_id: 486, // Report di stampa
		}
		if (data.phone_message_model_attachment_id == null) {
			await http.post(`${window.__endpoint}/com/phone_message_models/attachments/create`, data)
		} else {
			await http.put(`${window.__endpoint}/com/phone_message_models/attachments/update`, data)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListAttachments(defaultFiltersValues)
	}
	const handleDeleteAttachments = async(id) => {
		await http.delete(`${window.__endpoint}/com/phone_message_models/attachments/delete?phone_message_model_attachment_id=${id}`)
		handleListAttachments(defaultFiltersValues)
	}
	const handleSetAttachmentsItems = listAttachments => {
		setListAttachments(listAttachments)
	}

	const handleListModules = async() => {
		const list = await http.get(`${window.__endpoint}/cha/module_objects/list`, {
			params: {
				refer_type_id: 486, // Modelli di messaggi
				refer_id: id,
			}
		})
		setListModules(list.data)
	}
	const handleSaveModules = async(data) => {
		if (data.module_object_id == -1) {
			await http.post(`${window.__endpoint}/cha/module_objects/create`, {
				...data,
				refer_id: id,
				refer_type_id: 486,
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModules(defaultFiltersValues)
	}
	const handleDeleteModules = async(id) => {
		await http.delete(`${window.__endpoint}/cha/module_objects/delete?module_object_id=${id}`)
		handleListModules(defaultFiltersValues)
	}
	const handleSetModulesItems = listModules => {
		setListModules(listModules)
	}

	return <>
		<Card title={`${isNew ? 'Crea modello di comunicazione' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'modello di comunicazione'}`}`}>
			<PhoneMessagesEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{! isNew && <>
			<br />
			<Card title='Destinatari'>
				<PhoneMessagesTargetsList
					phone_message_model_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listTargets}
					onList={handleListTargets}
					onDelete={handleDeleteTargets}
					handleSetItems={handleSetTargetsItems}
				/>
			</Card>
			<br />
			<Card title='Allegati'>
				<PhoneMessagesAttachmentsList
					phone_message_model_id={id}
					phone_message_model={editItem}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listAttachments}
					onList={handleListAttachments}
					onSubmit={handleSaveAttachments}
					onDelete={handleDeleteAttachments}
					handleSetItems={handleSetAttachmentsItems}
				/>
			</Card>
			<br />
			<Card title='Assegnazione moduli'>
				<PhoneMessagesModulesList
					phone_message_model_id={id}
					PhoneMessages_model={editItem}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listModules}
					onList={handleListModules}
					onSubmit={handleSaveModules}
					onDelete={handleDeleteModules}
					handleSetItems={handleSetModulesItems}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
