import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMountEffect } from 'primereact/hooks'
import PrimeReact from 'primereact/api'
import Start from 'core/start/Start'

import Login from 'core/auth/Login'
import Layout from 'core/protected/Layout'
import { setLayoutConfig } from 'reducers/ui/uiSlice'

const PublicRoute = ({ children }) => {
	const { user } = useSelector(state => state.user)
	const location = useLocation()
	if (user) {
		return <Navigate to="/" replace state={{ from: location }} />
	}
	return children
}

const ProtectedRoute = ({ children }) => {
	const { user } = useSelector(state => state.user)
	const location = useLocation()
	if (!user) {
		return <Navigate to="/login" replace state={{ from: location }} />
	}
	return children
}

function App() {
	const dispatch = useDispatch()
	dispatch(setLayoutConfig({
		colorScheme: 'light',
	}))

	useMountEffect(() => {
		PrimeReact.ripple = true
	})

	return <Start>
		<Routes>
			<Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
			<Route path="*" element={<ProtectedRoute><Layout /></ProtectedRoute>} />
		</Routes>
	</Start>
}

export default App
