import React, { useState, useEffect } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import { useDispatch } from 'react-redux'
import labels from 'core/labels'
import { filter } from 'lodash'
import { Col, InputText, Row, Button } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [filters, setFilters] = useState({})
	const [name, setName] = useState('')

	const dispatch = useDispatch()

	const { onSubmit } = props

	const handleSubmit = () =>{
		onSubmit({
			name: name,
		})
		setName(null)
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>
		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
		/>
		<Row>
			<Col all={12}>
				<InputText
					value={name || ''}
					rows={4}
					onChange={(e) => setName(e.target.value)}
				/>
				<Button onClick={handleSubmit} disabled={! name}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='body_id'
			columns={[
				{ field: 'body_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
			]}
			hideBtnEdit
		/>
	</React.Fragment>
}

export default List
