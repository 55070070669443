import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ data, onSubmit }) {
	const [editItem, setEditItem] = useState(data || {})

	const onChange = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleSubmit = () => {
		onSubmit(editItem)
	}

	return <FormValidator
		onSubmit={handleSubmit}
		editItem={editItem}
		requiredFields={['val_order', 'name', 'list_value_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar hideGoBackButton />
				<Row>
					{/* Codice */}
					<Col all={4}>
						<FormGroup
							name="list_value_id"
							label="Valore"
							validationState={validator.validationState.list_value_id}
						>
							<InputText
								type="number"
								value={editItem.list_value_id || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Nome */}
					<Col all={8}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
						>
							<InputTextarea
								value={editItem.description || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Codice */}
					<Col all={6} sm={6}>
						<FormGroup
							name="list_value_code"
							label="Codice"
							validationState={validator.validationState.list_value_code}
						>
							<InputText
								value={editItem.list_value_code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Ordine */}
					<Col all={6} sm={6}>
						<FormGroup
							name="val_order"
							label="Ordine"
							validationState={validator.validationState.val_order}
						>
							<InputText
								type="number"
								value={editItem.val_order || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Codice fattura elettronica */}
					<Col all={12}>
						<FormGroup
							name="e_inv_code"
							label="Codice fattura elettronica"
							validationState={validator.validationState.e_inv_code}
						>
							<InputText
								value={editItem.e_inv_code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Label ecommerce */}
					<Col all={6} sm={6}>
						<FormGroup
							name="ecom_label"
							label="Label ecommerce"
							validationState={validator.validationState.ecom_label}
						>
							<InputText
								value={editItem.ecom_label || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* URL ecommerce */}
					<Col all={6} sm={6}>
						<FormGroup
							name="ecom_url"
							label="URL ecommerce"
							validationState={validator.validationState.ecom_url}
						>
							<InputText
								value={editItem.ecom_url || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Attivo */}
					<Col all={6}>
						<FormGroup
							name="active_flag"
							label="Attivo"
							validationState={validator.validationState.active_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.active_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Gestibile dall'utente */}
					<Col all={6}>
						<FormGroup
							name="user_visibility_flag"
							label="Visibile utente"
							validationState={validator.validationState.user_visibility_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.user_visibility_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>
		}}
	</FormValidator>
}

export default Edit
