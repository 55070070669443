import React from 'react'
import { ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues }) {

	return <React.Fragment>
		<ListToolbar
			onShowFilters={() => {}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
			hideBtnFilter
		/>

		<ListGrid
			items={items}
			fieldId='shop_id'
			columns={[
				{ field: 'shop_id', header: 'Id', sortable: true },
				{ field: 'web_url', header: 'URL erp', sortable: true },
				{ field: 'browser_title', header: 'Titolo', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
