import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { Button, Tag } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onImportIstat, onImport, onCheckIstat }) {
	const [step, setStep] = useState('start')

	const handleGotoStart = () => {
		setStep('start')
	}

	const handleImportIstat = async() => {
		onImportIstat(() => setStep('importList'))
	}

	const handleImport = async() => {
		onImport(() => setStep('importCompleted'))
	}

	const handleCheckIstat = async() => {
		onCheckIstat(() => setStep('checkIstat'))
	}

	return <React.Fragment>
		{step == 'start' && <div className='buttons-group' style={{ marginBottom: 8 }}>
			<Button onClick={handleImportIstat}>Avvia importazione dati Istat</Button>
			<Button onClick={handleCheckIstat}>Verifica codici Istat doppi</Button>
		</div>}

		{(step == 'importList' || step == 'importCompleted') && <React.Fragment>
			{step == 'importCompleted' && <p>
				<span>Importazione completata, le seguenti città non sono state aggiornate</span><br/>
			</p>}
			<ListGrid
				items={items}
				fieldId='concurrent_id'
				columns={[
					{ field: 'codice_comune_formato_alfanumerico', header: 'Codice Istat', sortable: true },
					{ field: 'denominazione_in_italiano', header: 'Città', sortable: true },
					{ field: 'codice_catastale_del_comune', header: 'Codice catastale'  },
					{ field: 'sigla_automobilistica', header: 'Provincia', sortable: true },
					{ field: 'denominazione_regione', header: 'Regione', sortable: true },
					{ field: 'action', header: 'Azione', sortable: true, body: ({ action }) => {
						switch (Number(action)) {
							case 0: return <Tag severity="danger" value="Da disattivare" />
							case 1: return <Tag severity="success" value="Da aggiungere" />
							case 2: return <Tag severity="warning" value="Da aggiornare" />
							default: return ''
						}
					} },
				]}
				hideBtnEdit
			/>
			<br/>
			{step == 'importList' && <p>
				{items.length > 0 ? <span>
					<Button onClick={handleImport}>Completa l'importazione</Button>
				</span> : <span>
					Nessuna città da aggiornare<br/>
				</span>}
			</p>}
			<p>
				<Button onClick={handleGotoStart}>Indietro</Button>
			</p>
		</React.Fragment>}

		{step == 'checkIstat' && <React.Fragment>
			<ListGrid
				items={items}
				fieldId='concurrent_id'
				columns={[
					{ field: 'codice_comune_formato_alfanumerico', header: 'Codice ISTAT', sortable: true },
					{ field: 'denominazione_in_italiano', header: 'Città', sortable: true },
					{ field: 'codice_catastale_del_comune', header: 'Codice catastale'  },
					{ field: 'sigla_automobilistica', header: 'Provincia', sortable: true },
					{ field: 'denominazione_regione', header: 'Regione', sortable: true },
					{ field: 'active_flag', header: 'Attivo', type: 'boolean', sortable: true },
				]}
				hideBtnEdit
			/>
			<p>
				<br/>
				<Button onClick={handleGotoStart}>Indietro</Button>
			</p>
		</React.Fragment>}
	</React.Fragment>
}

export default List
