import React, { useState } from 'react'
import { ListGrid, ListToolbar } from 'components/List'

function List({ region_id, defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	return <React.Fragment>
		<ListToolbar
			onShowFilters={() => {}}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='province_id'
			columns={[
				{ field: 'province_id', header: 'Id' },
				{ field: 'name', header: 'Nome' },
				{ field: 'region', header: 'Regione' },
			]}
		/>
	</React.Fragment>
}

export default List
