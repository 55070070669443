import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as SocialSecurityTaxesEdit } from '../../Components/SocialSecurityTaxes'
import { List as ValidityList } from '../../Components/SocialSecurityTaxesValidity'

function PageEdit() {
	const dispatch = useDispatch()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const navigate = useNavigate()
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}

	useEffect(() => {
		handleLoad()
		handleListSocialSecurityTaxesValidity(defaultFiltersValues)
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/act/social_security_taxes/load?social_security_tax_id=${id}`)
			setEditItem(area.data)
		}
	}

	const handleSave = async() => {
		// Esiste solo l'update
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/act/social_security_taxes/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/act/social_security_taxes/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleListSocialSecurityTaxesValidity = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/act/social_security_taxes/validities/list`, {
			params: {
				...filters,
				social_security_tax_id: id,
				// columns: 'node_id, name, node_type, node_order'
			}
		})
		setItems(list.data)
	}

	const handleSaveSocialSecurityTaxesValidity = async(data) => {
		await http.post(`${window.__endpoint}/act/social_security_taxes/validities/create`, {
			...data,
			social_security_tax_id: id,
		})
		handleListSocialSecurityTaxesValidity(filtersValues)
	}

	const handleDeleteSocialSecurityTaxesValidity = async(id) => {
		await http.delete(`${window.__endpoint}/act/social_security_taxes/validities/delete?social_security_tax_validity_id=${id}`)
		handleListSocialSecurityTaxesValidity(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea casse previdenziali' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'casse previdenziali'}`}`}>
			<SocialSecurityTaxesEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Elenco valori e validità casse previdenziali">
				<ValidityList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={items}
					onList={handleListSocialSecurityTaxesValidity}
					onDelete={handleDeleteSocialSecurityTaxesValidity}
					onChange={handleChangeEditItem}
					onSubmit={handleSaveSocialSecurityTaxesValidity}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
