import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import { useNavigate } from 'react-router-dom'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, field_id, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => {
				navigate(`/int/drivers/fields/code-variations/create?driver_field_id=${field_id}`)
			}}
		/>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='keys'
			columns={[
				{ field: 'condition_driver_field_name', header: 'Campo di differenziazione' },
				{ field: 'condition_value', header: 'Valore condizione' },
				{ field: 'field_code', header: 'Codice campo' },
			]}
			onEdit={txt => {
				const keys = txt.split(',')
				navigate(`/int/drivers/fields/code-variations/edit?driver_field_id=${keys[0]}&condition_driver_field_id=${keys[1]}&condition_value=${keys[2]}`)
			}}
		/>
	</React.Fragment>
}

export default List
