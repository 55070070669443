import React from 'react'
import { Button } from 'components/Ui'
import { useNavigate } from 'react-router-dom'

function EditToolbar({ ...props }) {
	const navigate = useNavigate()
	const { customActions } = props

	return <div className='buttons-group' style={{ marginBottom: 8 }}>
		{!props.hideGoBackButton && <Button tooltip="Indietro" icon="pi pi-angle-double-left" onClick={() => navigate(-1)} />}
		{!props.readOnly && <Button tooltip="Salva" icon="pi pi-save" type="submit" />}
		{customActions && customActions.map((action, index) => (
			<Button key={index} tooltip={action.tooltip} icon={action.icon} onClick={action.onClick} disabled={action.disabled} type={action.type || 'button'}/>
		))}
	</div>
}

export default EditToolbar
