import React, { useState } from 'react'
import { Tag } from 'components/Ui'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import { Modal } from 'components/Ui'
import { Edit } from '..'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [modalOpen, setModalOpen] = React.useState(false)

	const onSubmit = (data) => {
		props.onSubmit(data)
		setModalOpen(false)
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => {
				setModalOpen(true)
			}}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => setModalOpen(false)}
			label={'Nuova integrazione d\'ambiente'}
		>
			<Edit
				{...props}
				onSubmit={onSubmit}
			/>
		</Modal>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='env_service_id'
			columns={[
				{ field: 'env_service_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'org_name', header: 'Organizzazione', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
