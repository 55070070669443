import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ category_id, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)

		setSelectOptions({
			modules: modules.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'code', 'module_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Codice */}
					<Col all={12}>
						<FormGroup
							name="code"
							label="Codice"
							validationState={validator.validationState.code}
						>
							<InputText
								value={editItem.code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Modulo di riferimento */}
					<Col all={6}>
						<FormGroup
							name="module_id"
							label="Modulo di riferimento"
							validationState={validator.validationState.module_id}
						>
							<Select
								value={editItem.module_id || ''}
								onChange={onChange}
								options={selectOptions.modules || []}
								optionValue={(o) => o.module_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Attivo */}
					<Col all={6}>
						<FormGroup
							name="active_flag"
							label="Categoria attiva"
							validationState={validator.validationState.active_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.active_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								rows={5}
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
