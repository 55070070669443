import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import OrderTypes from '../modules/orderTypes'

function App() {
	return <Routes>
		<Route path="order-types/*" element={<OrderTypes />} />

	</Routes>
}

export default App
