import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ report_id, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const field_types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,name&list_id=${86}`)
		const fields = await http.get(`${window.__endpoint}/rep/reports/fields/list?cols=report_field_id,field_name&report_id=${report_id}&order=field_name`)

		setSelectOptions({
			field_types: field_types.data,
			fields: fields.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['column_name', 'field_name', 'field_type']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* N. di decimali */}
					<Col all={4}>
						<FormGroup
							name="field_order"
							label="Ordinamento"
							validationState={validator.validationState.field_order}
						>
							<InputText
								type="number"
								value={editItem.field_order || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Nome campo nella query */}
					<Col all={4}>
						<FormGroup
							name="field_name"
							label="Nome campo nella query"
							validationState={validator.validationState.field_name}
						>
							<InputText
								value={editItem.field_name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Intestazione colonna */}
					<Col all={4}>
						<FormGroup
							name="column_name"
							label="Intestazione colonna"
							validationState={validator.validationState.column_name}
						>
							<InputText
								value={editItem.column_name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Tipo campo */}
					<Col all={2}>
						<FormGroup
							name="field_type"
							label="Tipo campo"
							validationState={validator.validationState.field_type}
						>
							<Select
								value={editItem.field_type || ''}
								onChange={onChange}
								options={selectOptions.field_types || []}
								optionValue={(o) => o.list_value_code}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* N. di decimali */}
					<Col all={2}>
						<FormGroup
							name="decimals"
							label="N. di decimali"
							validationState={validator.validationState.decimals}
						>
							<InputText
								type="number"
								value={editItem.decimals || ''}
								onChange={onChange}
								disabled={editItem.field_type != 'money' && editItem.field_type != 'float'}
							/>
						</FormGroup>
					</Col>
					{/* Link */}
					<Col all={4}>
						<FormGroup
							name="link"
							label="Link"
							validationState={validator.validationState.link}
						>
							<InputText
								value={editItem.link || ''}
								onChange={onChange}
								disabled={editItem.field_type != 'link'}
							/>
						</FormGroup>
						<small>Inserire il link al quale far puntare ciascun record. Per inserire il valore ricavato dalla colonna inserire {'`{FIELD}`'}</small>
					</Col>
					{/* Larghezza colonna */}
					<Col all={4}>
						<FormGroup
							name="width"
							label="Larghezza colonna"
							validationState={validator.validationState.width}
						>
							<InputText
								type="number"
								value={editItem.width || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Attivo */}
					<Col all={2}>
						<FormGroup
							name="enable"
							label="Attivo"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.enable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Visibile */}
					<Col all={2}>
						<FormGroup
							name="visible"
							label="Visibile"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.visible == 1}
							/>
						</FormGroup>
					</Col>
					{/* Ordinabile */}
					<Col all={2}>
						<FormGroup
							name="sortable"
							label="Ordinabile"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.sortable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Somma */}
					<Col all={2}>
						<FormGroup
							name="summable"
							label="Somma"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.summable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Raggruppa */}
					<Col all={2}>
						<FormGroup
							name="groupable"
							label="Raggruppa"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.groupable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Stampa */}
					<Col all={2}>
						<FormGroup
							name="printable"
							label="Stampa"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.printable == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Forza testo a capo */}
					<Col all={12}>
						<FormGroup
							name="word_break_flag"
							label="Forza testo a capo"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.word_break_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Campo padre  */}
					<Col all={6}>
						<FormGroup
							name="parent_id"
							label="Campo padre"
							validationState={validator.validationState.parent_id}
						>
							<Select
								value={editItem.parent_id || ''}
								onChange={onChange}
								options={selectOptions.fields || []}
								optionValue={(o) => o.report_field_id}
								optionLabel={(o) => o.field_name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Campo visibile nel sommario */}
					<Col all={6}>
						<FormGroup
							name="summary"
							label="Campo visibile nel sommario"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.summary == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
