import { Dialog } from 'primereact/dialog'
import React from 'react'

function Modal({ children, label, open, onClose, onOpen, width, ...props }) {
	return <Dialog
		visible={open}
		style={{ width: width || '32rem' }}
		breakpoints={{ '960px': '75vw', '641px': '90vw' }}
		header={label || ''}
		modal
		className="p-fluid"
		onHide={onClose}
		onShow={onOpen}
		{...props}
	>
		{children}
	</Dialog>
}

export default Modal
