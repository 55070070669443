import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as PortalsList } from '../../Components/Portal'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context as ServerContext } from 'apps/environment/context'

function PageList() {
	const [items, setItems] = React.useState([])
	const [filtersValues, setFiltersValues] = React.useState({})
	const [editItem, setEditItem] = React.useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)
	const { selectedServer, selectedEnvironment } = useContext(ServerContext)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer, selectedEnvironment])

	const handleValueChange = (e) => {
		setEditItem({
			...editItem,
			[e.target.name]: e.target.value
		})
	}

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/bm/portals/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleCreate = async() => {
		await http.post(`${window.__endpoint}/environment/bm/portals/create`, editItem)
		handleList(filtersValues)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/environment/bm/portals/delete?portal_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title="Portali">
		<PortalsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
			editItem={editItem}
			onChange={handleValueChange}
			onSubmit={handleCreate}
		/>
	</Card>
}

export default PageList
