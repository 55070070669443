import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Checkbox } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['payment_method']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Metodo di pagamento */}
					<Col all={6}>
						<FormGroup
							name="payment_method"
							label="Metodo di pagamento"
							validationState={validator.validationState.payment_method}
						>
							<InputText
								value={editItem.payment_method || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Online */}
					<Col all={3}>
						<FormGroup
							name="online_flag"
							label="Online"
							validationState={validator.validationState.online_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.online_flag == 1}
							/>
						</FormGroup>
					</Col>
					{/* Conferma utente */}
					<Col all={3}>
						<FormGroup
							name="customer_confirmation_flag"
							label="Conferma utente"
							validationState={validator.validationState.customer_confirmation_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.customer_confirmation_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
