import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})

	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)

		setSelectOptions({
			modules: modules.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar hideGoBackButton/>
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome gruppo"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="module_id"
						label="Modulo associato"
					>
						<Select
							value={editItem.module_id || ''}
							onChange={onChange}
							options={selectOptions.modules || []}
							optionValue={(o) => o.module_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
