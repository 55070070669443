import { React, useState, useEffect } from 'react'
import { Col, Checkbox, FormGroup, Select, InputTextarea, Row, InputText } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ refer_type_id, refer_id, lang_id, editItem, setEditItem, onChange, onSubmit, isNew }) {
	const [selectedOptions, setSelectOptions] = useState([])
	const [item, setItem] = useState([])

	useEffect(() => {
		loadSelectedOptions()
		loadItem()
	}, [])

	const loadItem = async() => {
		const curItem = await http.get(`${window.__endpoint}/cha/referement_types/get/load`, {
			params: { refer_type_id: refer_type_id, refer_id: refer_id }
		})
		setItem(curItem.data)
	}

	const loadSelectedOptions = async() => {
		let langs
		if (isNew){
			langs = await http.get(`${window.__endpoint}/cha/referement_type_texts_translations/listUnusedLangs?cols=lang_id,name&order=name&refer_id=${refer_id}&refer_type_id=${refer_type_id}`)

		} else {
			langs = await http.get(`${window.__endpoint}/cha/text_translations/languages?cols=lang_id,name&order=name`)

		}
		setSelectOptions({
			langs: langs.data
		})
	}
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['language_id', 'name']}
	>
		{(validator) => {

			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Lingua di traduzione */}
					<Col all={12}>
						<FormGroup
							name="language_id"
							label="Lingua di traduzione"
							validationState={validator.validationState.language_id}
						>
							<Select
								disabled={!isNew}
								value={lang_id || editItem.language_id || ''}
								onChange={onChange}
								options={selectedOptions.langs || []}
								optionValue={(o) => o.lang_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Nome originale */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome originale"
						>
							<InputText
								disabled
								value={item.name || ''}
								//onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Nome tradotto */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome tradotto"
							validationState={validator.validationState.name}

						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione tradotta */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
						>
							<InputTextarea
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
