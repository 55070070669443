import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as EnvironmentsList } from '../../Components/Environments'
import { Context } from 'apps/server/context'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { selectedServer } = useContext(Context)

	useEffect(() => {
		handleList(defaultFiltersValues)
	}, [selectedServer])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/server/cha/environments/list?cols=env_id,env,name,offline_flag&order=name`, {
			params: filters
		})
		setItems(list.data)
	}

	return <Card title="Ambienti">
		<EnvironmentsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
		/>
	</Card>
}

export default PageList
