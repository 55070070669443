import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import http from 'axios'
import { Card } from 'components/Ui'

// Components
import { Edit as EditService } from '../../Components/Services'
import { List as ListFieldData } from '../../Components/ServiceFieldsData'
import { List as ListTransactions } from '../../Components/ServiceTransactions'

function PageEdit() {
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [listFieldsData, setListFieldsData] = useState([])
	const [listTransactions, setListTransactions] = useState([])

	const defaultFiltersValues = {
		env_service_id: id,
	}

	useEffect(() => {
		handleLoad()
		handleListFieldsData(defaultFiltersValues)
		handleListTransactions(defaultFiltersValues)
	}, [id])

	//* Load service
	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/environment/si/services/load?env_service_id=${id}`)
			setEditItem(service.data)
		}
	}

	//* Gestione dei campi ambiente
	const handleListFieldsData = async(filters) => {
		const list = await http.get(`${window.__endpoint}/environment/si/service_fields_data/list`, {
			params: filters
		})
		setListFieldsData(list.data)
	}
	const handleDeleteFieldsData = async(id) => {
		await http.delete(`${window.__endpoint}/environment/si/service_fields_data/delete?service_field_id=${id}`)
		handleListFieldsData(defaultFiltersValues)
	}
	const handleSetFieldsDataItems = listFieldsData => {
		setListFieldsData(listFieldsData)
	}

	//* Gestione delle transazione/audit log
	const handleListTransactions = async(filters) => {
		const list = await http.get(`${window.__endpoint}/environment/si/transactions/list`, {
			params: filters
		})
		setListTransactions(list.data)
	}

	const handleSetTransactionsItems = listTransactions => {
		setListTransactions(listTransactions)
	}

	return <>
		<Card title={`${isNew ? 'Crea servizio d\'integrazione' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'servizio d\'integrazione'}`} per l'ambiente`}>
			<EditService
				readOnly
				editItem={editItem}
			/>
		</Card>
		{id > 0 && <>
			<br />
			<Card title="Modifica campi">
				<ListFieldData
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listFieldsData}
					onList={handleListFieldsData}
					onDelete={handleDeleteFieldsData}
					handleSetItems={handleSetFieldsDataItems}
				/>
			</Card>
			<br />
			<Card title="Log delle transazioni/chiamate">
				<ListTransactions
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listTransactions}
					onList={handleListTransactions}
					handleSetItems={handleSetTransactionsItems}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
