import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListGrid, ListToolbar } from 'components/List'
import { useNavigate } from 'react-router-dom'
import { Tag } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, text_id }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()
	const [languages, setLanguages] = useState([])

	const editRoot = 'traslations'
	const handleLoadLanguages = async() =>{
		const response = await http.get(`${window.__endpoint}/cha/text_translations/languages?cols=code,name`)

		const languages = response.data.map((item) => {
			return {
				value: item.lang,
				label: item.name
			}
		})

		setLanguages(languages)
	}

	useEffect(() => {
		handleLoadLanguages()
	}, [])

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
				{
					type: 'select',
					label: 'Lingua',
					name: 'lang',
					options: languages
				}
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={editRoot}
			customAdd={() => {
				navigate(`/ecom/texts/translation/create?text_id=${text_id}`)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='lang'
			columns={[
				{ field: 'lang', header: 'Lingua', sortable: true },
				{ field: 'text', header: 'Testo', sortable: true },
			]}
			//editRoot={editRoot}
			onEdit={(lang) => {
				navigate(`/ecom/texts/translations/edit?text_id=${text_id}&lang=${lang}`)
			}}
		/>
	</React.Fragment>
}

export default List