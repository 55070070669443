import React, { useEffect, useState } from 'react'
import { Col, Checkbox, ColorPicker, FormGroup, InputGroup, InputText, InputTextarea, MessageBox, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar, SectionSeparator } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit, ...props }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const smtpSecure = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,name&list_id=25`)

		setSelectOptions({
			smtpSecure: smtpSecure.data,
		})
	}

	const customActions = []

	customActions.push({
		label: 'Prova SMTP',
		tooltip: 'Prova SMTP',
		icon: 'pi pi-envelope',
		onClick: props.sendEmailToApps,
	})

	if (! props.envIsOk.ok) {
		customActions.push({
			label: 'Prepara ambiente',
			tooltip: 'Prepara ambiente',
			icon: 'pi pi-wrench',
			onClick: props.handlePrepareEnvironment,
			disabled: props.envIsOk.disabled,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'env', 'db_name', 'web_erp_protocol', 'web_erp_url', 'srv_url', 'browser_title', 'permission_type']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar customActions={customActions}/>
			<Row>
				<Col all={12} sm={6}>
					<FormGroup
						name="offline_flag"
						label="Imposta l'ambiente offline"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.offline_flag == 1}
						/>
					</FormGroup>
				</Col>
				{editItem.offline_flag == 1 && <Col all={12} sm={6}>
					<MessageBox
						severity="error"
						text="L'ambiente è offline"
					/>
				</Col>}
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="env"
						label="Sigla ambiente"
						validationState={validator.validationState.env}
					>
						<InputText
							value={editItem.env || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="db_name"
						label="Database"
						validationState={validator.validationState.db_name}
					>
						<InputText
							value={editItem.db_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<SectionSeparator title="Url" />

			<Row>
				<Col all={12} md={6}>
					<FormGroup
						name="web_erp_protocol"
						label="Protocollo erp"
						validationState={validator.validationState.web_erp_protocol}
					>
						<InputText
							value={editItem.web_erp_protocol || ''}
							onChange={onChange}
							placeholder="http | https"
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12} md={6}>
					<FormGroup
						name="web_erp_url"
						label="Url erp"
						validationState={validator.validationState.web_erp_url}
					>
						<InputText
							value={editItem.web_erp_url || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="pwa_erp_url"
						label="Url pwa"
						validationState={validator.validationState.pwa_erp_url}
					>
						<InputText
							value={editItem.pwa_erp_url || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="srv_url"
						label="Url servizi (con https)"
						validationState={validator.validationState.srv_url}
					>
						<InputText
							value={editItem.srv_url || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="socket_url"
						label="Url socket notifiche (con https)"
						validationState={validator.validationState.socket_url}
					>
						<InputText
							value={editItem.socket_url || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<SectionSeparator title="Configurazione" />

			<Row>
				<Col all={12} sm={6}>
					<FormGroup
						name="user_show_registration"
						label="Abilita registrazione utente"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_show_registration == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="user_show_pwd_recover"
						label="Abilita recupera password"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_show_pwd_recover == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="powered_by_text"
						label="Testo powered by"
						validationState={validator.validationState.powered_by_text}
					>
						<InputText
							value={editItem.powered_by_text || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="powered_by_link"
						label="Link powered by"
						validationState={validator.validationState.powered_by_link}
					>
						<InputText
							value={editItem.powered_by_link || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="browser_title"
						label="Titolo del browser"
						validationState={validator.validationState.browser_title}
					>
						<InputText
							value={editItem.browser_title || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="permission_type"
						label="Tipologia di permessi"
						validationState={validator.validationState.permission_type}
					>
						<Select
							value={editItem.permission_type || ''}
							onChange={onChange}
							options={[
								{ value: 'm', label: 'Moduli' },
								{ value: 'p', label: 'Piani' },
							]}
						/>
					</FormGroup>
				</Col>
				{editItem.permission_type == 'p' && <React.Fragment>
					<Col all={12}>
						<FormGroup
							name="plans_title"
							label="Titolo pagina piani"
						>
							<InputText
								value={editItem.plans_title || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12}>
						<FormGroup
							name="plans_description"
							label="Descrizione pagina piani"
						>
							<InputTextarea
								value={editItem.plans_description || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} md={6}>
						<FormGroup
							name="free_trial_days"
							label="Giorni di prova"
							type="number"
						>
							<InputText
								value={editItem.free_trial_days || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} md={6}>
						<FormGroup
							name="plans_vat"
							label="Iva da sommare ai prezzi del piano"
							type="number"
						>
							<InputGroup
								afterAddOn="%"
							>
								<InputText
									value={editItem.plans_vat || ''}
									onChange={onChange}
								/>
							</InputGroup>
						</FormGroup>
					</Col>
					<Col all={12} md={6}>
						<FormGroup
							name="plans_duration_months"
							label="Mesi di durata del piano"
							type="number"
						>
							<InputText
								value={editItem.plans_duration_months || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} md={6}>
						<FormGroup
							name="plans_expiration_reminder_days"
							label="Giorni mancanti dalla scadenza del piano per promemoria"
							type="number"
						>
							<InputText
								value={editItem.plans_expiration_reminder_days || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</React.Fragment>}
			</Row>

			{editItem.permission_type == 'p' && <React.Fragment>
				<SectionSeparator title="Pagina contatti" />
				<Row>
					<Col all={12}>
						<FormGroup
							name="application_contact_company"
							label="Ragione sociale"
						>
							<InputText
								value={editItem.application_contact_company || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} sm={6}>
						<FormGroup
							name="application_contact_address1"
							label="Indirizzo"
						>
							<InputText
								value={editItem.application_contact_address1 || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} sm={6}>
						<FormGroup
							name="application_contact_address2"
							label="Indirizzo (riga aggiuntiva)"
						>
							<InputText
								value={editItem.application_contact_address2 || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} sm={6}>
						<FormGroup
							name="application_contact_phone"
							label="Telefono"
						>
							<InputText
								value={editItem.application_contact_phone || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} sm={6}>
						<FormGroup
							name="application_contact_email"
							label="Email"
						>
							<InputText
								value={editItem.application_contact_email || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} sm={6}>
						<FormGroup
							name="application_contact_fb"
							label="Link Facebook"
						>
							<InputText
								value={editItem.application_contact_fb || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} sm={6}>
						<FormGroup
							name="application_contact_twitter"
							label="Link Twitter"
						>
							<InputText
								value={editItem.application_contact_twitter || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12} sm={6}>
						<FormGroup
							name="application_contact_ln"
							label="Link LinkedIn"
						>
							<InputText
								value={editItem.application_contact_ln || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12}>
						<FormGroup
							name="application_contact_slogan"
							label="Slogan"
						>
							<InputTextarea
								value={editItem.application_contact_slogan || ''}
								onChange={onChange}
								rows={4}
							/>
						</FormGroup>
					</Col>
				</Row>

				<SectionSeparator title="PayPal" />
				<Row>
					<Col all={12}>
						<FormGroup
							name="paypal_token"
							label="Token"
						>
							<InputText
								value={editItem.paypal_token || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={12}>
						<FormGroup
							name="paypal_secret"
							label="Secret token"
						>
							<InputText
								value={editItem.paypal_secret || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<SectionSeparator title="Informative registrazione utenti (per piani)" />
				<Row>
					{/* Informativa privacy */}
					<Col all={12}>
						<FormGroup
							name="privacy_info_text"
							label="Informativa privacy"
						>
							<HTMLEditor
								value={editItem.privacy_info_text || ''}
								name="privacy_info_text"
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Termini e condizioni */}
					<Col all={12}>
						<FormGroup
							name="tac_info_text"
							label="Termini e condizioni"
						>
							<HTMLEditor
								value={editItem.tac_info_text || ''}
								name="tac_info_text"
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</React.Fragment>}

			<SectionSeparator title="Smtp" />
			<Row>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_smtp_server"
						label="Server"
					>
						<InputText
							value={editItem.mail_smtp_server || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_smtp_port"
						label="Porta (587/465)"
					>
						<InputText
							value={editItem.mail_smtp_port || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_smtp_user"
						label="User"
					>
						<InputText
							value={editItem.mail_smtp_user || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_smtp_password"
						label="Password"
					>
						<InputText
							type="password"
							value={editItem.mail_smtp_password || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_smtp_secure"
						label="Protocollo"
					>
						<Select
							value={editItem.mail_smtp_secure || ''}
							onChange={onChange}
							options={selectOptions.smtpSecure || []}
							optionValue={(o) => o.list_value_code}
							optionLabel={(o) => o.name}
							all={{
								label: 'Radice'
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_smtp_charset"
						label="Charset"
					>
						<InputText
							value={editItem.mail_smtp_charset || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_from_mail"
						label="Mail mittente"
					>
						<InputText
							value={editItem.mail_from_mail || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="mail_from_name"
						label="Nome mittente"
					>
						<InputText
							value={editItem.mail_from_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<SectionSeparator title="Impostazioni mail di sistema" />
			<Row>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_subject_prefix"
						label="Prefisso dell'oggetto"
					>
						<InputText
							value={editItem.system_mail_subject_prefix || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_body_bg"
						label="Colore sfondo del body"
					>
						<ColorPicker
							addHash
							value={editItem.system_mail_body_bg || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_body_color"
						label="Colore testo del body"
					>
						<ColorPicker
							addHash
							value={editItem.system_mail_body_color || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_body_link_color"
						label="Colore link del body"
					>
						<ColorPicker
							addHash
							value={editItem.system_mail_body_link_color || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_head_bg"
						label="Colore sfondo dell'intestazione"
					>
						<ColorPicker
							addHash
							value={editItem.system_mail_head_bg || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_header_color"
						label="Colore testo dell'intestazione"
					>
						<ColorPicker
							addHash
							value={editItem.system_mail_header_color || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_footer_bg"
						label="Colore sfondo del footer"
					>
						<ColorPicker
							addHash
							value={editItem.system_mail_footer_bg || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="system_mail_footer_color"
						label="Colore testo del footer"
					>
						<ColorPicker
							addHash
							value={editItem.system_mail_footer_color || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="system_mail_copyright"
						label="Copyright"
					>
						<InputText
							value={editItem.system_mail_copyright || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="system_mail_signature"
						label="Firma"
						validationState={validator.validationState.system_mail_signature}
					>
						<HTMLEditor
							value={editItem.system_mail_signature || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>

			<SectionSeparator title="Funzioni aggiuntive" />
			<Row>
				<Col all={12} md={6}>
					<FormGroup
						name="checks_active_flag"
						label="Abilita controlli"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.checks_active_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="notifications_active_flag"
						label="Abilita notifiche"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.notifications_active_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="help_active_flag"
						label="Abilita help"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.help_active_flag == 1}
						/>
					</FormGroup>
				</Col>
				{editItem.help_active_flag == 1 && <>
					<Col all={6}>
						<FormGroup
							name="help_url"
							label="URL esterno help"
						>
							<InputText
								value={editItem.help_url || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={6}>
						<FormGroup
							name="help_app_title"
							label="Titolo URL interno help"
						>
							<InputText
								value={editItem.help_app_title || ''}
								onChange={onChange}
								disabled={editItem.help_url != ''}
							/>
						</FormGroup>
					</Col>
					<Col all={6}>
						<FormGroup
							name="help_footer"
							label="Footer help interno"
						>
							<InputText
								value={editItem.help_footer || ''}
								onChange={onChange}
								disabled={editItem.help_url != ''}
							/>
						</FormGroup>
					</Col>
				</>}
			</Row>
			<Row>
				<Col all={12} md={6}>
					<FormGroup
						name="cron_flag"
						label="Abilita cronjob"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.cron_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="service_flag"
						label="Abilita script di manutenzione e purge"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.service_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					{editItem.service_flag == 1 && <FormGroup
						name=""
						label="Ultima esecuzione manutenzione e purge"
					><span>{editItem.service_last_execution}</span></FormGroup>}
				</Col>
				<Col all={12} md={6}>
					<FormGroup
						name="backup_flag"
						label="Abilita backup file d'ambiente"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.backup_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} md={6}>
					{editItem.backup_flag == 1 && <FormGroup
						name=""
						label="Ultima esecuzione backup"
					><span>{editItem.backup_last_execution}</span></FormGroup>}
				</Col>
				{editItem.backup_flag == 1 && <Col all={12} md={6}>
					<FormGroup
						name="backup_bucket"
						label="Bucket AWS per il backup"
					>
						<InputText
							value={editItem.backup_bucket || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>}
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
