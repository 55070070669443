import React from 'react'
import { Checkbox, FormGroup, InputText, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { useDispatch } from 'react-redux'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'

const MassiveEdit = ({
	fields,
	...props
}) => {
	const dispatch = useDispatch()
	const [filter, setFilter] = React.useState({})
	const [editingFields, setEditingFields] = React.useState(fields || [])
	const [selectOptions, setSelectOptions] = React.useState({})

	React.useEffect(() => {
		loadSelectOptions()
		handleCheckFieldsWithFilter()
	}, [])

	const loadSelectOptions = async() => {
		const field_types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,name&list_id=${86}`)

		setSelectOptions({
			field_types: field_types.data,
		})
	}

	const handleCheckFieldsWithFilter = () => {
		let tmp = { ...filter }
		listGrid.forEach((element, k) => {
			// If all the fields in the list are checked, I will check the filter
			const allFields = editingFields
			const allFieldsChecked = allFields.filter((field) => field[element.name] == 1).length == allFields.length

			// Lo stato non può essere modificato direttamente, quindi creo un oggetto temporaneo e lo assegno alla fine
			tmp = {
				...tmp,
				[element.name]: allFieldsChecked ? 1 : 0
			}
		})

		setFilter(tmp)
	}

	const onChange = (e) => {
		setFilter({
			...filter,
			[e.target.name]: e.target.checked
		})

		// Because I only edit filters that are checkbox on the top, I will also massively edit the elements of the list given checked 1 | 0

		editingFields.forEach((field, k) => {
			const row = field

			row[e.target.name] = Number(e.target.checked)

			const allFields = [...editingFields]
			allFields[k] = row

			setEditingFields(allFields)
		})
	}

	const handleEditingFieldValueChange = (e, row_id) => {
		// I have a specific row I am editing, and I will change the state at that row
		const editingRow = editingFields[row_id]

		// Update the value of the field based on the event target's name
		editingRow[e.target.name] = (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value)

		// Create a new array with the updated editing field at the specified row
		const updatedEditingFields = [...editingFields]
		updatedEditingFields[row_id] = editingRow

		// Update the state with the new array of editing fields
		setEditingFields(updatedEditingFields)
	}

	const handleSaveFields = async(e) => {
		// Evito il postback del form
		e.preventDefault()
		// Evito la propagazione del submit nel caso di form annidati
		e.stopPropagation()

		await http.put(`${window.__endpoint}/rep/reports/fields/saveMassive`, {
			report_id: props.report_id,
			fields: editingFields
		})

		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))

		props.onSaveCompleted()

		return false
	}

	const listGrid = [
		{ name: 'field_order', label: 'Ordine', type: 'number' },
		{ name: 'field_type', label: 'Tipologia colonna', type: 'select' },
		{ name: 'column_name', label: 'Nome colonna', type: 'text' },
		{ name: 'enable', label: 'Abilitato', type: 'boolean' },
		{ name: 'visible', label: 'Visibile', type: 'boolean' },
		{ name: 'sortable', label: 'Ordine', type: 'boolean' },
		{ name: 'summable', label: 'Sommabile', type: 'boolean' },
		{ name: 'groupable', label: 'Raggruppabile', type: 'boolean' },
		{ name: 'printable', label: 'Stampabile', type: 'boolean' },
		{ name: 'width', label: 'Larghezza colonna', type: 'number' },
	]

	return <form onSubmit={handleSaveFields}>
		<EditToolbar hideGoBackButton />
		<div className='p-datatable p-component'>
			<div className='p-datatable-wrapper'>
				<table className='p-datatable-table'>
					<thead className='p-datatable-thead'>
						<tr>
							{listGrid.map((col, k) => {
								switch (col.type) {
									case 'boolean':
										return <th key={k}>
											<FormGroup
												name={col.name}
												label={col.label}
											>
												<Checkbox
													onChange={onChange}
													checked={filter[col.name] == 1}
												/>
											</FormGroup>
										</th>
									case 'text':
									case 'select':
									case 'number':
										return <th key={k}>
											<p>{col.label}</p>
										</th>
									default:
										return <th key={k}>
											<p>{col.label}</p>
										</th>
								}
							})}
						</tr>
					</thead>
					<tbody className="p-datatable-tbody">
						{editingFields && fields.map((editingField, k) => {
							return <tr key={k}>
								{listGrid.map((col, c) => {
									switch (col.type) {
										case 'boolean':
											return <td key={c} className='p-editable-column'>
												<FormGroup
													name={col.name}
													label={' '}
												>
													<Checkbox
														onChange={(e) => handleEditingFieldValueChange(e, k)}
														checked={editingField[col.name] == 1}
													/>
												</FormGroup>
											</td>
										case 'text':
											return <td key={c} className='p-editable-column'>
												<FormGroup
													name={col.name}
												>
													<InputText
														value={editingField[col.name] || ''}
														onChange={(e) => handleEditingFieldValueChange(e, k)}
													/>
												</FormGroup>
											</td>
										case 'number':
											return <td key={c} className='p-editable-column'>
												<FormGroup
													name={col.name}
												>
													<InputText
														value={editingField[col.name] || ''}
														onChange={(e) => handleEditingFieldValueChange(e, k)}
													/>
												</FormGroup>
											</td>
										case 'select' :
											return <td key={c} className='p-editable-column'>
												<FormGroup
													name={col.name}
												>
													<Select
														value={editingField[col.name] || ''}
														onChange={(e) => handleEditingFieldValueChange(e, k)}
														options={selectOptions.field_types || []}
														optionValue={(o) => o.list_value_code}
														optionLabel={(o) => o.name}
														all={{
															label: ' - Seleziona - '
														}}
													/>
												</FormGroup>
											</td>
										default:
											return <td key={c} className='p-editable-column'>
												<FormGroup
													name={col.name}
												>
													<InputText
														value={editingField[col.name] || ''}
														onChange={(e) => handleEditingFieldValueChange(e, k)}
													/>
												</FormGroup>
											</td>
									}
								})}
							</tr>
						})}
					</tbody>
				</table>
			</div>
		</div>
	</form>
}

export default MassiveEdit