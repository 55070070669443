import React, { useEffect, useState } from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import { Edit } from '..'
import { Modal } from 'components/Ui'
import http from 'axios'

function List({ items, onDelete, onList, filtersValues, onSubmit, ...props }) {
	const [modalOpen, setModalOpen] = useState(false)
	const [editItem, setEditItem] = useState({})

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/act/social_security_taxes/validities/load?social_security_tax_validity_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}

	return <React.Fragment>
		<ListToolbar
			hideBtnFilter
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
			}}
			label='Nuovo valore validità'
		>
			<Edit
				{...props}
				data={editItem}
				onSubmit={onSubmit}
			/>
		</Modal>

		<ListGrid
			onDelete={onDelete}
			onUpdate={onSubmit}
			items={items}
			fieldId='social_security_tax_validity_id'
			columns={[
				{ field: 'social_security_tax_validity_id', header: 'Id' },
				{ field: 'value_perc', header: 'Percentuale dell\'importo' },
				{ field: 'coefficient_taxable', header: 'Valore cassa' },
				{ field: 'start_date', header: 'Inizio validità' },
				{ field: 'end_date', header: 'Fine validità' },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
