import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'area_order', 'type_id', 'icon']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
					>
						<InputTextarea
							value={editItem.description || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="area_order"
						label="Ordine"
						validationState={validator.validationState.area_order}
					>
						<InputText
							type="number"
							value={editItem.area_order || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="type_id"
						label="Tipo"
						validationState={validator.validationState.type_id}
					>
						<Select
							value={Number(editItem.type_id) || ''}
							onChange={onChange}
							options={[
								{ value: 1, label: 'Web' },
								{ value: 2, label: 'B.M.' },
							]}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="icon"
						label="Icona"
						validationState={validator.validationState.icon}
					>
						<InputGroup
							beforeAddOn={<i className={editItem.icon} />}
						>
							<InputText
								value={editItem.icon || ''}
								onChange={onChange}
							/>
						</InputGroup>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
