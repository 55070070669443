import React from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['code','name','description']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="code"
						label="Codice"
						validationState={validator.validationState.code}
					>
						<InputText
							value={editItem.code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputTextarea
							type="text"
							row={4}
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="automatic_flag"
						label="Applicazione automatica"
						validationState={validator.validationState.automatic_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.automatic_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="consider_social_security_tax_flag"
						label="Includo il contributo previdenziale nel calcolo della ritenuta"
						validationState={validator.validationState.consider_social_security_tax_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.consider_social_security_tax_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="vat_required_flag"
						label="Calcolo IVA tenendo conto anche della ritenuta"
						validationState={validator.validationState.vat_required_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.vat_required_flag == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="active_flag"
						label="Attivo"
						validationState={validator.validationState.active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
