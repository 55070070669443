import React from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ serviceId, editItem, onChange, onSubmit }) {
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'field_name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={6}>
					<FormGroup
						name="name"
						label="Intestazione colonna"
						validationState={validator.validationState.name}
					>
						<InputText
							placeholder="Token di accesso"
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="field_name"
						label="Nome campo nella query"
						validationState={validator.validationState.field_name}
					>
						<InputText
							placeholder="bearer_token"
							value={editItem.field_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="fixed_value"
						label="Valore del campo"
						validationState={validator.validationState.fixed_value}
					>
						<InputText
							value={editItem.fixed_value || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="fixed_value_sandbox"
						label="Valore del campo in modalità sandbox"
						validationState={validator.validationState.fixed_value_sandbox}
					>
						<InputText
							value={editItem.fixed_value_sandbox || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6} sm={6}>
					<FormGroup
						name="val_order"
						label="Ordine"
						validationState={validator.validationState.val_order}
					>
						<InputText
							value={editItem.val_order || ''}
							onChange={onChange}
							type="number"
						/>
					</FormGroup>
				</Col>
				{/* Richiesto */}
				<Col all={3} sm={3}>
					<FormGroup
						name="required"
						label="Richiesto"
						validationState={validator.validationState.required}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.required == 1}
						/>
					</FormGroup>
				</Col>
				{/* Modificabile dall'utente */}
				<Col all={3} sm={3}>
					<FormGroup
						name="user_flag"
						label="Configurabile dall'utente"
						validationState={validator.validationState.user_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.user_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
