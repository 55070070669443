import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import http from 'axios'
import { Modal } from 'components/Ui'
import { Edit } from '..'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [modalOpen, setModalOpen] = React.useState(false)
	const [editItem, setEditItem] = React.useState({})

	const handleEdit = async(id) => {
		const response = await http.get(`${window.__endpoint}/environment/ecom/blogs/load?blog_id=${id}`)
		setEditItem(response.data)
		setModalOpen(true)
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => setModalOpen(true)}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setEditItem({})
			}}
			label='Nuovo blog'
		>
			<Edit
				{...props}
				data={editItem}
			/>
		</Modal>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='blog_id'
			columns={[
				{ field: 'blog_id', header: 'Id' },
				{ field: 'name', header: 'Nome blog' },
				{ field: 'shop_name', header: 'Nome negozio' },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
