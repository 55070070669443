import React, { useEffect, useState } from 'react'
import { Col, FormGroup, InputText, InputTextarea, Row, Checkbox } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'parameter_code']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Procedura */}
					<Col all={12}>
						<FormGroup
							name="parameter_code"
							label="Codice parametro"
							validationState={validator.validationState.parameter_code}
						>
							<InputText
								value={editItem.parameter_code || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					<Col all={4}>
						<FormGroup
							name="required_flag"
							label="Parametro obbligatorio"
							validationState={validator.validationState.required_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.required_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
