import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, HTMLEditor } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'
import { Divider } from 'primereact/divider'

function Edit({ moduleId, editItem, setEditItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const file_measures = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_code,list_value_id,name&list_id=58`)

		setSelectOptions({
			file_measures: file_measures.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
								disabled
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Estensioni */}
					<Col all={6}>
						<FormGroup
							name="extensions"
							label="Estensioni"
							validationState={validator.validationState.extensions}
						>
							<InputText
								value={editItem.extensions || ''}
								onChange={onChange}
								placeholder={editItem.default_extensions || ''}
							/>
						</FormGroup>
					</Col>
					{/* Dimensione massima file */}
					<Col all={4}>
						<FormGroup
							name="max_size"
							label="Dimensione massima file"
							validationState={validator.validationState.max_size}
						>
							<InputText
								type="number"
								value={editItem.max_size || ''}
								onChange={onChange}
								placeholder={editItem.default_max_size || ''}
							/>
						</FormGroup>
					</Col>
					{/* Unità di misura */}
					<Col all={2}>
						<FormGroup
							name="max_size_um_id"
							label={'Unità di misura'}
						>
							<Select
								value={editItem.max_size_um_id || ''}
								onChange={onChange}
								options={selectOptions.file_measures || []}
								optionValue={(o) => o.list_value_id}
								optionLabel={(o) => o.name}
								all={{
									label: `Default (${editItem.file_size_um})`
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Ridimensiona */}
					<Col all={6}>
						<FormGroup
							name="resize_flag"
							label={'Ridimensiona'}
						>
							<Select
								value={editItem.resize_flag || ''}
								onChange={onChange}
								options={[
									{ optionValue: '0', optionLabel: 'No' },
									{ optionValue: '1', optionLabel: 'Si' },
								]}
								optionValue={(o) => o.optionValue}
								optionLabel={(o) => o.optionLabel}
								all={{
									label: `Default: (${editItem.default_resize_flag == 1 ? 'si' : 'no'})`
								}}
							/>
						</FormGroup>
					</Col>
					{/* Mantieni le proporzioni */}
					{(!!Number(editItem.resize_flag) || !!Number(editItem.default_resize_flag)) && <Col all={6}>
						<FormGroup
							name="keep_ratio_flag"
							label={'Mantieni le proporzioni'}
						>
							<Select
								value={editItem.keep_ratio_flag || ''}
								onChange={onChange}
								options={[
									{ optionValue: '0', optionLabel: 'No' },
									{ optionValue: '1', optionLabel: 'Si' },
								]}
								optionValue={(o) => o.optionValue}
								optionLabel={(o) => o.optionLabel}
								all={{
									label: `Default: (${editItem.default_keep_ratio_flag == 1 ? 'si' : 'no'})`
								}}
							/>
						</FormGroup>
					</Col>}
				</Row>
				{['1', ''].includes(editItem.resize_flag) && <>
					<Row>
						<Divider />
					</Row>
					<Row>
						{/* label superiori */}
						<Col all={6}>
							<h6>Immagine orizzontale</h6>
						</Col>
						<Col all={6}>
							<h6>Immagine verticale</h6>
						</Col>
						{/* Larghezza */}
						<Col all={3}>
							<FormGroup
								name="h_width"
								label="Larghezza"
								validationState={validator.validationState.h_width}
							>
								<InputText
									type="number"
									value={editItem.h_width || ''}
									onChange={onChange}
									disabled={!Number(editItem.resize_flag)}
									placeholder={editItem.default_h_width || ''}
								/>
							</FormGroup>
						</Col>
						{/* Altezza */}
						<Col all={3}>
							<FormGroup
								name="h_height"
								label="Altezza"
								validationState={validator.validationState.h_height}
							>
								<InputText
									type="number"
									value={editItem.h_height || ''}
									onChange={onChange}
									disabled={!Number(editItem.resize_flag)}
									placeholder={editItem.default_h_height || ''}
								/>
							</FormGroup>
						</Col>
						{/* Larghezza */}
						<Col all={3}>
							<FormGroup
								name="v_width"
								label="Larghezza"
								validationState={validator.validationState.v_width}
							>
								<InputText
									type="number"
									value={editItem.v_width || ''}
									onChange={onChange}
									disabled={!Number(editItem.resize_flag)}
									placeholder={editItem.default_v_width || ''}
								/>
							</FormGroup>
						</Col>
						{/* Altezza */}
						<Col all={3}>
							<FormGroup
								name="v_height"
								label="Altezza"
								validationState={validator.validationState.v_height}
							>
								<InputText
									type="number"
									value={editItem.v_height || ''}
									onChange={onChange}
									disabled={!Number(editItem.resize_flag)}
									placeholder={editItem.default_v_height || ''}
								/>
							</FormGroup>
						</Col>
					</Row>
				</>}
			</form>}}
	</FormValidator>
}
export default Edit
