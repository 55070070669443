import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import { useNavigate } from 'react-router-dom'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, refer_type_id, refer_id, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={() => {
				navigate(`/cha/referements-translations/translations/create?refer_type_id=${refer_type_id}&refer_id=${refer_id}`)
			}}
		/>

		<ListGrid
			{...props}
			items={items}
			fieldId='language_id'
			columns={[
				{ field: 'lang_name', header: 'Lingua', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
			]}
			onEdit={(lang_id) => {
				navigate(`/cha/referements-translations/translations/edit?refer_type_id=${refer_type_id}&refer_id=${refer_id}&lang_id=${lang_id}`)
			}}
		/>
	</React.Fragment>
}

export default List
