import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { useDispatch } from 'react-redux'
import { List as ServiceList } from '../../Components/Services'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context as ServerContext } from 'apps/environment/context'

function PageList() {
	const { contextSearch } = useContext(SearchContext)
	const { selectedServer, selectedEnvironment } = useContext(ServerContext)
	const dispatch = useDispatch()
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const [editItem, setEditItem] = useState({})
	const defaultFiltersValues = {}

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer, selectedEnvironment])

	const handleValueChange = (e) => {
		setEditItem({
			...editItem,
			[e.target.name]: e.target.value
		})
	}

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/si/services/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleCreate = async() => {
		await http.post(`${window.__endpoint}/environment/si/services/create`, editItem)
		handleList(filtersValues)
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/environment/si/services/delete?env_service_id=${id}`)
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleList(filtersValues)
	}

	const handleSetItems = items => {
		setItems(items)
	}

	return <Card title="Servizi d'integrazione per ambiente">
		<ServiceList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
			handleSetItems={handleSetItems}
			editItem={editItem}
			onChange={handleValueChange}
			onSubmit={handleCreate}
		/>
	</Card>
}

export default PageList
