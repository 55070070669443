import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Services } from '../modules/Services'
import { default as Groups } from '../modules/Groups'

function App() {
	return <Routes>
		<Route path="services/*" element={<Services />} />
		<Route path="groups/*" element={<Groups />} />
	</Routes>
}

export default App
