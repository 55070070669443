import React, { useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ data, onSubmit }) {
	const [editItem, setEditItem] = useState(data || {})

	const onChange = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleSubmit = () => {
		onSubmit(editItem)
	}

	return <FormValidator
		onSubmit={handleSubmit}
		editItem={editItem}
		requiredFields={['name', 'width', 'height']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar hideGoBackButton />
				<Row>
					{/* Codice */}
					<Col all={12}>
						<FormGroup
							name="name"
							label="Nome ridimensionamento"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Nome */}
					<Col all={6}>
						<FormGroup
							name="width"
							label="Larghezza"
							validationState={validator.validationState.width}
						>
							<InputText
								type="number"
								value={editItem.width || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* height */}
					<Col all={6}>
						<FormGroup
							name="height"
							label="Altezza"
							validationState={validator.validationState.height}
						>
							<InputText
								type="number"
								value={editItem.height || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>
		}}
	</FormValidator>
}

export default Edit
