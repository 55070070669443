import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as PaymentMethods } from '../modules/PaymentMethods'

function App() {
	return <Routes>
		<Route path="payment-methods/*" element={<PaymentMethods />} />
	</Routes>
}

export default App
