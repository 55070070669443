import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const types = await http.get(`${window.__endpoint}/int/types/list?cols=type,type_id`)

		setSelectOptions({
			types: types.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name','type_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="type_id"
						label="Tipo di driver"
						validationState={validator.validationState.type_id}
					>
						<Select
							value={editItem.type_id || ''}
							onChange={onChange}
							options={selectOptions.types || []}
							optionValue={(o) => o.type_id}
							optionLabel={(o) => o.type}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="table_name"
						label="Nome tabella"
						validationState={validator.validationState.table_name}
					>
						<InputText
							value={editItem.table_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="procedure_name"
						label="Nome procedura"
						validationState={validator.validationState.procedure_name}
					>
						<InputText
							value={editItem.procedure_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="procedure_images_name"
						label="Nome immagini procedura"
						validationState={validator.validationState.procedure_images_name}
					>
						<InputText
							value={editItem.procedure_images_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
