import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { classNames } from 'primereact/utils'
import { login, setUser } from 'reducers/user/userSlice'
import { setMessage } from 'reducers/ui/uiSlice'
import md5 from 'md5'
import http from 'axios'

import { Button, InputPassword, InputText } from 'components/Ui'

function Login() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { layoutConfig, loading } = useSelector(state => state.ui)

	const containerClassName = classNames('surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden', { 'p-input-filled': layoutConfig.inputStyle === 'filled' })
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	const handleLogin = async(e) => {
		e.preventDefault()

		if (username != '' && password != '') {
			const response = await http.post(`${window.__endpoint}/oauth/user_login/login`, {
				username: username,
				password: md5(password),
			})
			dispatch(login(response.data.token))
			const user = await http.get(`${window.__endpoint}/oauth/user/load`)
			dispatch(setUser(user))
		} else {
			dispatch(setMessage({ message: { severity: 'warn', summary: 'Attenzione', detail: 'Inserire user e password' } }))
		}
	}
	return (
		<div className={containerClassName}>
			<div className="flex flex-column align-items-center justify-content-center">
				<img src={`/layout/images/logo-${layoutConfig.colorScheme === 'light' ? 'dark' : 'white'}.svg`} alt="Logo" className="mb-5 w-6rem flex-shrink-0" />
				<div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
					<div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
						<div className="text-center mb-5">
							<div className="text-900 text-3xl font-medium mb-3">Welcome!</div>
							<span className="text-600 font-medium">Sign in to continue</span>
						</div>

						<form onSubmit={handleLogin}>
							<label htmlFor="username" className="block text-900 text-xl font-medium mb-2">
								User
							</label>
							<InputText
								inputid="username"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								type="text"
								placeholder="User name"
								className="w-full md:w-30rem mb-5"
								style={{ padding: '1rem' }}
								disabled={loading > 0}
							/>

							<label htmlFor="password" className="block text-900 font-medium text-xl mb-2">
								Password
							</label>
							<InputPassword
								inputid="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="Password"
								toggleMask
								className="w-full mb-5"
								inputClassName="w-full p-3 md:w-30rem"
								disabled={loading > 0}
							/>

							<div className="flex align-items-center justify-content-between mb-5 gap-5"></div>
							<Button
								type="submit"
								label="Sign in"
								className="w-full p-3 text-xl"
								icon="pi-sign-in"
							/>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
