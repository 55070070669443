import React, { useContext, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

// * Pages
import { PageData } from './Pages'

// * Context
import { SearchContext } from 'core/contexts/SearchContext'

function Router() {
	const [contextSearch, setContextSearch] = useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageData />} />
		</Routes>
	</SearchContext.Provider>
}

export default Router
