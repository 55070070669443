import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as PortalEdit } from '../../Components/Portal'
import { ListSettings as ListBmSettings } from '../../Components/Portal'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [listBmSettings, setListBmSettings] = useState([])

	const defaultFiltersValues = {
		portal_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (id > 0) {
			handleListBmSettings(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei portali
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/environment/bm/portals/load?portal_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/environment/bm/portals/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/environment/bm/portals/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione delle impostazioni per ogni portale (bm_settings)
	const handleListBmSettings = async(filters) => {
		const list = await http.get(`${window.__endpoint}/environment/bm/portal_settings/list`, {
			params: filters
		})
		setListBmSettings(list.data)
	}
	const handleDeleteBmSettings = async(id) => {
		await http.delete(`${window.__endpoint}/environment/bm/portal_settings/delete?setting_id=${id}`)
		handleListBmSettings(defaultFiltersValues)
	}
	const handleSetBmSettingsItems = listBmSettings => {
		setListBmSettings(listBmSettings)
	}

	return <>
		<Card title={isNew ? 'Crea portale per l\'ambiente' : `Modifica ${editItem && editItem.name ? editItem.name : 'portale'}`}>
			<PortalEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Impostazioni">
				<ListBmSettings
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listBmSettings}
					onList={handleListBmSettings}
					onDelete={handleDeleteBmSettings}
					handleSetItems={handleSetBmSettingsItems}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
