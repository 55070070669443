import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Dashboard from 'core/dashboard/Dashboard'

import Act from 'apps/act/app'
import Cha from 'apps/cha/app'
import Com from 'apps/com/app'
import Ecom from 'apps/ecom/app'
import Eln from 'apps/eln/app'
import Fls from 'apps/fls/app'
import Fnc from 'apps/fnc/app'
import Help from 'apps/help/app'
import Hr from 'apps/hr/app'
import Int from 'apps/int/app'
import Inv from 'apps/inv/app'
import Prc from 'apps/prc/app'
import Pur from 'apps/pur/app'
import Rep from 'apps/rep/app'
import Rms from 'apps/rms/app'
import Sal from 'apps/sal/app'
import Si from 'apps/si/app'
import Todo from 'apps/todo/app'
import Utl from 'apps/utl/app'
import Server from 'apps/server/Server'
import Environment from 'apps/environment/Environment'
import Deploy from 'apps/deploy/Deploy'
import Develop from 'apps/develop/app'

const AppConfig = (props) => {
	return (
		<Routes>
			<Route exact path="/" element={<Dashboard />} />
			<Route path="/act/*" element={<Act />} />
			<Route path="/cha/*" element={<Cha />} />
			<Route path="/com/*" element={<Com />} />
			<Route path="/develop/*" element={<Develop />} />
			<Route path="/ecom/*" element={<Ecom />} />
			<Route path="/eln/*" element={<Eln />} />
			<Route path="/fls/*" element={<Fls />} />
			<Route path="/fnc/*" element={<Fnc />} />
			<Route path="/help/*" element={<Help />} />
			<Route path="/Hr/*" element={<Hr />} />
			<Route path="/int/*" element={<Int />} />
			<Route path="/inv/*" element={<Inv />} />
			<Route path="/prc/*" element={<Prc />} />
			<Route path="/pur/*" element={<Pur />} />
			<Route path="/rep/*" element={<Rep />} />
			<Route path="/Rms/*" element={<Rms />} />
			<Route path="/sal/*" element={<Sal />} />
			<Route path="/si/*" element={<Si />} />
			<Route path="/todo/*" element={<Todo />} />
			<Route path="/utl/*" element={<Utl />} />
			<Route path="/server/*" element={<Server />} />
			<Route path="/environment/*" element={<Environment />} />
			<Route path="/deploy/*" element={<Deploy />} />
		</Routes>
	)
}

export default AppConfig
