import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import { MassiveEdit, QueryFieldsGenerator } from '..'
import { Modal } from 'components/Ui'

function List({ report_id, defaultFiltersValues, items, onGenerateFromQuery, onCheckQueryBind, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [modalBindsOpen, setModalBindsOpen] = useState(false)
	const [binds, setBinds] = useState([])
	const editRoot = 'fields'

	const handleGenerateQuery = async() => {
		const result = await onCheckQueryBind(report_id)
		if (result.errcode == 0) {
			onGenerateFromQuery(report_id)
			return
		}

		setBinds(result.data)
		setModalBindsOpen(true)
	}

	const handleSave = async(binds) => {
		await onGenerateFromQuery(report_id, binds)
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={editRoot}
			hideBtnFilter
			customActions={[
				{
					tooltip: 'Genera da query',
					icon: 'pi pi-list',
					action: () => {
						handleGenerateQuery()
					}
				},
				{
					tooltip: 'Modifica massiva dei campi',
					icon: 'pi-sliders-h',
					action: () => {
						setModalOpen(true)
					},
					disabled: items.length == 0
				}
			]}
		/>

		{/* Modal for the massive fields handling */}
		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
			}}
			label='Modifica massiva dei campi'
			width={1250}
		>
			<MassiveEdit
				{...props}
				fields={items}
				report_id={report_id}
				onSaveCompleted={() => {
					setModalOpen(false)
					onList(filtersValues)
				}}
			/>
		</Modal>

		{/* Modal for handling fields generation */}
		<Modal
			{...props}
			open={modalBindsOpen}
			onClose={() => {
				setModalBindsOpen(false)
			}}
			label='Gestione dei bindings della query report'
		>
			<QueryFieldsGenerator
				report_id={report_id}
				binds={binds}
				onSave={handleSave}
				onSaveCompleted={() => {
					setModalBindsOpen(false)
					onList(filtersValues)
				}}
			/>
		</Modal>

		<ListGrid
			items={items}
			fieldId='report_field_id'
			columns={[
				{ field: 'report_field_id', header: 'Id', sortable: true },
				{ field: 'field_order', header: 'Ordinamento', sortable: true },
				{ field: 'field_name', header: 'Nome campo', sortable: true },
				{ field: 'field_type', header: 'Tipo campo', sortable: true },
				{ field: 'column_name', header: 'Intestazione colonna', sortable: true },
				{ field: 'enable', header: 'Abilitato', sortable: true, type: 'boolean' },
				{ field: 'visible', header: 'Visibile', sortable: true, type: 'boolean' },
				{ field: 'sortable', header: 'Ordinabile', sortable: true, type: 'boolean' },
				{ field: 'summable', header: 'Somma', sortable: true, type: 'boolean' },
				{ field: 'groupable', header: 'Raggruppa', sortable: true, type: 'boolean' },
				{ field: 'printable', header: 'Stampa', sortable: true, type: 'boolean' },
				{ field: 'width', header: 'Larghezza colonna', sortable: true },
				{ field: 'word_break_flag', header: 'Forza il testo a capo', sortable: true, type: 'boolean' },
			]}
			editRoot={editRoot}
		/>
	</React.Fragment>
}

export default List
