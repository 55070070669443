/* global process */
import React from 'react'
import ReactDOM from 'react-dom/client'
// Redux
import store from './reducers/store'
import { Provider } from 'react-redux'
// Router
import { BrowserRouter } from 'react-router-dom'
// App
import App from './App'
// Css
// import 'primereact/resources/themes/fluent-light/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import './styles/layout/layout.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))

if (process.env.NODE_ENV !== 'development') {
	window.__endpoint = 'https://api.admin.flexjump.it'
}

root.render(/* <React.StrictMode> */
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>
/* </React.StrictMode> */)
