import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List } from '../../Components/TerritoriesUpdate'
import { SearchContext } from 'core/contexts/SearchContext'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)

	useEffect(() => {
		//
	}, [])

	const handleImportIstat = async(callBack) => {
		const territories = await http.get(`${window.__endpoint}/utl/territories_update/importIstat`)
		setItems(territories.data)
		callBack()
	}

	const handleImport = async(callBack) => {
		const territories = await http.get(`${window.__endpoint}/utl/territories_update/import`)
		setItems(territories.data)
		callBack()
	}

	// Funzione per verificare i comuni con codice istat duplicato
	const handleCheckIstat = async(callBack) => {
		const territories = await http.get(`${window.__endpoint}/utl/territories_update/checkIstat`)
		setItems(territories.data)
		callBack()
	}

	return <Card title="Aggiornamento territori">
		<List
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onImportIstat={handleImportIstat}
			onImport={handleImport}
			onCheckIstat={handleCheckIstat}
		/>
	</Card>
}

export default PageList
