import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { SearchContext } from 'core/contexts/SearchContext'

// * pages
import { PageList, PageEdit, PageEditChartSeries, PageEditField } from './Pages'

function Router() {
	const [contextSearch, setContextSearch] = useState({})

	return <SearchContext.Provider value={{ contextSearch, setContextSearch }}>
		<Routes>
			<Route index element={<PageList />} />
			<Route path="create" element={<PageEdit />} />
			<Route path="edit/:id" element={<PageEdit />} />
			<Route path="edit/:id/chart-series/create" element={<PageEditChartSeries />} />
			<Route path="edit/:id/chart-series/edit/:report_chart_serie_id" element={<PageEditChartSeries />} />
			<Route path="edit/:id/fields/create" element={<PageEditField />} />
			<Route path="edit/:id/fields/edit/:field_id" element={<PageEditField />} />

		</Routes>
	</SearchContext.Provider>
}

export default Router
