import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Shops } from '../modules/Shops'

function App() {
	return <Routes>
		<Route path="shops/*" element={<Shops />} />
	</Routes>
}

export default App
