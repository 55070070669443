import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { Edit as PhoneMessageTargetsEdit } from '../../Components/PhoneMessageTargets'

function PageEditPhoneMessageTargets() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { PhoneMessageModelId, id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (! isNew) {
			const target = await http.get(`${window.__endpoint}/com/phone_message_models/targets/load?phone_message_model_target_id=${id}`)
			setEditItem(target.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/com/phone_message_models/targets/create`, {
				phone_message_model_id: PhoneMessageModelId,
				...editItem
			})
			navigate(`./../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/com/phone_message_models/targets/update`, {
				...editItem
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title="Modifica destinatari">
		<PhoneMessageTargetsEdit
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
			phone_message_model_id={PhoneMessageModelId}
		/>
	</Card>
}

export default PageEditPhoneMessageTargets
