import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as PrintReports } from '../modules/PrintReports'
import { default as Modules } from '../modules/Modules'
import { default as RegistrationApp } from '../modules/RegistrationApp'
import { default as Plans } from '../modules/Plans'
import { default as MailAccounts } from '../modules/MailAccounts'
import { default as NotificationModels } from '../modules/NotificationModels'
import { default as Concurrents } from '../modules/Concurrents'
import { default as Organizations } from '../modules/Organizations'
import PrintReportsThemes from '../modules/PrintReportsThemes'
import PrintReportsFormats from '../modules/printReportsFormats'

function App() {
	return <Routes>
		<Route path="print-reports/*" element={<PrintReports />} />
		<Route path="print-reports-themes/*" element={<PrintReportsThemes />} />
		<Route path="print-reports-formats/*" element={<PrintReportsFormats />} />
		<Route path="modules/*" element={<Modules />} />
		<Route path="registration-apps/*" element={<RegistrationApp />} />
		<Route path="plans/*" element={<Plans />} />
		<Route path="mail-accounts/*" element={<MailAccounts />} />
		<Route path="notification-models/*" element={<NotificationModels />} />
		<Route path="concurrents/*" element={<Concurrents />} />
		<Route path="organizations/*" element={<Organizations />} />
	</Routes>
}

export default App
