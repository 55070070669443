import React from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={4}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="e_inv_code"
						label="Codice Fatture elettroniche"
						validationState={validator.validationState.e_inv_code}
					>
						<InputText
							value={editItem.e_inv_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="bank_receipt_flag"
						label="Ha distinta pagamenti"
						validationState={validator.validationState.bank_receipt_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.bank_receipt_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="note"
						label="Note"
						validationState={validator.validationState.note}
					>
						<InputTextarea
							type="text"
							row={4}
							value={editItem.note || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
