import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ serviceId, editItem, onChange, onSubmit, id }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const regions = await http.get(`${window.__endpoint}/utl/regions/list?cols=region_id,name&order=name`)

		setSelectOptions({
			regions: regions.data,
		})
	}
	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['province_id','name', 'region_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={2}>
					<FormGroup
						name="province_id"
						label="Sigla"
						validationState={validator.validationState.province_id}
					>
						<InputText
							value={editItem.province_id || ''}
							onChange={onChange}
							readOnly={id !== undefined}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="region_id"
						label="Regione associata"
						validationState={validator.validationState.region_id}
					>
						<Select
							value={editItem.region_id || ''}
							onChange={onChange}
							options={selectOptions.regions || []}
							optionValue={(o) => o.region_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={2}>
					<FormGroup
						name="active_flag"
						label="Attivo"
						validationState={validator.validationState.active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
