import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Reports } from '../modules/Reports'

function App() {
	return <Routes>
		<Route path="reports/*" element={<Reports />} />
	</Routes>
}

export default App
