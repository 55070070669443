import { useSelector } from 'react-redux'
import { SplitButton as PrSplitButton } from 'primereact/splitbutton'

function SplitButton({ disabled, ...props }) {
	const { loading } = useSelector(state => state.ui)
	const isLoading = loading > 0

	return <PrSplitButton
		size="small"
		disabled={disabled || isLoading}
		{...props}
	/>
}

export default SplitButton
