import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import { useNavigate } from 'react-router-dom'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, refer_type_id, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()

	const translate = {
		onClick: (id) => navigate(`/cha/referements-translations/translations?refer_type_id=${refer_type_id}&id=${id}`),
		label: 'traduci',
		//icon: 'fas fa-language'
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
		/>

		<ListGrid
			{...props}
			hideBtnEdit
			items={items}
			fieldId='id'
			columns={[
				{ field: 'id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
			]}
			customItems={[translate]}
		/>
	</React.Fragment>
}

export default List
