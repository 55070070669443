import React, { useContext, useEffect, useState } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as ListThemes } from '../../Components/PrintReportsThemes'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context } from 'apps/environment/context'

function PageList() {
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)
	const { selectedServer, selectedEnvironment } = useContext(Context)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer, selectedEnvironment])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/cha/print_reports_themes/list?cols=theme_id,name&order=name`, {
			params: filters
		})
		setItems(list.data)
	}

	return <Card title="Temi print report per l'ambiente">
		<ListThemes
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
		/>
	</Card>
}

export default PageList
