import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import { Divider } from 'primereact/divider'

import http from 'axios'

function Edit({ report_id, editItem, setEditItem, onChange, onSubmit, fields }) {
	const [selectOptions, setSelectOptions] = useState({})
	const [enableChart, setEnableChart] = useState(false)
	useEffect(() => {
		loadSelectOptions()
	}, [])
	const loadSelectOptions = async() => {
		const refer_types = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=refer_type_id,name&order=name`)
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)
		const break_fields = await http.get(`${window.__endpoint}/rep/reports/fields/list?cols=report_field_id,column_name&order=column_name&report_id=${report_id}`)
		const chart_types = await http.get(`${window.__endpoint}/cha/list_lines/list?cols=name,list_value_code&order=name&list_id=70`)
		const chart_legend_positions = await http.get(`${window.__endpoint}/cha/list_lines/list?cols=name,list_value_code&order=name&list_id=71`)

		setSelectOptions({
			refer_types: refer_types.data,
			modules: modules.data,
			break_fields: break_fields.data,
			chart_types: chart_types.data,
			chart_legend_positions: chart_legend_positions.data
		})
	}

	const onChangeChart = async(e) =>{
		setEnableChart(Number(e.checked))
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'query']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar />
				<Row>
					{/* Nome */}
					<Col all={4}>
						<FormGroup
							name="name"
							label="Nome"
							validationState={validator.validationState.name}
						>
							<InputText
								value={editItem.name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Referement type */}
					<Col all={4}>
						<FormGroup
							name="refer_type_id"
							label="Referement type"
							validationState={validator.validationState.refer_type_id}
						>
							<Select
								value={editItem.refer_type_id || ''}
								onChange={onChange}
								options={selectOptions.refer_types || []}
								optionValue={(o) => o.refer_type_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Module */}
					<Col all={4}>
						<FormGroup
							name="module_id"
							label="Modulo"
							validationState={validator.validationState.module_id}
						>
							<Select
								value={editItem.module_id || ''}
								onChange={onChange}
								options={selectOptions.modules || []}
								optionValue={(o) => o.module_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Attivo di default (report standard) */}
					<Col all={12}>
						<FormGroup
							name="default_active_flag"
							label="Attivo di default su ogni ambiente per ogni organizzazione"
							validationState={validator.validationState.default_active_flag}
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.default_active_flag == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Descrizione */}
					<Col all={12}>
						<FormGroup
							name="description"
							label="Descrizione"
							validationState={validator.validationState.description}
						>
							<InputTextarea
								rows={5}
								value={editItem.description || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Query */}
					<Col all={12}>
						<FormGroup
							name="query"
							label="Query"
							validationState={validator.validationState.query}
						>
							<InputTextarea
								rows={5}
								value={editItem.query || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>

				<Row>
					{/* Esportazione in PDF */}
					<Col all={6}>
						<FormGroup
							name="printable"
							label="Esportazione in PDF"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.printable == 1}
							/>
						</FormGroup>
					</Col>
					{/* Orientamento PDF orizzontale */}
					<Col all={6}>
						<FormGroup
							name="landscape"
							label="Orientamento PDF orizzontale"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.landscape == 1}
								disabled={editItem.printable != 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Campo raggruppamento in colonna */}
					<Col all={6}>
						<FormGroup
							name="groupable_without_header"
							label="Campo raggruppamento in colonna"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.groupable_without_header == 1}
							/>
						</FormGroup>
					</Col>
					{/* Spaziatura tra sotto-tabelle raggruppate */}
					<Col all={6}>
						<FormGroup
							name="subtables_spacing"
							label="Spaziatura tra sotto-tabelle raggruppate (in mm)"
							validationState={validator.validationState.subtables_spacing}
						>
							<InputText
								type="number"
								value={editItem.subtables_spacing || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Campo raggruppamento in colonna */}
					<Col all={6}>
						<FormGroup
							name="excel"
							label="Esportazione in Excel"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.excel == 1}
							/>
						</FormGroup>
					</Col>
					{/* Nome del file per l'export (senza l'estensione) */}
					<Col all={6}>
						<FormGroup
							name="file_name"
							label="Nome del file per l'export (senza l'estensione)"
							validationState={validator.validationState.file_name}
						>
							<InputText
								value={editItem.file_name || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Abilita il sommario */}
					<Col all={6}>
						<FormGroup
							name="summary_enabled"
							label="Abilita il sommario"
						>
							<Checkbox
								onChange={onChange}
								checked={editItem.summary_enabled == 1}
							/>
						</FormGroup>
					</Col>
					{/* Campo sul quale interrompere la pagina */}
					<Col all={6}>
						<FormGroup
							name="break_field_id"
							label="Campo sul quale interrompere la pagina"
							validationState={validator.validationState.break_field_id}
						>
							<Select
								value={editItem.break_field_id || ''}
								onChange={onChange}
								options={selectOptions.break_fields || []}
								optionValue={(o) => o.report_field_id}
								optionLabel={(o) => o.column_name}
								all={{
									label: ' - Seleziona - '
								}}
								disabled={editItem.summary_enabled != 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Abilita grafico */}
					<Col all={12}>
						<FormGroup
							name="enableChart"
							label="Abilita grafico"
						>
							<Checkbox
								onChange={onChangeChart}
								checked={enableChart == 1}
							/>
						</FormGroup>
					</Col>
				</Row>
				{enableChart == 1 &&
				<Row>
					<Divider />
					{/* Titolo grafico */}
					<Col all={12}>
						<FormGroup
							name="chart_title"
							label="Titolo grafico"
							validationState={validator.validationState.chart_title}
						>
							<InputText
								value={editItem.chart_title || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Tipo di grafico */}
					<Col all={6}>
						<FormGroup
							name="chart_type"
							label="Tipo di grafico"
							validationState={validator.validationState.chart_type}
						>
							<Select
								value={editItem.chart_type || ''}
								onChange={onChange}
								options={selectOptions.chart_types || []}
								optionValue={(o) => o.list_value_code}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Posizione legenda */}
					<Col all={6}>
						<FormGroup
							name="chart_legend_position"
							label="Posizione legenda"
							validationState={validator.validationState.chart_legend_positions}
						>
							<Select
								value={editItem.chart_legend_positions || ''}
								onChange={onChange}
								options={selectOptions.chart_legend_positions || []}
								optionValue={(o) => o.list_value_code}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Serie asse principale */}
					<Col all={12}>
						<FormGroup
							name="chart_main_axis_field"
							label="Serie asse principale"
							validationState={validator.validationState.chart_main_axis_field}
						>
							<Select
								value={editItem.chart_main_axis_field || ''}
								onChange={onChange}
								options={fields || []}
								optionValue={(o) => o.field_name}
								optionLabel={(o) => o.column_name}
								all={{
									label: ' - Seleziona - '
								}}
							/>
						</FormGroup>
					</Col>
					{/* Opzioni (stacked) */}
					<Col all={12}>
						<FormGroup
							name="chart_options"
							label="Opzioni (stacked)"
							validationState={validator.validationState.chart_options}
						>
							<InputText
								value={editItem.chart_options || ''}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>

				}
			</form>}}
	</FormValidator>
}
export default Edit
