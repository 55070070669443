import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { classNames } from 'primereact/utils'
import { confirmDialog } from 'primereact/confirmdialog'
import { onMenuToggle, showProfileSidebar } from 'reducers/ui/uiSlice'
import { logout } from 'reducers/user/userSlice'
import { setLayoutState } from 'reducers/ui/uiSlice'

const AppTopbar = forwardRef((props, ref) => {
	const dispatch = useDispatch()
	const { layoutConfig, layoutState } = useSelector(state => state.ui)

	const menubuttonRef = useRef(null)
	const topbarmenuRef = useRef(null)
	const topbarmenubuttonRef = useRef(null)

	useImperativeHandle(ref, () => ({
		menubutton: menubuttonRef.current,
		topbarmenu: topbarmenuRef.current,
		topbarmenubutton: topbarmenubuttonRef.current
	}))

	const doLogout = () => {
		confirmDialog({
			message: 'Procedere con il logout?',
			header: 'Logout utente',
			icon: 'pi pi-exclamation-triangle',
			accept: () => dispatch(logout()),
			reject: () => { /* Do nothing */}
		})
	}

	const onConfigButtonClick = () => {
		dispatch(setLayoutState({ configSidebarVisible: true }))
	}

	return (
		<div className="layout-topbar">
			<Link to="/" className="layout-topbar-logo">
				<img src={`/layout/images/logo-${layoutConfig.colorScheme !== 'light' ? 'white' : 'dark'}.svg`} width="47.22px" height={'35px'} widt={'true'} alt="logo" />
				<span>ADMIN</span>
			</Link>

			<button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={() => dispatch(onMenuToggle())}>
				<i className="pi pi-bars" />
			</button>
			<div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible })}>
				<button type="button" className="p-link layout-topbar-button" onClick={doLogout}>
					<i className="pi pi-sign-out"></i>
					<span>logout</span>
				</button>
				<button type="button" className="p-link layout-topbar-button" onClick={onConfigButtonClick}>
					<i className="pi pi-cog"></i>
				</button>
			</div>
		</div>
	)
})

export default AppTopbar
