import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit, setEditItem }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['connector']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="connector"
						label="Connettore"
						validationState={validator.validationState.connector}
					>
						<InputText
							value={editItem.connector || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
