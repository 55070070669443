import React from 'react'
import { Button } from 'components/Ui'

const ModalActions = ({	data, actions, onClose }) => {

	return <React.Fragment>
		{actions.map((action, a) => {
			const { component: Component, ...rest } = action
			return <Button
				key={a}
				onClick={() => {
					if (action.action && typeof action.action === 'function') {
						action.action(data)
						onClose()
					} else {
						console.error('action is not a function')
					}
				}}
				icon={action.icon}
				label={action.label}
				style={{ marginBottom: '5px' }}
			/>
		})}
	</React.Fragment>
}

export default ModalActions
