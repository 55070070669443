import React from 'react'
import ReactQuill from 'react-quill'
import { Button } from '..'
import { InputTextarea } from '..'
// import { Button, FormControl } from 'react-bootstrap'
import 'react-quill/dist/quill.snow.css'

class HTMLEditor extends React.Component {
	state = {
		value: '',
		editorHtml: true,
	}

	componentDidMount() {
		this.setState({
			value: this.props.value,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.value != prevProps.value)
			this.setState({
				value: this.props.value,
			})
	}

	handleHtmlValueChange = (value) => {
		this.setState({ value: value })

		if (! this.props.onChange) {
			return
		}

		if (this.props.onChange instanceof Function) {
			this.props.onChange({
				target: {
					name: this.props.name,
					value: value,
				}
			})
		}
	}
	handleTextValueChange = (e) => {
		const value = e.target.value
		this.setState({ value: value })

		this.props.onChange({
			target: {
				name: this.props.name,
				value: value,
			}
		})
	}

	render() {
		const { value } = this.state
		const customHeight = this.props.customHeight
		let css = `
			.${this.props.name}_container > .ql-container {
				height: ${customHeight && customHeight > 0 ? customHeight : '500'}px !important;
			}
		`
		const  modules = {
			toolbar: [
				[{ 'header': [1, 2, false] }],
				['bold', 'italic', 'underline','strike', 'blockquote'],
				[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
				['link', 'image'],
				['clean']
			],
		}
		return (<div style={{ display: 'flex', flexDirection: 'column' }}>
			<style>{css}</style>
			{!this.props.hideCode && <div style={{ maxWidth: '220' }}>
				<Button onClick={() => this.setState({ editorHtml: !this.state.editorHtml })}>
					<i className="fas fa-code"></i> Editor Html / Codice sorgente
				</Button>
			</div>}
			{!this.state.editorHtml && <InputTextarea
				rows={7}
				value={value || ''}
				onChange={this.handleTextValueChange}
			/>
			}
			{this.state.editorHtml &&
				<ReactQuill
					className={`${this.props.name}_container`}
					value={value}
					onChange={(text, delta, source, editor) => {
						if (source == 'user') {
							this.handleHtmlValueChange(text)
						}
					}}
					readOnly={this.props.readOnly}
					modules={this.props.modules || modules}
				/>}

		</div>)
	}
}

export default HTMLEditor
