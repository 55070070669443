import React, { useContext } from 'react'
import http from 'axios'
import { Card } from 'components/Ui'
import { SearchContext } from 'core/contexts/SearchContext'
import Data from '../../Components/Data/Data'

function PageData() {
	const [items, setItems] = React.useState([])
	const [filtersValues, setFiltersValues] = React.useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)

	React.useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/deploy/db/cha/data/listTables`, {
			params: filters
		})
		setItems(list.data)
	}

	return <Card title="Deploy dati CHA">
		<Data
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
		/>
	</Card>
}

export default PageData
