import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { Edit as RulesEdit } from '../../Components/RulesFields'

function PageEditRulesFields() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id, ruleId } = useParams()
	const isNew = (ruleId == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id,ruleId])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (! isNew) {
			const target = await http.get(`${window.__endpoint}/int/interfaces/rules_fields/load?interface_field_id=${ruleId}`)
			setEditItem(target.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const interface_field_id = await http.post(`${window.__endpoint}/int/interfaces/rules_fields/create`, {
				...editItem,
				interface_id: id
			})
			navigate(`./../edit/${interface_field_id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/int/interfaces/rules_fields/update`, {
				...editItem
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title={`${isNew ? 'Crea regola' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'regola'}`}`}>
		<RulesEdit
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
			interface_field_id={ruleId}
		/>
	</Card>
}

export default PageEditRulesFields
