import React, { useEffect, useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, InputTextarea, Row, Select, SelectTree } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ areaId, editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const nodeTypes = await http.get(`${window.__endpoint}/cha/node_types/list?cols=node_type_id,name&order=description`)
		const nodeParents = await http.get(`${window.__endpoint}/cha/nodes/list?cols=node_id,node_full_name&order=node_full_name&area_id=${areaId}&node_type_id=1`)

		setSelectOptions({
			nodeTypes: nodeTypes.data,
			nodeParents: nodeParents.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'node_order', 'node_type_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="parent_id"
						label="Nodo padre"
						validationState={validator.validationState.parent_id}
					>
						<SelectTree
							value={editItem.parent_id || ''}
							onChange={onChange}
							options={selectOptions.nodeParents || []}
							optionValue={(o) => o.node_id}
							optionLabel={(o) => o.node_full_name}
							all={{
								label: 'Radice'
							}}
						/>
					</FormGroup>
				</Col>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
					>
						<InputTextarea
							value={editItem.description || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="node_order"
						label="Ordine"
						validationState={validator.validationState.node_order}
					>
						<InputText
							type="number"
							value={editItem.node_order || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="node_type_id"
						label="Tipo"
						validationState={validator.validationState.node_type_id}
					>
						<Select
							value={Number(editItem.node_type_id) || ''}
							onChange={onChange}
							options={selectOptions.nodeTypes || []}
							optionValue={(o) => Number(o.node_type_id)}
							optionLabel={(o) => o.name}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="web_node"
						label="Visibile su ERP desktop"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.web_node == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="web_quick_node"
						label="Link home su ERP desktop"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.web_quick_node == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="pwa_node"
						label="Visibile su ERP pwa"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.pwa_node == 1}
						/>
					</FormGroup>
				</Col>
				<Col all={12} sm={6}>
					<FormGroup
						name="pwa_quick_node"
						label="Link home su ERP pwa"
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.pwa_quick_node == 1}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
