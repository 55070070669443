import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ readOnly, editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})
	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const services = await http.get(`${window.__endpoint}/si/services/list?cols=service_id,name&order=name`)
		const organizations = await http.get(`${window.__endpoint}/environment/cha/organizations/list?cols=organization_id,name&order=name`)

		setSelectOptions({
			services: services.data,
			organizations: organizations.data,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['service_id', 'organization_id']}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar
					readOnly={readOnly}
				/>
				<Row>
					{/* servizio */}
					<Col all={12}>
						<FormGroup
							name="service_id"
							label="Servizio"
							validationState={validator.validationState.service_id}
						>
							<Select
								value={editItem.service_id || ''}
								onChange={onChange}
								options={selectOptions.services || []}
								optionValue={(o) => o.service_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
								disabled={readOnly}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					{/* Organizzazione */}
					<Col all={12}>
						<FormGroup
							name="organization_id"
							label="Organizzazione"
							validationState={validator.validationState.organization_id}
						>
							<Select
								value={editItem.organization_id || ''}
								onChange={onChange}
								options={selectOptions.organizations || []}
								optionValue={(o) => o.organization_id}
								optionLabel={(o) => o.name}
								all={{
									label: ' - Seleziona - '
								}}
								disabled={readOnly}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
