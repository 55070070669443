import { useSelector } from 'react-redux'
import { Button as PrButton } from 'primereact/button'

function Button({ disabled, icon, iconPos, ...props }) {
	const { loading } = useSelector(state => state.ui)
	const isLoading = loading > 0

	return <PrButton
		size="small"
		iconPos={iconPos || 'right'}
		icon={`pi ${!isLoading ? icon : 'pi-spin pi-spinner'}`}
		disabled={disabled || isLoading}
		tooltipOptions={{ position: 'top' }}
		type="button"
		{...props}
	/>
}

export default Button
