import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * pages
import { PageEdit } from './Pages'

// Senza context perché non è necessario in una lista sotto un edit in quanto la lista non è filtrabile
function Router() {
	return <Routes>
		<Route path="create" element={<PageEdit />} />
		<Route path="edit/:id" element={<PageEdit />} />
	</Routes>
}

export default Router
