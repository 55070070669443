import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'
import { EditSettings as EditSettings } from '../../Components/Portal'

function PageEditFields() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { portalId, id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({
		portal_id: portalId,
	})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = (e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const portal_settings = await http.get(`${window.__endpoint}/environment/bm/portal_settings/load?setting_id=${id}`)
			setEditItem(portal_settings.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const response = await http.post(`${window.__endpoint}/environment/bm/portal_settings/create`, editItem)
			navigate(`../edit/${portalId}/settings/edit/${response.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/environment/bm/portal_settings/update`, {
				setting_id: id,
				...editItem
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title="Modifica impostazioni portale">
		<EditSettings
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
			portalId={portalId}
			isCreate={isNew}
		/>
	</Card>
}

export default PageEditFields
