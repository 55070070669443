import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Components
import { Edit as EditRegion } from '../../Components/Regions'
import { List as ListProvinces } from '../../Components/Provinces'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})
	const [listProvinces, setListProvinces] = useState([])

	const defaultFiltersValues = {
		region_id: id,
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListProvinces(defaultFiltersValues)
		}
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/utl/regions/load?region_id=${id}`)
			setEditItem(service.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/utl/regions/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/utl/regions/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione delle province
	const handleListProvinces = async(filters) => {
		const list = await http.get(`${window.__endpoint}/utl/regions/provinces/list`, {
			params: filters
		})
		setListProvinces(list.data)
	}
	const handleSetProvincesItems = listProvinces => {
		setListProvinces(listProvinces)
	}

	return <>
		<Card title={`${isNew ? 'Crea regione' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'regione'}`}`}>
			<EditRegion
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Modifica province">
				<ListProvinces
					region_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={defaultFiltersValues}
					items={listProvinces}
					onList={handleListProvinces}
					handleSetItems={handleSetProvincesItems}
				/>
			</Card>
			<br />
		</>
		}
	</>
}

export default PageEdit
