import React, { useState } from 'react'
import http from 'axios'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button, Modal } from 'components/Ui'
import './RecordComparison.scss'

function RecordComparison({ record, open, onClose, table, ...props }) {
	const [comparedRecords, setComparedRecords] = useState([])
	const handleOpen = async() => {
		const recordCondition = table.pk.split(',').map(key => `${key}='${record[key]}'`).join(' AND ')
		const records = await http.get(`${window.__endpoint}/deploy/db/cha/data/compareRecord?table=${table.name}&cond=${encodeURIComponent(recordCondition)}`)
		setComparedRecords(records.data)
	}

	const handleClose = () => {
		setComparedRecords([])
		onClose()
	}

	const fields = comparedRecords.dp3 ? Object.keys(comparedRecords.dp3) : []

	return <React.Fragment>
		<Modal
			open={open}
			onClose={handleClose}
			onOpen={handleOpen}
			label={table.name}
			maximizable
			width={'90vw'}
		>
			<table className='record-comparison'>
				<thead>
					<tr>
						<th></th>
						{Object.keys(comparedRecords).map((server, s) => <th key={s}>{server}</th>)}
					</tr>
				</thead>
				<tbody>
					{fields.map((field, f) => <tr key={f}>
						<td className='field-name'>{field}</td>
						{Object.values(comparedRecords).map((server, s) => <td
							key={s}
							className={server[field] != comparedRecords.dp3[field] ? 'different' : 'equal'}
						>
							{server[field]}
						</td>)}
					</tr>)}
				</tbody>
			</table>
		</Modal>
	</React.Fragment>
}

export default RecordComparison
