import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							rows={4}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="code"
						label="Codice"
						validationState={validator.validationState.code}
					>
						<InputText
							value={editItem.code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="pa_code"
						label="Codice pa"
						validationState={validator.validationState.pa_code}
					>
						<InputText
							value={editItem.pa_code || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={4}>
					<FormGroup
						name="group_id"
						label="Gruppo"
						validationState={validator.validationState.group_id}
					>
						<InputText
							type='number'
							value={editItem.group_id || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
