import React, { useState } from 'react'
import { Col, Checkbox, FormGroup, InputText, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ data, onSubmit, states }) {
	const [editItem, setEditItem] = useState(data || {})

	const onChange = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleSubmit = () => {
		onSubmit(editItem)
	}

	return <FormValidator
		onSubmit={handleSubmit}
		editItem={editItem}
		requiredFields={['line_value', 'name', 'description']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="line_value"
						label="Valore"
						validationState={validator.validationState.line_value}
					>
						<InputText
							value={editItem.line_value || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputText
							value={editItem.description || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}
export default Edit
