import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, InputTextarea, Row, Select } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})

	const handleListFilters = async() => {
		// Carico le response di tutti i filtri
		const referements = await http.get(`${window.__endpoint}/cha/referement_types/list?cols=referment_full_name,refer_type_id,name`)
		const types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${73}`)
		const lists = await http.get(`${window.__endpoint}/cha/lists/list?cols=list_id,name&order=name`)
		const profiles = await http.get(`${window.__endpoint}/cha/profiles/list?cols=profile_id,name&order=name&profile_type_id=${5}`)

		// Set the filters state
		setSelectOptions({
			referements: referements.data,
			profiles_types: profiles.data,
			lists: lists.data,
			types: types.data,
		})
	}
	useEffect(() => {
		handleListFilters()
	}, [])

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['name', 'param_name', 'param_submit_name', 'parameter_type_id', 'width']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				{/* Name */}
				<Col all={12}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Parametro */}
				<Col all={6}>
					<FormGroup
						name="param_name"
						label="Parametro (quando il valore del parametro viene salvato nel db)"
						validationState={validator.validationState.param_name}
					>
						<InputText
							value={editItem.param_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				{/* Parametro submit */}
				<Col all={6}>
					<FormGroup
						name="param_submit_name"
						label="Parametro submit (quando il valore del parametro viene solo inviato tramite request)"
						validationState={validator.validationState.param_submit_name}
					>
						<InputText
							value={editItem.param_submit_name || ''}
							onChange={onChange}
						/>
					</FormGroup>
					<small>Per le multi date inserire i 2 campi separati da punto e virgola</small>
				</Col>
			</Row>
			<Row>
				{/* Larghezza */}
				<Col all={3}>
					<FormGroup
						name="width"
						label="Larghezza"
						validationState={validator.validationState.width}
					>
						<InputText
							type="number"
							value={editItem.width || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				{/* Ordinamento */}
				<Col all={3}>
					<FormGroup
						name="val_order"
						label="Ordinamento"
						validationState={validator.validationState.val_order}
					>
						<InputText
							type="number"
							value={editItem.val_order || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				{/* Obbligatorio */}
				<Col all={3}>
					<FormGroup
						name="required_flag"
						label="Obbligatorio"
						validationState={validator.validationState.required_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.required_flag == 1}
						/>
					</FormGroup>
				</Col>
				{/* Attivo */}
				<Col all={3}>
					<FormGroup
						name="active_flag"
						label="Attivo"
						validationState={validator.validationState.active_flag}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.active_flag == 1}
						/>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col all={12}>
					<br />
					<h5>Tipo di dato</h5>
				</Col>
				{/* Tipologia */}
				<Col all={12}>
					<FormGroup
						name="parameter_type_id"
						label="Tipologia"
						validationState={validator.validationState.parameter_type_id}
					>
						<Select
							value={editItem.parameter_type_id || ''}
							onChange={onChange}
							options={selectOptions.types || []}
							optionValue={(o) => o.list_value_id}
							optionLabel={(o) => o.name}
						/>
					</FormGroup>
				</Col>
				{/* Multi */}
				{([5,7,8,9,11,12].includes(Number(editItem.parameter_type_id))) && <Col all={12}>
					<FormGroup
						name="is_multi"
						label="Consenti selezione valori multipli"
						validationState={validator.validationState.is_multi}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.is_multi == 1}
						/>
					</FormGroup>
				</Col>}
			</Row>
			<Row>
				{/* Carica l'elenco completo senza filtro (no async) */}
				<Col all={4}>
					<FormGroup
						name="show_all"
						label="Carica l'elenco completo senza filtro (no async)"
						validationState={validator.validationState.show_all}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.show_all == 1}
							disabled={!([7,8,9,13].includes(Number(editItem.parameter_type_id)))}
						/>
					</FormGroup>
				</Col>
				{/* Campo da...a */}
				<Col all={4}>
					<FormGroup
						name="is_from_to"
						label="Campo da...a"
						validationState={validator.validationState.is_from_to}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.is_from_to == 1}
						/>
					</FormGroup>
				</Col>
				{/* Copia valore da su a  */}
				<Col all={4}>
					<FormGroup
						name="same_value_fields"
						label="Copia valore da, su a"
						validationState={validator.validationState.same_value_fields}
					>
						<Checkbox
							onChange={onChange}
							checked={editItem.same_value_fields == 1}
							disabled={editItem.is_from_to != 1}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Referement type */}
				<Col all={6}>
					<FormGroup
						name="list_refer_type_id"
						label="Riferimento"
						validationState={validator.validationState.list_refer_type_id}
					>
						<Select
							value={editItem.list_refer_type_id || ''}
							onChange={onChange}
							options={selectOptions.referements || []}
							optionValue={(o) => o.refer_type_id}
							optionLabel={(o) => o.referment_full_name}
							disabled={editItem.parameter_type_id != 7 && editItem.parameter_type_id != 11}
						/>
					</FormGroup>
				</Col>
				{/* Lista valore */}
				<Col all={6}>
					<FormGroup
						name="list_id"
						label="Lista valore"
						validationState={validator.validationState.list_id}
					>
						<Select
							value={editItem.list_id || ''}
							onChange={onChange}
							options={selectOptions.lists || []}
							optionValue={(o) => o.list_id}
							optionLabel={(o) => o.name}
							disabled={editItem.parameter_type_id != 8}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Modello per il file */}
				<Col all={12}>
					<FormGroup
						name="template"
						label="Modello per il file"
						validationState={validator.validationState.template}
					>
						<InputText
							value={editItem.template || ''}
							onChange={onChange}
							disabled={editItem.parameter_type_id != 10}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Nome del file generato per il download del template */}
				<Col all={6}>
					<FormGroup
						name="template_name"
						label="Nome del file generato per il download del template (compreso di estensione)"
						validationState={validator.validationState.template_name}
					>
						<InputText
							value={editItem.template_name || ''}
							onChange={onChange}
							disabled={editItem.parameter_type_id != 10}
						/>
					</FormGroup>
				</Col>
				{/* Estensioni ammesse per il file, separate da ";" con il "." prima di ogni estensione */}
				<Col all={6}>
					<FormGroup
						name="extensions"
						label='Estensioni ammesse per il file, separate da ";" con il "." prima di ogni estensione (es: .csv;.xls)'
						validationState={validator.validationState.extensions}
					>
						<InputText
							value={editItem.extensions || ''}
							onChange={onChange}
							disabled={editItem.parameter_type_id != 10}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Query lista */}
				<Col all={12}>
					<FormGroup
						name="list_sql"
						label="Query lista"
						validationState={validator.validationState.list_sql}
					>
						<InputTextarea
							value={editItem.list_sql || ''}
							onChange={onChange}
							rows={7}
							// disabled={editItem.parameter_type_id != 9}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<br />
					<h5>Default</h5>
				</Col>
			</Row>
			<Row>
				{/* Valore */}
				<Col all={6}>
					<FormGroup
						name="default_value"
						label='Valore'
						validationState={validator.validationState.default_value}
					>
						<InputText
							value={editItem.default_value || ''}
							onChange={onChange}
						/>
					</FormGroup>
					<small>Valore fisso o comando sql per ricavare il valore. Per le multi date inserire i valori separati da punto e virgola</small>
				</Col>
				{/* Profilo di stampa */}
				<Col all={6}>
					<FormGroup
						name="default_value_from_profile_id"
						label="Profilo di stampa"
						validationState={validator.validationState.default_value_from_profile_id}
					>
						<Select
							value={editItem.default_value_from_profile_id || ''}
							onChange={onChange}
							options={selectOptions.profiles_types || []}
							optionValue={(o) => o.profile_id}
							optionLabel={(o) => o.name}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Descrizione */}
				<Col all={12}>
					<FormGroup
						name="description"
						label="Descrizione"
						validationState={validator.validationState.description}
					>
						<InputTextarea
							value={editItem.description || ''}
							onChange={onChange}
							rows={7}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
