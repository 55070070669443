import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as ProfilesEdit } from '../../Components/Parameters'

function PageEdit() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const query = useQuery()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	useEffect(() => {
		handleLoad()
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/cha/parameters/load?parameter_id=${id}`)
			setEditItem(area.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/parameters/create`, {
				...editItem,
				refer_type_id: query.get('refer_type_id'),
				refer_id: query.get('refer_id'),
			})
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/parameters/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title={`${isNew ? 'Crea parametro' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'parametro'}`}`}>
		<ProfilesEdit
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
		/>
	</Card>
}

function useQuery() {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export default PageEdit
