import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListGrid, ListToolbar } from 'components/List'
import { useNavigate } from 'react-router-dom'
import http from 'axios'
import { Modal, Row, Col, Button } from 'components/Ui'
import { Execute } from '..'

function List({ defaultFiltersValues, items, onDelete, onSubmit, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const navigate = useNavigate()

	const [modalOpen, setModalOpen] = useState(false)
	const [parameters, setParameters] = useState([])
	const [editItem, setEditItem] = useState({})
	const [id, setId] = useState(null)
	const [path, setPath] = useState(null)

	useEffect(() =>{
		setEditItem({})
	}, [id])

	const handleExecute = async(id) => {
		const response = await http.get(`${window.__endpoint}/develop/scripts/parameters/list`,{
			params: {
				order: 'val_order',
				script_id: id
			}
		})
		setId(id)
		setParameters(response.data)
		setModalOpen(true)
	}
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const execute = {
		label: 'esegui',
		//icon: 'pi pi-trash',
		onClick: handleExecute,
		//command: handleExecute,
	}

	/* const customEdit = {
		//className: 'w-full',
		label: 'modifica',
		icon: 'fa-solid fa-pen-to-square',
		onClick: handleEdit,
		//command: handleEdit
	} */

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
				setParameters({})
				setId(null)
				setPath(null)
			}}
			label='Inserimento parametri'
		>
			<Execute
				{...props}
				editItem={editItem}
				parameters={parameters}
				onChange={handleChangeEditItem}
				id={id}
				setPath={setPath}
			/>
		</Modal>

		<ListGrid
			//onDelete={onDelete}
			items={items}
			fieldId='script_id'
			columns={[
				{ field: 'script_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'script', header: 'Procedura', sortable: true },
				{ field: 'description', header: 'Descrizione', sortable: true },

			]}
			customItems={[execute]}
			//onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
