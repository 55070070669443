import React, { useState } from 'react'
import { Col, FormGroup, InputText, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import { useDispatch } from 'react-redux'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'

const QueryFieldsGenerator = ({
	report_id,
	binds,
	onSaveCompleted,
	onSave,
	...props
}) => {
	const dispatch = useDispatch()
	const [editItem, setEditItem] = useState({})

	const onChange = (e) => {
		setEditItem({
			...editItem,
			[e.target.name] : e.target.value
		})
	}

	const handleSubmit = async() => {
		// Forzo il managed_server_id perché sono su cha e devo generare i campi del report, quindi devo eseguire una query su un ambiente e quell'ambiente è dev
		const response = await http.post(`${window.__endpoint}/rep/reports/fields/generate_from_query?report_id=${report_id}&managed_server_id=1`, {
			binds: {
				...editItem
			}
		})

		if (response.errcode == 0) {
			dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
			onSaveCompleted()
		} else {
			dispatch(setMessage({ message: { severity: 'error', summary: response.message || '', detail: '' } }))
		}
	}

	return <FormValidator
		onSubmit={handleSubmit}
		editItem={editItem}
		requiredFields={binds}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar hideGoBackButton />
				<Row>
					{binds && binds.length > 0 && binds.map((bind, k) => {
						return <Col all={12} key={k}>
							<FormGroup
								name={bind}
								label={bind}
								validationState={validator.validationState[bind]}
							>
								<InputText
									value={editItem[bind] || ''}
									onChange={onChange}
								/>
							</FormGroup>
						</Col>
					})}
				</Row>
			</form>}}
	</FormValidator>
}

export default QueryFieldsGenerator
