import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { HeaderLinesSeparator } from 'components/Page'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

import { Edit as AreaEdit } from '../../Components/Areas'
import { List as NodeList } from '../../Components/Nodes'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}

	useEffect(() => {
		handleLoad()
		handleListNodes(defaultFiltersValues)
	}, [id])

	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const area = await http.get(`${window.__endpoint}/cha/areas/load?area_id=${id}`)
			setEditItem(area.data)
		}
	}

	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/areas/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/areas/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleListNodes = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/nodes/list`, {
			params: {
				...filters,
				area_id: id,
				columns: 'node_id, name, node_type, node_order'
			}
		})
		setItems(list.data)
	}

	const handleDeleteNode = async(id) => {
		await http.delete(`${window.__endpoint}/cha/nodes/delete?node_id=${id}`)
		handleListNodes(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea area' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'area'}`}`}>
			<AreaEdit
				editItem={editItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title="Elenco nodi">
				<NodeList
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={items}
					onList={handleListNodes}
					onDelete={handleDeleteNode}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
