import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import http from 'axios'
import { Card } from 'components/Ui'
import { List as SubscriptionsList } from '../../Components/Blogs'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { SearchContext } from 'core/contexts/SearchContext'
import { Context as ServerContext } from 'apps/environment/context'

function PageList() {
	const dispatch = useDispatch()
	const [items, setItems] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {}
	const { contextSearch } = useContext(SearchContext)
	const { selectedServer, selectedEnvironment } = useContext(ServerContext)

	useEffect(() => {
		handleList({
			...defaultFiltersValues,
			...contextSearch,
		})
	}, [selectedServer, selectedEnvironment])

	const handleList = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/environment/ecom/blogs/list`, {
			params: filters
		})
		setItems(list.data)
	}

	const handleSave = async(data) => {
		if (data && data.blog_id > 0) {
			await http.put(`${window.__endpoint}/environment/ecom/blogs/update`, {
				...data
			})
			handleList(filtersValues)
		} else {
			await http.post(`${window.__endpoint}/environment/ecom/blogs/create`, {
				...data
			})
			handleList(filtersValues)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	const handleDelete = async(id) => {
		await http.delete(`${window.__endpoint}/environment/ecom/blogs/delete?blog_id=${id}`)
		handleList(filtersValues)
	}

	return <Card title="Blog">
		<SubscriptionsList
			defaultFiltersValues={defaultFiltersValues}
			filtersValues={filtersValues}
			items={items}
			onList={handleList}
			onDelete={handleDelete}
			onSubmit={handleSave}
		/>
	</Card>
}

export default PageList
