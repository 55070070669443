import React, { useState } from 'react'
import { ListGrid, ListToolbar } from 'components/List'
import http from 'axios'
import { Col, Row, Select, Button } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const { onSubmit } = props
	const [paymentMethods, setPaymentMethods] = React.useState([])

	const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState()

	const handleLoadOptions = async() =>{
		const paymentMethods = await http.get(`${window.__endpoint}/ecom/payment_methods/list?cols=payment_method_id,payment_method&order=payment_method`)

		setPaymentMethods(paymentMethods.data)
	}

	React.useEffect(() => {
		handleLoadOptions()
	}, [])

	const handleSubmit = () =>{
		onSubmit({
			shipping_payment_id: -1,
			payment_method_id: selectedPaymentMethod,
		})
		setSelectedPaymentMethod(null)
	}

	return <React.Fragment>
		<ListToolbar
			onShowFilters={() => {}}
			hideBtnFilter
			hideBtnAdd
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedPaymentMethod || ''}
					onChange={(e) => setSelectedPaymentMethod(e.target.value)}
					options={paymentMethods || []}
					optionValue={(o) => o.payment_method_id}
					optionLabel={(o) => o.payment_method}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Button onClick={handleSubmit} disabled={! selectedPaymentMethod}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='shipping_payment_id'
			columns={[
				{ field: 'shipping_payment_id', header: 'Id' },
				{ field: 'payment_method', header: 'Metodo di pagamento' },
			]}
		/>
	</React.Fragment>
}

export default List
