import React, { useState } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'

function List({ defaultFiltersValues, items, onDelete, onEnvEnable, onEnvDisable, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			hideBtnAdd
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='shipping_method_id'
			columns={[
				{ field: 'env_shipping_method_id', header: 'Id', sortable: true },
				{ field: 'shipping_method', header: 'Metodo di spedizione', sortable: true },
				{ field: 'active_state', header: 'stato', type: 'boolean', editable: false, sortable: true },
			]}
			envCustomizeButton
			envEnableButtons={{
				onEnable: onEnvEnable,
				onDisable: onEnvDisable,
			}}
			scrollHeight="calc(100vh - 23rem)"
		/>
	</React.Fragment>
}

export default List
