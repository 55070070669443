import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as EventWarningTypes } from '../modules/EventWarningTypes'

function App() {
	return <Routes>
		<Route path="event-warning-types/*" element={<EventWarningTypes />} />
	</Routes>
}

export default App
