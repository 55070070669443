import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as CommunicationsTaxes } from '../modules/Communications'

function App() {
	return <Routes>
		<Route path="communications/*" element={<CommunicationsTaxes />} />
	</Routes>
}

export default App
