import React from 'react'
import { ListGrid } from 'components/List'
import { ListToolbarSelector } from 'components/List'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onSave, onList, filtersValues, ...props }) {
	const [selectOptions, setSelectOptions] = React.useState({})
	const [addListEditItem, setAddListEditItem] = React.useState({})

	React.useEffect(() => {
		listAddonModules()
	}, [])

	const listAddonModules = async() => {
		const modules = await http.get(`${window.__endpoint}/cha/modules/list?cols=module_id,name&order=name`)

		setSelectOptions({
			modules: modules.data,
		})
	}

	const handleToolbarChange = (target) => {
		setAddListEditItem({
			...addListEditItem,
			[target.name]: target.value
		})
	}

	const onSubmit = () => {
		onSave({
			...addListEditItem
		})
	}

	return <React.Fragment>
		<ListToolbarSelector
			onChange={handleToolbarChange}
			selectOptions={[
				{
					options: selectOptions.modules || [],
					name: 'module_id',
					placeholder: 'Seleziona il modulo aggiuntivo da collegare',
					optionValue: (o) => { return Number(o.module_id) },
					optionLabel: (o) => { return o.name }
				},
			]}
			onSubmit={onSubmit}
		/>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='topic_module_id'
			columns={[
				{ field: 'topic_module_id', header: 'Id', sortable: true },
				{ field: 'module_id', header: 'ID Modulo', sortable: true },
				{ field: 'module', header: 'Modulo', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
