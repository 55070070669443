import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as ModifierTypes } from '../modules/ModifierTypes'
import { default as Attributes } from '../modules/Attributes'

function App() {
	return <Routes>
		<Route path="modifier-types/*" element={<ModifierTypes />} />
		<Route path="attributes/*" element={<Attributes />} />

	</Routes>
}

export default App
