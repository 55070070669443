import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Portals } from '../modules/Portals'

function App() {
	return <Routes>
		<Route path="portals/*" element={<Portals />} />
	</Routes>
}

export default App
