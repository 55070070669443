import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import http from 'axios'
import { Card } from 'components/Ui'
import labels from 'core/labels'
import { setMessage } from 'reducers/ui/uiSlice'

// Components
import { Edit as EditPrintReports } from '../../Components/PrintReports'
function PageEdit() {
	let { id } = useParams()
	const dispatch = useDispatch()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const defaultFiltersValues = {
		env_service_id: id,
	}

	useEffect(() => {
		handleLoad()
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	//* Load service
	const handleLoad = async() => {
		if (!isNew) {
			const service = await http.get(`${window.__endpoint}/environment/cha/print_reports/load?print_report_id=${id}`)
			setEditItem(service.data)
		}
	}

	const handleSave = async() => {
		await http.post(`${window.__endpoint}/environment/cha/print_reports/save`, editItem)
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	return <Card title="Personalizza print report per l'ambiente">
		<EditPrintReports
			readOnly
			editItem={editItem}
			onChange={handleChangeEditItem}
			onSubmit={handleSave}
		/>
	</Card>
}

export default PageEdit
