import React from 'react'
import { Route, Routes } from 'react-router-dom'

// * modules
import { default as Informations } from '../modules/Informations'
import { default as PaymentMethods } from '../modules/PaymentMethods'
import { default as ShippingMethods } from '../modules/ShippingMethods'
import { default as Services } from '../modules/Services'
import { default as Texts } from '../modules/Texts'

function App() {
	return <Routes>
		<Route path="informations/*" element={<Informations />} />
		<Route path="payment-methods/*" element={<PaymentMethods />} />
		<Route path="shipping-methods/*" element={<ShippingMethods />} />
		<Route path="services/*" element={<Services />} />
		<Route path="texts/*" element={<Texts />} />
	</Routes>
}

export default App
