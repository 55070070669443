import React, { useState, useEffect } from 'react'
import { FiltersSidebar, ListGrid, ListToolbar } from 'components/List'
import http from 'axios'
import { filter } from 'lodash'
import { Col, InputText, Row, Button } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [filters, setFilters] = useState({})
	const [sidebarFilters, setSidebarFilters] = useState({})
	const [action, setAction] = useState()

	const { onSubmit } = props

	const handleSubmit = () =>{
		onSubmit({
			action: action,
		})
		setAction(null)
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>
		<Row>
			<Col lg={12} md={12}>
				<InputText
					value={action || ''}
					rows={4}
					onChange={(e) => setAction(e.target.value)}
				/>
				<Button onClick={handleSubmit} disabled={! action}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='connector_action_id'
			columns={[
				{ field: 'connector_action_id', header: 'Id', sortable: true },
				{ field: 'action', header: 'Azione', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
