import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit ImageTypes
import { Edit as ImageTypesEdit } from '../../Components/ImageTypes'
import { List as ImageReferements } from '../../Components/Referements'
import { List as ImageResizings } from '../../Components/Resizings'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [imageReferements, setImageReferements] = useState([])
	const [imageResizings, setImageResizings] = useState([])
	const [editItem, setEditItem] = useState({})
	const defaultFiltersValues = {
		image_id: id
	}

	useEffect(() => {
		handleLoad()
		handleListImageReferements(defaultFiltersValues)
		handleListImageResizings(defaultFiltersValues)
	}, [id])

	//* Gestione dei moduli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}

	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/environment/fls/image_types/load?image_type_id=${id}`)
			setEditItem(module.data)
		}
	}

	const handleSave = async() => {
		if (! isNew) {
			await http.put(`${window.__endpoint}/environment/fls/image_types/update`, {
				...editItem,
				image_type_id: id,
			})
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei riferimenti
	const handleListImageReferements = async(filters) => {
		const module = await http.get(`${window.__endpoint}/environment/fls/image_types/referements/list?image_type_id=${id}`, {
			params: {
				...filters,
			}
		})
		setImageReferements(module.data)
	}
	const handleDeleteImageReferements = async(data) => {
		await http.post(`${window.__endpoint}/environment/fls/image_types/referements/remove_from_environment?image_type_refer_type_id=${data['image_type_refer_type_id']}`)
		handleListImageReferements(defaultFiltersValues)
	}
	const handleCreateImageReferements = async(data) => {
		await http.post(`${window.__endpoint}/environment/fls/image_types/referements/add_to_environment`, {
			image_type_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListImageReferements(defaultFiltersValues)
	}
	const handleSetDefaultImageReferement = async(data) => {
		await http.post(`${window.__endpoint}/environment/fls/image_types/referements/set_default`, {
			image_type_refer_type_id: data['image_type_refer_type_id']
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListImageReferements(defaultFiltersValues)
	}

	//* Gestione dei riferimenti
	const handleListImageResizings = async(filters) => {
		const module = await http.get(`${window.__endpoint}/environment/fls/image_types/resizings/list?image_type_id=${id}`, {
			params: {
				...filters,
			}
		})
		setImageResizings(module.data)
	}
	const handleDeleteImageResizings = async(id) => {
		await http.delete(`${window.__endpoint}/environment/fls/image_types/resizings/delete?image_size_id=${id}`)
		handleListImageResizings(defaultFiltersValues)
	}
	const handleSaveImageResizings = async(data) => {
		if (data.image_size_id && data.image_size_id > 0) {
			await http.put(`${window.__endpoint}/environment/fls/image_types/resizings/update`, {
				image_type_id: id,
				...data
			})
			dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
			handleListImageResizings(defaultFiltersValues)
		} else {
			await http.post(`${window.__endpoint}/environment/fls/image_types/resizings/create`, {
				image_type_id: id,
				image_size_id: -1,
				...data
			})
			dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
			handleListImageResizings(defaultFiltersValues)
		}
	}

	return <>
		<Card title={`${isNew ? 'Crea tipo di immagine' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'tipo di immagine'}`} per l'ambiente`}>
			<ImageTypesEdit
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <>
			<br />
			<Card title='Elenco riferimenti collegati'>
				<ImageReferements
					image_id={id}
					defaultFiltersValues={defaultFiltersValues}
					items={imageReferements}
					onList={handleListImageReferements}
					onDelete={handleDeleteImageReferements}
					onSubmit={handleCreateImageReferements}
					onSetDefault={handleSetDefaultImageReferement}
				/>
			</Card>
			<br />
			<Card title='Elenco ridimensionamenti'>
				<ImageResizings
					image_id={id}
					defaultFiltersValues={defaultFiltersValues}
					items={imageResizings}
					onList={handleListImageResizings}
					onDelete={handleDeleteImageResizings}
					onSubmit={handleSaveImageResizings}
					onUpdate={handleSaveImageResizings}
				/>
			</Card>
		</>}
	</>
}

export default PageEdit
