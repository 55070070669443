import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import http from 'axios'
import Edit from './../Edit/Edit'
import { Modal } from 'components/Ui'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [editItem, setEditItem] = useState({})
	const [modalOpen, setModalOpen] = useState(false)

	const handleEdit = async(id) => {
		if (id && id != 0) {
			const type = await http.get(`${window.__endpoint}/int/types/load?type_id=${id}`)
			setEditItem(type.data)
		}
		setModalOpen(true)
	}

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
			customAdd={handleEdit}
		/>
		<Modal
			{...props}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false)
			}}
			label='Nuovo allegato'
		>
			<Edit
				{...props}
				data={editItem}
			/>
		</Modal>
		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='type_id'
			columns={[
				{ field: 'type_id', header: 'Id', sortable: true },
				{ field: 'type', header: 'Tipo', sortable: true },
			]}
			onEdit={handleEdit}
		/>
	</React.Fragment>
}

export default List
