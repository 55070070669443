import React from 'react'
import { Col } from 'components/Ui'
import { ColorPicker as PrColorPicker } from 'primereact/colorpicker'
import { InputText as PrInputText } from 'primereact/inputtext'

function ColorPicker({ onChange, name, value, addHash , disabled, ...props }) {
	// Se è impostato addHash mi aspetto di ricevere il codice colore con il carattere #
	// Devo quindi elaborare il valore in ingresso e in uscita dal componente PrColorPicker

	let cleanValue = value
	if (addHash) {
		cleanValue = value.replaceAll('#', '')
	}
	const handleCPChange = (e) => {
		let event = e
		if (addHash) {
			event.target.value = `#${event.target.value}`
		}
		onChange(event)
	}

	return <div className="p-inputgroup flex-1">
		<span className="p-inputgroup-addon" style={{ padding: 0 }}>
			<PrColorPicker
				name={name}
				value={cleanValue}
				onChange={handleCPChange}
				style={{ width: 30 }}
				disabled={disabled}
			/>
		</span>
		<PrInputText
			id={name}
			name={name}
			value={value}
			onChange={onChange}
			disabled={disabled}
		/>
	</div>
}

export default ColorPicker
