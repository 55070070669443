import React from 'react'
import { Col, FormGroup, HTMLEditor, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'

function Edit({ editItem, onChange, onSubmit }) {

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={[]}
	>
		{(validator) => {
			return <form onSubmit={validator.onSubmit}>
				<EditToolbar hideGoBackButton/>
				<Row>
					{/* Testo privacy */}
					<Col all={12}>
						<FormGroup
							name="privacy_text"
							label="Testo privacy"
							validationState={validator.validationState.privacy_text}
						>
							<HTMLEditor
								value={editItem.privacy_text || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo condizioni di vendita */}
					<Col all={12}>
						<FormGroup
							name="sell_conditions"
							label="Testo condizioni di vendita"
							validationState={validator.validationState.sell_conditions}
						>
							<HTMLEditor
								value={editItem.sell_conditions || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo Resi e rimborsi */}
					<Col all={12}>
						<FormGroup
							name="returns_text"
							label="Testo Resi e rimborsi"
							validationState={validator.validationState.returns_text}
						>
							<HTMLEditor
								value={editItem.returns_text || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo Consegna della merce */}
					<Col all={12}>
						<FormGroup
							name="shipping_text"
							label="Testo Consegna della merce"
							validationState={validator.validationState.shipping_text}
						>
							<HTMLEditor
								value={editItem.shipping_text || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo Metodi di pagamento */}
					<Col all={12}>
						<FormGroup
							name="payment_methods"
							label="Testo Metodi di pagamento"
							validationState={validator.validationState.payment_methods}
						>
							<HTMLEditor
								value={editItem.payment_methods || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo condizioni di accettazione dei cookie */}
					<Col all={12}>
						<FormGroup
							name="subscription_code"
							label="Testo condizioni di accettazione dei cookie"
							validationState={validator.validationState.subscription_code}
						>
							<HTMLEditor
								value={editItem.cookie_text || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
					{/* Testo Allergeni */}
					<Col all={12}>
						<FormGroup
							name="restaurant_allergens"
							label="Testo Allergeni"
							validationState={validator.validationState.restaurant_allergens}
						>
							<HTMLEditor
								value={editItem.restaurant_allergens || ''}
								rows={4}
								onChange={onChange}
							/>
						</FormGroup>
					</Col>
				</Row>
			</form>}}
	</FormValidator>
}
export default Edit
