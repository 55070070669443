import React from 'react'
import { Tag } from 'components/Ui'
import { ListGrid, ListToolbar } from 'components/List'
import http from 'axios'
import { Col, Row, Select, Button } from 'components/Ui'

function List({ country_id, defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const { onSubmit } = props
	const [countryZones, setCountryZones] = React.useState([])

	const [selectedCountryZone, setSelectedCountryZone] = React.useState()

	const handleLoadOptions = async() =>{
		const countryZones = await http.get(`${window.__endpoint}/utl/regions/list?cols=region_id,name&order=name&avoid_country_id=${country_id}`)

		setCountryZones(countryZones.data)
	}

	React.useEffect(() => {
		handleLoadOptions()
	}, [items])

	const handleSubmit = () =>{
		onSubmit({
			region_id: selectedCountryZone,
		})
		setSelectedCountryZone(null)
	}

	return <React.Fragment>
		<ListToolbar
			onReload={() => {
				onList(filtersValues)
			}}
			editRoot={'fields'}
			hideBtnFilter
			hideBtnAdd
		/>

		<Row>
			<Col lg={12} md={12}>
				<Select
					value={selectedCountryZone || ''}
					onChange={(e) => setSelectedCountryZone(e.target.value)}
					options={countryZones || []}
					optionValue={(o) => o.region_id}
					optionLabel={(o) => o.name}
					all={{
						label: ' - Seleziona - '
					}}
				/>
				<Button onClick={handleSubmit}>
					Aggiungi
				</Button>
			</Col>
		</Row>

		<ListGrid
			hideBtnEdit
			onDelete={onDelete}
			items={items}
			fieldId='region_id'
			columns={[
				{ field: 'region_id', header: 'Id', sortable: true, width: '50%' },
				{ field: 'name', header: 'Regione', sortable: true },
			]}
		/>
	</React.Fragment>
}

export default List
