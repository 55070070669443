import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row, FormGroup, Select, InputText, Sidebar } from 'components/Ui'
import labels from 'core/labels'
import { SearchContext } from 'core/contexts/SearchContext'

function FiltersSidebar({ defaultValues, filters, hideSearch, visible, onHide, onSubmit, ...props }) {
	const [values, setValues] = useState({})

	// Se ho dichiarato il context per salvare la ricerca utilizzo la funzione per salvare i parametri di ricerca
	const setContextSearch = useContext(SearchContext) ? useContext(SearchContext).setContextSearch : () => { }

	useEffect(() => {
		setValues({
			...values,
			...defaultValues,
		})
	}, [defaultValues])

	const handleReset = (e) => {
		setValues({ ...defaultValues })
		onSubmit(defaultValues)
		// Resetto i parametri di ricerca salvati nel context
		setContextSearch({})
		onHide()
	}
	const handleSubmit = (e) => {
		e.preventDefault()
		onSubmit(values)
		// Salvo i parametri di ricerca nel context
		setContextSearch(values)
		onHide()
	}

	const getField = (filter) => {
		switch (filter.type) {
			case 'select':
				return <Select
					name={filter.name}
					all={{
						value: '',
						label: labels.select_value_all,
					}}
					optionLabel={filter.optionLabel}
					optionValue={filter.optionValue}
					options={filter.options}
					value={values[filter.name] || ''}
					onChange={(e) => setValues({ ...values, [filter.name]: e.target.value })}
					filter
				/>
		}
	}

	return <Sidebar visible={visible} position="right" onHide={onHide}>
		<form onSubmit={handleSubmit}>
			<Row>
				{filters.map((filter, f) => <Col key={f} all={12}>
					<FormGroup
						id={filter.name}
						label={filter.label}
					>
						{getField(filter)}
					</FormGroup>
				</Col>)}
				{!hideSearch && <Col all={12}>
					<FormGroup
						id="search"
						label="Ricerca libera"
					>
						<InputText
							name="search"
							value={values.search || ''}
							onChange={(e) => setValues({ ...values, search: e.target.value })}
						/>
					</FormGroup>
				</Col>}
				<Col all={12} className="buttons-group text-right">
					<Button
						severity="secondary"
						type="button"
						tooltip="Reset"
						icon="pi-replay"
						onClick={handleReset}
					/>
					<Button
						type="submit"
						tooltip="Applica"
						icon="pi-check"
					/>
				</Col>
			</Row>
		</form>
	</Sidebar>
}

export default FiltersSidebar
