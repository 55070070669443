import React, { useEffect, useState } from 'react'
import { FiltersSidebar, ListGridTree, ListGrid, ListToolbar } from 'components/List'
import { Tag } from 'components/Ui'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues }) {
	const [filtersVisible, setFiltersVisible] = useState(false)

	const [languages, setLanguages] = useState([])

	const handleLoadLanguages = async() =>{
		const response = await http.get(`${window.__endpoint}/cha/text_translations/languages?cols=lang_id,name`)

		const languages = response.data.map((item) => {
			return {
				value: item.lang_id,
				label: item.name
			}
		})

		setLanguages(languages)
	}

	useEffect(() => {
		handleLoadLanguages()
	}, [])

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
				{
					type: 'select',
					label: 'Lingua',
					name: 'lang_id',
					options: languages
				}
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			onDelete={onDelete}
			items={items}
			fieldId='text_id'
			columns={[
				{ field: 'text_id', header: 'Id', sortable: true },
				{ field: 'text_code', header: 'Codice', sortable: true },
				{ field: 'lang_name', header: 'Lingua', sortable: true },
				{ field: 'customer_edit_flag', header: 'Gestibile da utente', type: 'boolean', editable: false, sortable: true, body: ({ customer_edit_flag }) => {
					switch (Number(customer_edit_flag)) {
						case 0: return <Tag severity="danger" value="No" />
						case 1: return <Tag severity="success" value="Si" />
						default: return ''
					}
				} },
			]}
		/>
	</React.Fragment>
}

export default List
