import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import http from 'axios'
import { setMessage } from 'reducers/ui/uiSlice'
import labels from 'core/labels'
import { Card } from 'components/Ui'

// Edit module
import { Edit as RoleEdit } from '../../Components/Roles'
import { List as ListReferements } from '../../Components/RolesReferements'
import { List as ListModules } from '../../Components/RolesModules'
import { List as ListNodes } from '../../Components/RolesNodes'
import { List as ListObjects } from '../../Components/RolesObjects'

function PageEdit() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let { id } = useParams()
	const isNew = (id == undefined)
	const [editItem, setEditItem] = useState({})

	const [referementTypes, setReferementTypes] = useState([])
	const [nodes, setNodes] = useState([])
	const [modules, setModules] = useState([])
	const [objects, setObjects] = useState([])
	const [filtersValues, setFiltersValues] = useState({})
	const defaultFiltersValues = {
		role_id: id
	}

	useEffect(() => {
		handleLoad()
		if (!isNew) {
			handleListReferementTypes(defaultFiltersValues)
			handleListModules(defaultFiltersValues)
			handleListNodes(defaultFiltersValues)
			handleListObjects(defaultFiltersValues)
		}
	}, [id])

	//* Gestione dei ruoli
	const handleChangeEditItem = async(e) => {
		setEditItem({
			...editItem,
			[e.target.name]: (e.target.type == 'checkbox' ? Number(e.target.checked) : e.target.value),
		})
	}
	const handleLoad = async() => {
		if (!isNew) {
			const module = await http.get(`${window.__endpoint}/cha/roles/load?role_id=${id}`)
			setEditItem(module.data)
		}
	}
	const handleSave = async() => {
		if (isNew) {
			const id = await http.post(`${window.__endpoint}/cha/roles/create`, editItem)
			navigate(`../edit/${id.data}`, { replace: true })
		} else {
			await http.put(`${window.__endpoint}/cha/roles/update`, editItem)
		}
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
	}

	//* Gestione dei riferimenti/permessi
	const handleListReferementTypes = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/referement_types/permissions/list`, {
			params: {
				...filters
			}
		})
		setReferementTypes(list.data)
	}
	const handleDeleteReferementTypes = async(id) => {
		await http.delete(`${window.__endpoint}/cha/referement_types/permissions/delete?refer_type_permission_id=${id}`)
		handleListReferementTypes(filtersValues)
	}
	const handleCreateReferementTypes = async(data) => {
		await http.post(`${window.__endpoint}/cha/referement_types/permissions/create`, {
			role_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListReferementTypes(filtersValues)
	}

	//* Gestione dei moduli
	const handleListModules = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/roles/modules/list`, {
			params: {
				...filters,
			}
		})
		setModules(list.data)
	}
	const handleDeleteModules = async(id) => {
		await http.delete(`${window.__endpoint}/cha/roles/modules/delete?module_permission_id=${id}`)
		handleListModules(filtersValues)
	}
	const handleCreateModules = async(data) => {
		await http.post(`${window.__endpoint}/cha/roles/modules/create`, {
			role_id: id, // ID Ruolo
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListModules(filtersValues)
	}

	//* Gestione dei nodi
	const handleListNodes = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/roles/nodes/list`, {
			params: {
				...filters,
			}
		})
		setNodes(list.data)
	}
	const handleDeleteNode = async(id) => {
		await http.delete(`${window.__endpoint}/cha/roles/nodes/delete?node_permission_id=${id}`)
		handleListNodes(filtersValues)
	}
	const handleCreateNode = async(data) => {
		await http.post(`${window.__endpoint}/cha/roles/nodes/create`, {
			role_id: id,
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListNodes(filtersValues)
	}

	//* Gestione dei Oggetti
	const handleListObjects = async(filters) => {
		setFiltersValues(filters)
		const list = await http.get(`${window.__endpoint}/cha/role_objects/list`, {
			params: {
				...filters,
			}
		})
		setObjects(list.data)
	}
	const handleDeleteObjects = async(id) => {
		await http.delete(`${window.__endpoint}/cha/role_objects/delete?role_object_id=${id}`)
		handleListObjects(filtersValues)
	}
	const handleCreateObjects = async(data) => {
		await http.post(`${window.__endpoint}/cha/role_objects/create`, {
			role_id: id, // ID Lista valore
			...data
		})
		dispatch(setMessage({ message: { severity: 'success', summary: labels.save_complete, detail: '' } }))
		handleListObjects(filtersValues)
	}

	return <>
		<Card title={`${isNew ? 'Crea ruolo' : `Modifica ${editItem && editItem.name != '' ? editItem.name : 'ruolo'}`}`}>
			<RoleEdit
				role_id={id}
				editItem={editItem}
				setEditItem={setEditItem}
				onChange={handleChangeEditItem}
				onSubmit={handleSave}
			/>
		</Card>
		{!isNew && <React.Fragment>
			<br />
			<Card title="Modifica riferimenti/permessi">
				<ListReferements
					role_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={referementTypes}
					onList={handleListReferementTypes}
					onDelete={handleDeleteReferementTypes}
					onSubmit={handleCreateReferementTypes}
				/>
			</Card>
			<br />
			<Card title="Modifica moduli">
				<ListModules
					role_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={modules}
					onList={handleListModules}
					onDelete={handleDeleteModules}
					onSubmit={handleCreateModules}
				/>
			</Card>
			<br />
			<Card title="Modifica nodi">
				<ListNodes
					role_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={nodes}
					onList={handleListNodes}
					onDelete={handleDeleteNode}
					onSubmit={handleCreateNode}
				/>
			</Card>
			<br />
			<Card title="Modifica oggetti">
				<ListObjects
					role_id={id}
					defaultFiltersValues={defaultFiltersValues}
					filtersValues={filtersValues}
					items={objects}
					onList={handleListObjects}
					onDelete={handleDeleteObjects}
					onSubmit={handleCreateObjects}
				/>
			</Card>
		</React.Fragment>}
	</>
}

export default PageEdit
