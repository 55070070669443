import React, { useState } from 'react'
import { FiltersSidebar, ListToolbar, ListGrid } from 'components/List'
import http from 'axios'

function List({ defaultFiltersValues, items, onDelete, onList, filtersValues, ...props }) {
	const [filtersVisible, setFiltersVisible] = useState(false)
	const [filters, setFilters] = useState([])

	const handleListFilters = async() => {
		const regionsResponse = await http.get(`${window.__endpoint}/utl/regions/list?order=name`)
		const provincesResponse = await http.get(`${window.__endpoint}/utl/provinces/list?order=name`)

		const regions = regionsResponse.data.map(item => ({
			value: item.region_id,
			label: item.name
		}))

		const provinces = provincesResponse.data.map(item => ({
			value: item.province_id,
			label: item.name
		}))

		setFilters({
			regions: regions,
			provinces: provinces
		})
	}

	React.useEffect(() => {
		handleListFilters()
	}, [])

	return <React.Fragment>
		<FiltersSidebar
			visible={filtersVisible}
			onHide={() => setFiltersVisible(false)}
			onSubmit={filters => onList(filters)}
			defaultValues={defaultFiltersValues}
			filters={[
				{
					type: 'select',
					label: 'Provincia',
					name: 'province_id',
					options: filters.provinces
				},
				{
					type: 'select',
					label: 'Regione',
					name: 'region_id',
					options: filters.regions
				}
			]}
		/>

		<ListToolbar
			onShowFilters={() => {
				setFiltersVisible(true)
			}}
			onReload={() => {
				onList(filtersValues)
			}}
		/>

		<ListGrid
			{...props}
			onDelete={onDelete}
			items={items}
			fieldId='city_id'
			columns={[
				{ field: 'city_id', header: 'Id', sortable: true },
				{ field: 'name', header: 'Nome', sortable: true },
				{ field: 'province', header: 'Provincia', sortable: true },
				{ field: 'region', header: 'Regione', sortable: true },
			]}
			paginator
			rows={20}
		/>
	</React.Fragment>
}

export default List
