import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Checkbox, InputText, Select, Row } from 'components/Ui'
import { EditToolbar } from 'components/Edit'
import { FormValidator } from 'components/Misc'
import http from 'axios'

function Edit({ editItem, onChange, onSubmit }) {
	const [selectOptions, setSelectOptions] = useState({})

	useEffect(() => {
		loadSelectOptions()
	}, [])

	const loadSelectOptions = async() => {
		const contact_category_mails = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${63}`)
		const target_types = await http.get(`${window.__endpoint}/cha/list_lines/get/list?cols=list_value_id,name&list_id=${89}`)

		const opts = [
			{ optionValue: '4', optionLabel: 'Contatto' },
			{ optionValue: '144', optionLabel: 'Ruolo utente' },
			{ optionValue: '166', optionLabel: 'Gruppo utenti' },
		]

		setSelectOptions({
			target_types: target_types.data,
			contact_category_mails: contact_category_mails.data,
			refers: opts,
		})
	}

	return <FormValidator
		onSubmit={onSubmit}
		editItem={editItem}
		requiredFields={['target_type_id']}
	>
		{(validator) => <form onSubmit={validator.onSubmit}>
			<EditToolbar />
			<Row>
				{/* Tipi di destinatario */}
				<Col all={12}>
					<FormGroup
						name="target_type_id"
						label="Tipi di destinatario"
						validationState={validator.validationState.target_type_id}
					>
						<Select
							value={editItem.target_type_id || ''}
							onChange={onChange}
							options={selectOptions.target_types || []}
							optionValue={(o) => o.list_value_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Riferimento (solo quelli gestiti) */}
				<Col all={12}>
					<FormGroup
						name="refer_type_id"
						label="Riferimento (solo quelli gestiti)"
						validationState={validator.validationState.refer_type_id}
					>
						<Select
							value={editItem.refer_type_id || ''}
							onChange={onChange}
							options={selectOptions.refers || []}
							optionValue={(o) => o.optionValue}
							optionLabel={(o) => o.optionLabel}
							all={{
								label: 'Personalizzato'
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<FormGroup
						name="refer_id"
						label="Valore Riferimento"
						validationState={validator.validationState.refer_id}
					>
						<InputText
							value={editItem.refer_id || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				{/* Categoria Mail del Contatto */}
				<Col all={12}>
					<FormGroup
						name="contact_mail_category_id"
						label="Categoria Mail del Contatto"
						validationState={validator.validationState.contact_mail_category_id}
					>
						<Select
							value={editItem.contact_mail_category_id || ''}
							onChange={onChange}
							options={selectOptions.contact_category_mails || []}
							optionValue={(o) => o.list_value_id}
							optionLabel={(o) => o.name}
							all={{
								label: ' - Seleziona - '
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col all={12}>
					<h5>Personalizzato</h5>
				</Col>
			</Row>
			<Row>
				<Col all={6}>
					<FormGroup
						name="name"
						label="Nome"
						validationState={validator.validationState.name}
					>
						<InputText
							value={editItem.name || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
				<Col all={6}>
					<FormGroup
						name="email"
						label="email"
						validationState={validator.validationState.email}
					>
						<InputText
							value={editItem.email || ''}
							onChange={onChange}
						/>
					</FormGroup>
				</Col>
			</Row>
		</form>}
	</FormValidator>
}

export default Edit
